import SampleBadge from "@/components/SampleBadge";
import NewFolderCard from "@/components/card/newFolderCard";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import Tooltip from "@shared/frontend/Tooltip";
import { userHasPermission, userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { IFVariable } from "@shared/types/Variable";
import { IFVariableFolder } from "@shared/types/VariableFolder";
import { default as classNames, default as classnames } from "classnames";
import React, { useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Dropdown from "react-bootstrap/Dropdown";
import useLocalStorage from "react-use/lib/useLocalStorage";
import EditableName from "../../../../components/EditableName";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import PopulatedState from "../../../../components/PopulatedState/PopulatedState";
import CommonFolderCard from "../../../../components/card/CommonFolderCard";
import SearchAndFilters from "../../../../components/searchAndFilters/searchAndFilters";
import useSegment from "../../../../hooks/useSegment";
import { VariableType } from "../../types";
import VariableFolderGroup from "../VariableFolderGroup";
import VariableItem from "../VariableItem";
import VariableListPlaceholder from "../VariableListPlaceholder";
import VariableTypeFilter from "../VariableTypeFilter";
import style from "./style.module.css";

const HIDE_VARIABLES_HELP = "hide-variables-help";

interface Props {
  query: string;
  setQuery: (query: string) => void;
  handleQueryChange: (q: string) => void;
  typeFilter: any;
  chooseType: (type: VariableType) => void;
  variables: IFVariable[];
  variablesByFolder: {
    [key: string]: IFVariable[];
  };
  isLoading: boolean;
  selectedVariable: IFVariable | null;
  handleSelectVariable: (input: IFVariable) => void;
  foldersAllowed: boolean;
  variableFolders: IFVariableFolder[];
  openCreateVariableFolderModal: () => Promise<void>;
  handleFolderClick: (folderId: string) => void;
  handleRenameVariableFolder: (name: string, folderId: string) => void;
  handleDeleteVariableFolder: (folderId: string) => void;
  selectedFolderId?: string;
  toggleMultiSelected: (variable: IFVariable) => void;
  multiSelectedVariables: IFVariable[];
  isSearching: boolean;
}
const VariableList = ({
  query,
  setQuery,
  handleQueryChange,
  typeFilter,
  chooseType,
  variables,
  variablesByFolder,
  isLoading,
  selectedVariable,
  handleSelectVariable,
  foldersAllowed,
  variableFolders,
  openCreateVariableFolderModal,
  handleFolderClick,
  handleRenameVariableFolder,
  handleDeleteVariableFolder,
  selectedFolderId,
  toggleMultiSelected,
  multiSelectedVariables,
  isSearching,
}: Props) => {
  const segment = useSegment();
  const [hideVariablesHelp, setHideVariablesHelp] = useLocalStorage(HIDE_VARIABLES_HELP, false);
  const { user } = useAuthenticatedAuth();

  const selectedFolder = useMemo(() => {
    return variableFolders.find((folder) => folder._id === selectedFolderId);
  }, [selectedFolderId, variableFolders]);

  const [editingFolderName, setEditingFolderName] = useState(false);
  const showCreateFolderButton = userHasPermission("variable_folder:create");
  const canEdit = userHasResourcePermission("variable_folder:edit");

  // we want to show the folder section if
  // 1. there is no folder selected
  // 2. AND loading is finished
  // 3. AND there are either
  //    a. folders to show
  //    b. OR the user can create folders
  const showFolderSection = !selectedFolder && !isLoading && (variableFolders.length > 0 || showCreateFolderButton);

  // we don't want to show the variables "Getting Started" card if the user has
  // any variable folders, with the exception of the sample folder.
  const nonSampleVariableFolderExists = variableFolders.filter((vf) => !vf.isSample).length > 0;

  const createSampleBanner = () => {
    return (
      <div className={style.sampleBanner}>
        <div className={style.sampleBannerTitle}>
          You’re viewing <SampleBadge className={style.sampleBadge}>sample</SampleBadge> Ditto variables.
        </div>
        <div className={style.sampleBannerText}>
          You won’t be able to use any of these variables in your projects, but when you’re ready, you can create your
          own.{" "}
          <a
            className={style.sampleBannerLink}
            href="https://dittowords.com/docs/variables"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about variables
          </a>
        </div>
      </div>
    );
  };

  const createFolderTitle = (folder: IFVariableFolder) => {
    return (
      <div className={style.variableFolderTitle}>
        <FolderOpenOutlined className={style.icon} />
        <EditableName
          isEditEnabled={canEdit && !folder.isSample}
          className={style.folderName}
          value={folder.name}
          onSave={(name) => {
            handleRenameVariableFolder(name, folder._id);
            setEditingFolderName(false);
          }}
          editModeEnabled={[editingFolderName, setEditingFolderName]}
          uniqueNames={variableFolders.map((folder) => folder.name).filter((name) => name !== folder.name)}
        />
        {canEdit && (
          <Dropdown>
            <Dropdown.Toggle>
              <MoreVert className={style.more_icon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Tooltip
                hideOnClick={false}
                className={style.tooltip}
                content={<div className={style.body}>Renaming is disabled for sample data.</div>}
                placement="top"
                theme="dark"
                disabled={!folder.isSample}
              >
                <div>
                  <Dropdown.Item disabled={folder.isSample} onClick={() => setEditingFolderName(true)}>
                    Rename Folder
                  </Dropdown.Item>
                </div>
              </Tooltip>
              <Tooltip
                hideOnClick={false}
                className={style.tooltip}
                content={<div className={style.body}>Deleting is disabled for sample data.</div>}
                placement="top"
                theme="dark"
                disabled={!folder.isSample}
              >
                <div>
                  <Dropdown.Item disabled={folder.isSample} onClick={() => handleDeleteVariableFolder(folder._id)}>
                    Delete Folder
                  </Dropdown.Item>
                </div>
              </Tooltip>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    );
  };

  const createVariableItems = () => {
    return (
      <div className={classNames("row", style.row)}>
        <div className="col-12">
          {isSearching &&
            !isLoading &&
            !selectedFolderId &&
            Object.keys(variablesByFolder).filter((id) => id !== "none").length > 0 && (
              <h2 className={style.sectionHeader}>Folders</h2>
            )}
          {isSearching &&
            !isLoading &&
            !selectedFolderId &&
            Object.keys(variablesByFolder)
              .filter((id) => id !== "none")
              .map((folderId, index) => (
                <div key={folderId}>
                  {variableFolders.find((f) => f._id === folderId) && (
                    <div>
                      <VariableFolderGroup
                        folder={variableFolders.find((f) => f._id === folderId) as IFVariableFolder}
                        variables={variablesByFolder[folderId]}
                        handleSelectVariable={handleSelectVariable}
                        isSelected={(variable) => {
                          return Boolean(selectedVariable) && selectedVariable?._id === variable._id;
                        }}
                        isMultiSelected={(variable) => {
                          return multiSelectedVariables.find((v) => v._id === variable._id) !== undefined;
                        }}
                        toggleSelected={toggleMultiSelected}
                      />
                    </div>
                  )}
                </div>
              ))}

          {foldersAllowed && !selectedFolder && variablesByFolder["none"]?.length > 0 && (
            <h2 className={style.sectionHeader}>Variables</h2>
          )}
          {selectedFolder && createFolderTitle(selectedFolder)}
          {selectedFolder?.isSample && createSampleBanner()}
          {!foldersAllowed && <h2 className={style.sectionHeader}>Variables</h2>}
          {variablesByFolder[selectedFolderId || "none"] &&
            variablesByFolder[selectedFolderId || "none"].length > 0 &&
            variablesByFolder[selectedFolderId || "none"].map((variable, index) => (
              <VariableItem
                key={index}
                variable={variable}
                isSelected={Boolean(selectedVariable) && selectedVariable?._id === variable._id}
                handleSelectVariable={handleSelectVariable}
                toggleSelected={() => toggleMultiSelected(variable)}
                isMultiSelected={multiSelectedVariables.find((v) => v._id === variable._id) !== undefined}
              />
            ))}
          {!isLoading && foldersAllowed && selectedFolderId && !variablesByFolder[selectedFolderId] && (
            <div className={style.helpText}>
              <h2>No variables in folder</h2>
              <p>
                Storing your variables in folders can help keep your variable library organized and ensure you're able
                to quickly find the text you need.
              </p>
              <br />
              <p>You can add variables to this folder by:</p>
              <ul>
                <li>Clicking the "New Variable" button on the top right</li>
                <li>Selecting variables and clicking the "Move to folder" button</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };

  const createVariableFolders = () => {
    return (
      <div className={classNames("row", style.row)}>
        <div className="col-12">
          <div className={style.folderSection}>
            <h2 className={style.sectionHeader}>Folders</h2>
            <div className={classnames("row", style.allProjects)}>
              {showCreateFolderButton && (
                <NewFolderCard
                  responsiveSize="large"
                  disabled={!foldersAllowed}
                  openModal={openCreateVariableFolderModal}
                  learnMoreLink="https://www.dittowords.com/docs/variables"
                  onUpsellClicked={() =>
                    segment.track({
                      event: SegmentEvents.UPSELL_CLICKED,
                      properties: {
                        type: "Folders",
                        location: "Variable Library",
                        workspaceId: user.workspaceId,
                      },
                    })
                  }
                />
              )}
              {variableFolders.map((folder, index) => {
                return (
                  <CommonFolderCard
                    responsiveSize="large"
                    key={index}
                    folder={folder}
                    count={folder.variableCount || 0}
                    content="variable"
                    onClick={() => handleFolderClick(folder._id)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const createNoSearchResultsScreen = () => {
    if (isSearching && variables.length === 0) {
      return (
        <div className={classnames("col-12", [style.noResultsMessage])}>
          <div>Sorry, we couldn't find any results for your search! Try adjusting your search query.</div>
        </div>
      );
    }
  };

  const createGettingStartedScreen = () => {
    if (!selectedFolderId && !nonSampleVariableFolderExists) {
      return (
        <EmptyState
          headline="Your team doesn’t have any variables yet! Create new variables using the button in the top right. ✨"
          title="Getting Started with Variables ⚡️"
          description={
            <div>
              <p>
                Variables in Ditto allow teams to integrate dynamic values. Variables automatically format for
                developers without interfering with the display in designs.
              </p>
              <p>Here, you can view and manage your team’s variables.</p>
              <p>We support 5 types of variables:</p>
              <ul>
                <li>
                  Strings (ex: “Hello <span className={style.variableSpan}>Janet</span>!”)
                </li>
                <li>
                  Numbers (ex: “Your balance is $<span className={style.variableSpan}>450.12</span>
                  ”)
                </li>
                <li>
                  Links (ex: “View our <span className={style.variableSpan}>FAQ page</span>.”)
                </li>
                <li>
                  Lists (ex: “Visit our location in <span className={style.variableSpan}>New York</span>.”)
                </li>
                <li>
                  Maps (ex: View your balance in <span className={style.variableSpan}>USD</span>.”)
                </li>
              </ul>
            </div>
          }
          learnMoreLink="https://www.dittowords.com/docs/variables"
          loomEmbed={
            <div>
              <iframe
                src="https://www.loom.com/embed/4564ed828ce2401c81c891c3fe1ab6be"
                frameBorder="0"
                allowFullScreen
                style={{
                  width: "100%",
                  borderRadius: 8,
                  aspectRatio: "3/2",
                  maxHeight: 300,
                }}
              />
            </div>
          }
        />
      );
    }
  };

  return (
    <div className={style.wrapper}>
      <SearchAndFilters
        query={query}
        setQuery={setQuery}
        assignee={undefined}
        setAssignee={undefined}
        devID={undefined}
        setDevID={undefined}
        onChange={handleQueryChange}
        name={"your workspace"}
        loading={false}
        docSearch={false}
        fullWidth={false}
        inheritWidth={true}
        customInputs={<VariableTypeFilter type={typeFilter} chooseType={chooseType} />}
        normalFiltersHidden={true}
        clearSelectedTags={() => {}}
        selected_tags={[]}
        unselected_tags={null}
        onInitialChange={undefined}
        status={undefined}
        chooseStatus={undefined}
        selectTag={undefined}
        filteredCompIds={undefined}
        multiSelectedIds={undefined}
        setMultiSelected={undefined}
        scrollToId={undefined}
        searchDisabled={undefined}
        debounce={250}
      />
      <div className={style.variableList} id="variableContainer">
        <div className={classnames("container", [style.container])}>
          {!isLoading &&
            !isSearching &&
            variables.filter((v) => !v.isSample).length === 0 &&
            createGettingStartedScreen()}
          {isLoading && <VariableListPlaceholder />}
          <div className={style.row}>
            {!hideVariablesHelp && !isSearching && variables?.length > 0 && !isLoading && !selectedFolder?.isSample && (
              <PopulatedState
                learnMoreLink="https://www.dittowords.com/docs/variables"
                onClose={() => setHideVariablesHelp(true)}
                title="What are variables? 💡️"
                description={
                  <>
                    <p>
                      Variables in Ditto allow teams to integrate dynamic values. Variables automatically format for
                      developers without interfering with the display in designs.
                    </p>
                    <p>We currently support 5 types of variables: strings, numbers, links, lists, and maps.</p>
                  </>
                }
              />
            )}
            <DragDropContext>
              {" "}
              {showFolderSection && !isSearching && createVariableFolders()}
              {!isLoading && createVariableItems()}{" "}
            </DragDropContext>
            {createNoSearchResultsScreen()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VariableList;
