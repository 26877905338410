import { mostRecentTextItemCommentFamilyAtom, postCommentActionAtom, updateCommentEditsAtom } from "@/stores/Comments";
import { detailsPanelEditStateAtom, onlySelectedTextItemIdAtom } from "@/stores/ProjectSelection";
import { deferredUsersAtom } from "@/stores/Workspace";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import CommentEditor from "@ds/organisms/CommentEditor";
import { IUser } from "@shared/types/User";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import { CommentThreadWrapper } from "../../CommentThreadWrapper";
import style from "./style.module.css";

function Comments() {
  const users = useAtomValue(deferredUsersAtom);
  const selectedTextItemId = useAtomValue(onlySelectedTextItemIdAtom);
  const setSelectedDetailsTab = useSetAtom(detailsPanelEditStateAtom);
  const postCommentAction = useSetAtom(postCommentActionAtom);
  const mostRecentThreadId = useAtomValue(mostRecentTextItemCommentFamilyAtom(selectedTextItemId));
  const updateIsEditingAtom = useSetAtom(updateCommentEditsAtom);

  const handlePostComment = async (commentText: string, mentionedUsers: IUser[], resetEditorState: () => void) => {
    if (!selectedTextItemId) {
      return;
    }

    await postCommentAction({
      textItemId: selectedTextItemId,
      commentText,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
    });

    resetEditorState();
  };

  const onTypeComment = useCallback(
    (contentToSave: string, _mentionedUsersInfo: IUser[]) => {
      updateIsEditingAtom({ commentThreadId: "new", hasUnsavedChanges: !!contentToSave });
    },
    [updateIsEditingAtom]
  );

  const handleShowAll = () => {
    setSelectedDetailsTab("COMMENTS");
  };

  if (!selectedTextItemId) {
    return <></>;
  }

  return (
    <div className={style.commentsContainer}>
      <div className={style.commentEditorContainer}>
        <Text size="small" color="primary" weight="strong">
          Comment
        </Text>
        <CommentEditor
          key={selectedTextItemId}
          placeholderText="Add a comment..."
          submitButtonText="Add comment"
          onChange={onTypeComment}
          onSave={handlePostComment}
          mentionableUsers={users}
        />
      </div>
      {!!mostRecentThreadId && (
        <div className={style.mostRecentContainer}>
          <div className={style.mostRecentHeader}>
            <Text size="small" weight="medium" color="secondary">
              Most recent
            </Text>
            <Button size="micro" level="subtle" onClick={handleShowAll}>
              See all comments
            </Button>
          </div>
          <CommentThreadWrapper commentThreadId={mostRecentThreadId} />
        </div>
      )}
    </div>
  );
}

export default Comments;
