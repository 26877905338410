import { ITipTapRichText } from "../types/TextItem";

export const EMPTY_RICH_TEXT: ITipTapRichText = {
  type: "doc",
  content: [
    {
      type: "paragraph",
    },
  ],
};
