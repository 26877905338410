import classNames from "classnames";
import noop from "lodash/noop";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createEmptyRichText } from "../../../../shared/frontend/richText/plainTextToRichText";
import { RichTextInputProps } from "../../../../shared/types/RichText";
import { ActualComponentStatus, ITextItemVariableRichValue, ITipTapRichText } from "../../../../shared/types/TextItem";
import { AddVariantData, AddVariantUpdateType } from "../../../../shared/types/Variant";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import BaseCombobox, { ComboboxOption } from "../../molecules/BaseCombobox";
import LabelWrapper from "../../molecules/LabelWrapper";
import StatusSelect from "../StatusSelect";
import { FlagIcon } from "../TextItemMetadata/icons";
import style from "./index.module.css";

interface IAddVariantFormProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The list of existing variants in the workspace as options for the combobox.
   */
  variantOptions: ComboboxOption[];

  /**
   * Optional default selected option in the combobox.
   */
  defaultOption?: ComboboxOption;

  /**
   * Placeholder for the variant text input.
   */
  placeholder: string;

  /**
   * The variant IDs that have been assigned for the currently selected text item.
   */
  selectedTextItemVariantNames: string[];

  /**
   * A method to run whenever the user makes an edit in the form.
   * This could be selecting a variant, changing the variant text, or changing the status.
   */
  onEdit?: ({
    selectedVariant,
    status,
    richText,
  }: {
    selectedVariant?: ComboboxOption | null;
    status?: ActualComponentStatus;
    richText?: ITipTapRichText;
  }) => void;
  onCancel: () => void;
  onSave: (variant: AddVariantData, updateType: AddVariantUpdateType) => void;

  /**
   * Wrapper for our TipTap RichTextInput component.
   */
  RichTextInput: (richTextInputProps: RichTextInputProps) => React.ReactNode;
}

export function AddVariantForm(props: IAddVariantFormProps) {
  const [selectedVariant, setSelectedVariant] = useState<ComboboxOption | null>(props.defaultOption ?? null);
  const [variantOptions, setVariantOptions] = useState<ComboboxOption[]>(props.variantOptions);
  const [status, setStatus] = useState<ActualComponentStatus>("NONE");
  const [richText, setRichText] = useState<ITipTapRichText>(createEmptyRichText());

  const { onEdit, onCancel, onSave } = props;

  const handleSelectVariant = useCallback(
    (newSelectedVariant: ComboboxOption | null) => {
      setSelectedVariant(newSelectedVariant);
      onEdit && onEdit({ selectedVariant: newSelectedVariant });
    },
    [onEdit]
  );

  const handleSelectStatus = useCallback(
    (newStatus: ActualComponentStatus) => {
      setStatus(newStatus);
      onEdit && onEdit({ status: newStatus });
    },
    [onEdit]
  );

  const handleUpdateRichText = useCallback(
    (newRichText: ITipTapRichText) => {
      setRichText(newRichText);
      onEdit && onEdit({ richText: newRichText });
    },
    [onEdit]
  );

  /**
   * Syncs variant options state with props.variantOptions, which can update when
   * a variant is created elsewhere in the app and a websocket event is emitted
   */
  useEffect(() => {
    setVariantOptions(props.variantOptions);
  }, [props.variantOptions]);

  const initialVariableRichValue: ITextItemVariableRichValue = useMemo(() => {
    return {
      rich_text: createEmptyRichText(),
      plurals: [],
      variables: [],
      text: "",
      characterLimit: null,
    };
  }, []);

  /**
   * Creates a new variant option with the given name. Flags the new option as a draft item.
   * @param newVariantName - The name of the new variant.
   */
  function onCreateNewVariantOption(newVariantName: string) {
    setVariantOptions([
      ...variantOptions.filter((v) => !v.isDraftItem),
      { value: newVariantName, label: newVariantName, isDraftItem: true },
    ]);
  }

  /**
   * Handles adding the variant to the selected text item.
   *
   * If saving a new variant (flagged with 'isDraftItem'):
   * - set the name property so backend knows to create a new variant with that name
   * - set variantId to empty string; handle optimistically creating and saving ID elsewhere
   * Otherwise, we're attaching an existing variant to a text item
   * - set the name property to be empty (we don't want to try and make a duplicate variant!)
   * - set variantId to the selected ComboboxOption's value (which should be the existing variant's ID)
   */
  function handleAddVariant() {
    const variantToAdd = {
      name: selectedVariant!.isDraftItem ? selectedVariant!.label : "",
      variantId: selectedVariant!.isDraftItem ? "" : selectedVariant!.value,
      status,
      rich_text: richText,
    };

    onSave(variantToAdd, selectedVariant!.isDraftItem ? "CREATE" : "ATTACH");
  }

  const saveDisabled =
    selectedVariant === null ||
    props.selectedTextItemVariantNames.some((name) => name.toLowerCase() === selectedVariant.value.toLowerCase());

  return (
    <div
      style={props.style}
      className={classNames(style.AddVariantFormWrapper, props.className)}
      data-testid="add-variant-form"
    >
      <Text weight="strong">Add variant</Text>
      <div className={style.AddVariantForm}>
        <BaseCombobox
          options={variantOptions}
          exclusions={props.selectedTextItemVariantNames}
          selectedItem={selectedVariant}
          setSelectedItem={handleSelectVariant}
          placeholder={`${variantOptions.length > 0 ? "Choose or create " : "Create "}variant...`}
          onCreateNew={onCreateNewVariantOption}
          createNewText="Create new variant: "
          autoFocus
        />

        {/* Variant text and metadata */}
        <div className={style.AddVariantMetadata}>
          <Text size="small" weight="strong">
            Variant text
          </Text>
          <props.RichTextInput
            initialVariableRichValue={initialVariableRichValue}
            setBaseText={handleUpdateRichText}
            setPlurals={noop}
            setCharacterLimit={noop}
            characterLimit={null}
            pluralsDisabled={true}
            characterLimitDisabled={true}
            placeholder={props.placeholder}
            emptyEditorClass="emptyEditor"
          />
        </div>

        {/* Status select */}
        <LabelWrapper labelFlexStart labelHeight={32} label="Status" leadingIcon={<FlagIcon />}>
          <div className={style.inputWidthWrapper}>
            <StatusSelect status={status} setStatus={handleSelectStatus} />
          </div>
        </LabelWrapper>

        {/* CTA buttons */}
        <div className={style.CTAButtons}>
          <Button size="small" level="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={saveDisabled} size="small" level="primary" onClick={handleAddVariant}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddVariantForm;
