import { z } from "zod";
import { ZFFigmaTextNode, ZFigmaTextNode } from "./FigmaTextNode";
import { BackendSchema, FrontendSchema, ZObjectId } from "./lib";
import { ZActualComponentVariableSchema, ZTextItem, ZTipTapRichText } from "./TextItem";

// MARK: - Concerns

const ZDittoTextMismatchConcern = z.object({
  type: z.literal("dittoTextMismatch"),
  textItem: ZTextItem,
  figmaTextNode: ZFigmaTextNode,
  dittoRichText: ZTipTapRichText,
  figmaRichText: ZTipTapRichText,
  isFigmaEdit: z.boolean(),
});

type IDittoTextMismatchConcern = z.infer<typeof ZDittoTextMismatchConcern>;
export type IBDittoTextMismatchConcern = BackendSchema<IDittoTextMismatchConcern>;
export type IFDittoTextMismatchConcern = FrontendSchema<IDittoTextMismatchConcern>;

const ZTextOverrideConcern = z.object({
  type: z.literal("textOverride"),
  textItem: ZTextItem,
  figmaNodeId: z.string(),
  overrideRichText: ZTipTapRichText,
});

type ITextOverrideConcern = z.infer<typeof ZTextOverrideConcern>;
export type IBTextOverrideConcern = BackendSchema<ITextOverrideConcern>;
export type IFTextOverrideConcern = FrontendSchema<ITextOverrideConcern>;

const ZTextMismatchConcern = z.object({
  type: z.literal("textMismatch"),
  figmaTextNode: ZFigmaTextNode,
  textItem: ZTextItem,
  dittoRichText: ZTipTapRichText,
  figmaRichText: ZTipTapRichText,
  isConflict: z.boolean(),
  isFigmaEdit: z.boolean(),
});

type ITextMismatchConcern = z.infer<typeof ZTextMismatchConcern>;
export type IBTextMismatchConcern = BackendSchema<ITextMismatchConcern>;
export type IFTextMismatchConcern = FrontendSchema<ITextMismatchConcern>;

const ZUnlinkedFigmaNodeWithTextItemIdConcern = z.object({
  type: z.literal("unlinkedFigmaNodeWithTextItemId"),
  figmaTextNode: ZFigmaTextNode,
  textItem: ZTextItem,
  dittoRichText: ZTipTapRichText,
  figmaRichText: ZTipTapRichText,
  isConflict: z.boolean(),
});

type IUnlinkedFigmaNodeWithTextItemIdConcern = z.infer<typeof ZUnlinkedFigmaNodeWithTextItemIdConcern>;
export type IBUnlinkedFigmaNodeWithTextItemIdConcern = BackendSchema<IUnlinkedFigmaNodeWithTextItemIdConcern>;
export type IFUnlinkedFigmaNodeWithTextItemIdConcern = FrontendSchema<IUnlinkedFigmaNodeWithTextItemIdConcern>;

const ZFigmaNodeWithNonExistentTextItemIdConcern = z.object({
  type: z.literal("figmaNodeWithNonExistentTextItemId"),
  figmaNodeId: z.string(),
  textItemId: z.string(),
});

type IFigmaNodeWithNonExistentTextItemIdConcern = z.infer<typeof ZFigmaNodeWithNonExistentTextItemIdConcern>;
export type IBFigmaNodeWithNonExistentTextItemIdConcern = BackendSchema<IFigmaNodeWithNonExistentTextItemIdConcern>;
export type IFFigmaNodeWithNonExistentTextItemIdConcern = FrontendSchema<IFigmaNodeWithNonExistentTextItemIdConcern>;

const ZFigmaTextNodeDeletionConcern = z.object({
  type: z.literal("figmaTextNodeDeletion"),
  figmaNodeId: z.string(),
  textItemId: z.string(),
});

type IFigmaTextNodeDeletionConcern = z.infer<typeof ZFigmaTextNodeDeletionConcern>;
export type IBFigmaTextNodeDeletionConcern = BackendSchema<IFigmaTextNodeDeletionConcern>;
export type IFFigmaTextNodeDeletionConcern = FrontendSchema<IFigmaTextNodeDeletionConcern>;

export const ZConcern = z.discriminatedUnion("type", [
  ZTextMismatchConcern,
  ZDittoTextMismatchConcern,
  ZTextOverrideConcern,
  ZFigmaNodeWithNonExistentTextItemIdConcern,
  ZUnlinkedFigmaNodeWithTextItemIdConcern,
  ZFigmaTextNodeDeletionConcern,
]);
type IConcern = z.infer<typeof ZConcern>;
export type IBConcern = BackendSchema<IConcern>;
export type IFConcern = FrontendSchema<IConcern>;

// MARK: - Actions

const ZResolveTextConflictAction = z.object({
  type: z.literal("resolveTextConflict"),
  textItem: z.object({
    _id: z.string(),
    text: z.string(),
    variables: z.array(ZActualComponentVariableSchema),
    richText: ZTipTapRichText,
  }),
  figmaNodes: z.array(
    z.object({
      ids: z.array(z.string()),
      richText: ZTipTapRichText,
    })
  ),
});
type IResolveTextConflictAction = z.infer<typeof ZResolveTextConflictAction>;
export type IBResolveTextConflictAction = BackendSchema<IResolveTextConflictAction>;
export type IFResolveTextConflictAction = FrontendSchema<IResolveTextConflictAction>;

const ZLinkFigmaTextNodeAction = z.object({
  type: z.literal("linkFigmaTextNode"),
  textItemId: z.string(),
  figmaNode: ZFFigmaTextNode.pick({ _id: true, workspaceId: true, nodeId: true, richText: true, position: true }),
});
type ILinkFigmaTextNodeAction = z.infer<typeof ZLinkFigmaTextNodeAction>;
export type IBLinkFigmaTextNodeAction = BackendSchema<ILinkFigmaTextNodeAction>;
export type IFLinkFigmaTextNodeAction = FrontendSchema<ILinkFigmaTextNodeAction>;

const ZUnlinkFigmaTextNodeAction = z.object({
  type: z.literal("unlinkFigmaTextNode"),
  textItemId: z.string(),
  figmaNodeId: z.string(),
});

type IUnlinkFigmaTextNodeAction = z.infer<typeof ZUnlinkFigmaTextNodeAction>;
export type IBUnlinkFigmaTextNodeAction = BackendSchema<IUnlinkFigmaTextNodeAction>;
export type IFUnlinkFigmaTextNodeAction = FrontendSchema<IUnlinkFigmaTextNodeAction>;

const ZUpdateFigmaNodeRichTextChange = z.object({
  type: z.literal("richText"),
  before: ZTipTapRichText.optional(),
  after: ZTipTapRichText,
});

const ZUpdateFigmaNodeChange = z.discriminatedUnion("type", [ZUpdateFigmaNodeRichTextChange]);

type IUpdateFigmaNodeChange = z.infer<typeof ZUpdateFigmaNodeChange>;
export type IBUpdateFigmaNodeChange = BackendSchema<IUpdateFigmaNodeChange>;
export type IFUpdateFigmaNodeChange = FrontendSchema<IUpdateFigmaNodeChange>;

const ZUpdateFigmaNodeAction = z.object({
  type: z.literal("updateFigmaNode"),
  nodeId: z.string(),
  textItemId: z.string(),
  changes: z.array(ZUpdateFigmaNodeChange),
});

type IUpdateFigmaNodeAction = z.infer<typeof ZUpdateFigmaNodeAction>;
export type IBUpdateFigmaNodeAction = BackendSchema<IUpdateFigmaNodeAction>;
export type IFUpdateFigmaNodeAction = FrontendSchema<IUpdateFigmaNodeAction>;

const ZUpdateTextItemRichTextChange = z.object({
  type: z.literal("richText"),
  before: ZTipTapRichText,
  after: ZTipTapRichText,
});

const ZUpdateTextITemChange = z.discriminatedUnion("type", [ZUpdateTextItemRichTextChange]);

const ZUpdateDittoTextItemAction = z.object({
  type: z.literal("updateDittoTextItem"),
  textItemId: ZObjectId,
  changes: z.array(ZUpdateTextITemChange),
});

type IUpdateDittoTextItemAction = z.infer<typeof ZUpdateDittoTextItemAction>;
export type IBUpdateDittoTextItemAction = BackendSchema<IUpdateDittoTextItemAction>;
export type IFUpdateDittoTextItemAction = FrontendSchema<IUpdateDittoTextItemAction>;

export const ZAction = z.discriminatedUnion("type", [
  ZUpdateFigmaNodeAction,
  ZUpdateDittoTextItemAction,
  ZLinkFigmaTextNodeAction,
  ZUnlinkFigmaTextNodeAction,
  ZResolveTextConflictAction,
]);
type IAction = z.infer<typeof ZAction>;
export type IBAction = BackendSchema<IAction>;
export type IFAction = FrontendSchema<IAction>;

// MARK: - Sync results after applying the changes in the figma plugin.

export const ZUpdateTextNodeInstanceLastReconciledRichText = z.object({
  fileId: z.string(),
  branchId: z.string().nullable(),
  textItemId: z.string(),
  figmaNodeId: z.string(),
  lastReconciledRichText: ZTipTapRichText,
});

export type IUpdateTextNodeInstanceLastReconciledRichText = z.infer<
  typeof ZUpdateTextNodeInstanceLastReconciledRichText
>;
