import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import Add from "@mui/icons-material/Add";
import "@reach/combobox/styles.css";
import classNames from "classnames";
import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import getBoldedMatchingText from "../../../helpers/getBoldedMatchingText";
import Button from "../../atoms/Button";
import Checkbox from "../../atoms/Checkbox";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

const isDuplicate = (disallowCaseDuplicates: boolean, query: string, itemValue: string) =>
  Boolean(disallowCaseDuplicates) ? itemValue.toLocaleLowerCase() === query.toLocaleLowerCase() : itemValue === query;

export type Item = {
  value: string;
  label: string;
};

interface IProps {
  options: Item[];
  selectedItems: Item[];
  setSelectedItems: (items: Item[]) => void;

  size?: "base" | "small";
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  focusedPlaceholder?: string;
  createNewText?: string;

  onCreateNew?: (value: string) => void;
  onFocus?: () => void;

  // Setting this to true will disallow new items to be created if they are the same
  // spelling but have different casing
  disallowCaseDuplicates?: boolean;
}

export function MultiCombobox(props: IProps) {
  const [query, setQuery] = useState("");

  const { selectedItems, setSelectedItems } = props;

  const filteredOptions = useMemo(
    () =>
      [
        // always list all of the selected items at the top of the list
        ...props.options
          .filter((item) => selectedItems.some((selectedItem) => selectedItem.value === item.value))
          .filter((item) => item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
          .sort((a, b) => a.label.localeCompare(b.label)),
        ...props.options
          .filter((item) => !selectedItems.some((selectedItem) => selectedItem.value === item.value))
          .filter((item) => item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ] as Item[],
    [query, props.options, selectedItems]
  );

  const showCreateNew =
    query !== "" &&
    !filteredOptions.some((item) => isDuplicate(Boolean(props.disallowCaseDuplicates), query, item.value));

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  function onChangeValue(values: Item[]) {
    setSelectedItems(values);
    setQuery("");
  }
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    // if the user presses delete and there's no query, focus the last selected item
    if (query === "" && event.key === "Backspace") {
      const newSelectedItems = selectedItems.slice(0, selectedItems.length - 1);
      setSelectedItems(newSelectedItems);
    }

    // if the user presses enter and there are no suggestions, create a new item
    if (event.key === "Enter" && !filteredOptions.some((item) => item.value === query)) {
      onCreateNew(query);
    }
  }

  function onCreateNew(value: string) {
    props.onCreateNew?.(value);
    const newValues = [...selectedItems, { value, label: value }];
    onChangeValue(newValues);
  }

  function removeSelectedItem(item: Item) {
    const newValues = selectedItems.filter((i) => i.value !== item.value);
    onChangeValue(newValues);
  }

  function handleWrapperClick(e: React.MouseEvent<HTMLDivElement>) {
    inputRef.current?.focus();
  }

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    },
    [setQuery]
  );

  useEffect(function calculateWrapperDimensions() {
    if (!wrapperRef.current) return;
    const rect = wrapperRef.current.getBoundingClientRect();
    wrapperRef.current.style.setProperty("--input-wrapper-height", `${rect.height}px`);
    wrapperRef.current.style.setProperty("--input-wrapper-width", `${rect.width}px`);
  });

  return (
    <Combobox immediate multiple value={selectedItems} by="value" onChange={onChangeValue} onClose={() => setQuery("")}>
      <div
        className={classNames(style.combobox, {
          [style[`size-${props.size}`]]: props.size,
        })}
        ref={wrapperRef}
        onClick={handleWrapperClick}
        onFocus={props.onFocus}
      >
        <div className={style.selectedOptions}>
          {selectedItems.map((item) => (
            <Button
              key={item.value}
              level="invert"
              size="micro"
              onClick={() => removeSelectedItem(item)}
              clickOnBackspace
              className={style.selectedItem}
            >
              {item.label}
            </Button>
          ))}

          <ComboboxInput
            // @ts-ignore
            onKeyDown={handleKeyDown}
            placeholder={props.placeholder}
            onChange={onInputChange}
            className={style.input}
            value={props.focusedPlaceholder ?? query}
            ref={inputRef}
          />
        </div>

        <ComboboxOptions modal={false} anchor={false} className={style.optionsList} ref={optionsListRef}>
          {filteredOptions.map((option) => (
            <Option key={option.value} className={classNames(style.option)} label={option.label} value={option.value}>
              {({ selected }) => (
                <>
                  <Checkbox checked={selected} className={style.checkbox} size="sm" color="invert" />
                  <Text>{getBoldedMatchingText(option.label, query)}</Text>
                </>
              )}
            </Option>
          ))}

          {showCreateNew && (
            <div
              key="create-new"
              className={classNames(style.option, style.createNew)}
              onClick={() => onCreateNew(query)}
            >
              <Icon Icon={<Add />} size="xs" className={style.createNewIcon} />
              <Text className={style.createNewText}>
                <span className={style.labelText}>{props.createNewText || "Create: "}</span>
                <span className={style.queryText}>"{query}"</span>
              </Text>
            </div>
          )}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
}

interface OptionProps {
  className?: string;
  disabled?: boolean;
  label: string;
  value: string;
  innerClassName?: string;
  children?: (props: { selected: boolean }) => React.ReactNode;
}

export const Option = memo(
  forwardRef<HTMLDivElement, OptionProps>(function Option(props, forwardedRef) {
    const ChildComponent = props.children;
    return (
      <ComboboxOption
        key={props.value}
        value={{
          value: props.value,
          label: props.label,
        }}
        className={classNames(style.option, props.className)}
      >
        {({ selected }) => (
          <div className={style.optionInner} ref={forwardedRef}>
            {ChildComponent && <ChildComponent selected={selected} />}
          </div>
        )}
      </ComboboxOption>
    );
  })
);

export default MultiCombobox;
