import { Editor } from "@tiptap/core";
import { IUser } from "../../../../shared/types/User";

/**
 * Parses the TipTap editor content and returns the mentioned users and a plaintext representation of the content
 * @param editor TipTap Editor instance
 * @param mentionableUsers Array of users that can be mentioned
 */
const getEditorText = (
  editor: Editor | null,
  mentionableUsers: IUser[]
): { contentToSave: string; mentionedUsersInfo: IUser[] } => {
  const mentionedUsersInfo: IUser[] = [];

  interface CommentParagraph {
    type: "paragraph";
    content: ({ text: string; type: "text" } | { type: "mention"; attrs: { id: string } })[];
  }

  const json = editor?.getJSON();
  const paragraphs: CommentParagraph[] | undefined = json?.content as CommentParagraph[] | undefined;

  const contentToSave =
    paragraphs
      ?.map((paragraph) => {
        const text = paragraph.content
          ?.map((item) => {
            if (item.type === "text") {
              return item.text;
            } else if (item.type === "mention") {
              const mentionedUser = mentionableUsers.find((mention) => mention.userId === item.attrs.id);
              if (!mentionedUser) {
                return "";
              }
              mentionedUsersInfo.push(mentionedUser);
              return "<@" + mentionedUser.userId + ">";
            }
            return "";
          })
          .join("");
        return text;
      })
      .join("\n") || "";

  return { contentToSave, mentionedUsersInfo };
};

export default getEditorText;
