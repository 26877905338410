import { Types } from "mongoose";
import { z } from "zod";
import { ICommentThread } from "./CommentThread";
import { BackendSchema, FrontendSchema, ZObjectId } from "./lib";

const ZActualComponentVariableBase = z.object({
  variable_id: ZObjectId,
  name: z.string(),
});

const ZVariableNumber = z.object({
  type: z.literal("number"),
  data: z.object({
    example: z.number(),
    fallback: z.union([z.number(), z.string()]).optional(),
  }),
});

export type VariableNumber = z.infer<typeof ZVariableNumber>;

const ZVariableString = z.object({
  type: z.literal("string"),
  data: z.object({
    example: z.string(),
    fallback: z.string().optional(),
  }),
});

export type VariableString = z.infer<typeof ZVariableString>;

const ZVariableHyperlink = z.object({
  type: z.literal("hyperlink"),
  data: z.object({
    text: z.string(),
    url: z.string(),
  }),
});

export type VariableHyperlink = z.infer<typeof ZVariableHyperlink>;

const ZVariableList = z.object({
  type: z.literal("list"),
  data: z.string().array(),
});

export type VariableList = z.infer<typeof ZVariableList>;

const ZVariableMap = z.object({
  type: z.literal("map"),
  data: z.record(z.string(), z.string()),
});

export type VariableMap = z.infer<typeof ZVariableMap>;

const ZVariablaData = z.discriminatedUnion("type", [
  ZVariableNumber,
  ZVariableString,
  ZVariableHyperlink,
  ZVariableList,
  ZVariableMap,
]);

export type VariableData = z.infer<typeof ZVariablaData>;

export const ZActualComponentVariableSchema = ZActualComponentVariableBase.and(ZVariablaData);
export type ActualComponentVariableSchema = z.infer<typeof ZActualComponentVariableSchema>;

// MARK: - TipTap Zod Types

const ZTipTapMarks = z.object({
  type: z.enum(["bold", "italic", "underline", "superscript", "subscript", "template_highlight"]),
});
export type ITipTapMarks = z.infer<typeof ZTipTapMarks>;

const ZTipTapVariableType = z.enum(["number", "string", "hyperlink", "list", "map"]);
export type ITipTapVariableType = z.infer<typeof ZTipTapVariableType>;

const ZTipTapVariable = z.object({
  type: z.literal("variable"),
  attrs: z.object({
    name: z.string(),
    text: z.string(),
    variableId: z.string(),
    variableType: ZTipTapVariableType,
  }),
  marks: z.array(ZTipTapMarks).optional(),
});

export type ITipTapVariable = z.infer<typeof ZTipTapVariable>;

const ZTipTapText = z.object({
  type: z.literal("text"),
  text: z.string(),
  marks: z.array(ZTipTapMarks).optional(),
});

export type ITipTapText = z.infer<typeof ZTipTapText>;

// Define the recursive types. This doesn't work with strict null checking unfortunately.
const ZTipTapContentElement = z.union([ZTipTapText, ZTipTapVariable]);
export type ITipTapContentElement = z.infer<typeof ZTipTapContentElement>;

// Define the schemas using the recursive types
const ZTipTapParagraph = z.object({
  type: z.literal("paragraph"),
  content: z.array(ZTipTapContentElement).optional(),
});
export type ITipTapParagraph = z.infer<typeof ZTipTapParagraph>;

export const ZTipTapRichText = z.object({
  type: z.literal("doc"),
  content: z.array(ZTipTapParagraph),
});
export type ITipTapRichText = z.infer<typeof ZTipTapRichText>;

// MARK: - Plurals

export const ZPluralForm = z.enum(["zero", "one", "two", "few", "many", "other"]);
export type PluralForm = z.infer<typeof ZPluralForm>;

export const ZPluralSchema = z.object({
  form: ZPluralForm,
  text: z.string(),
  rich_text: ZTipTapRichText.optional(),
  variables: z.array(ZObjectId).nullable(),
});

export type PluralSchema = z.infer<typeof ZPluralSchema>;

export interface VariantSchema {
  variantId: Types.ObjectId;
  text: string;
  rich_text: RichText;
  variables: ActualComponentVariableSchema[];
  plurals: PluralSchema[];
  lastSync: string | null;
  lastSyncRichText: RichText | null;
  text_last_modified_at: Date;
  status?: string | null;
}

/**
 * @deprecated Please use ITipTapContentElement instead of this interface.
 */
export type TipTapContentElement = ITipTapContentElement;

/**
 * @deprecated Please use ITipTapParagraph instead of this interface.
 */
export type TipTapParagraph = ITipTapParagraph;

/**
 * @deprecated Please use ITipTapMarks instead of this interface.
 */
export type Marks = ITipTapMarks;

/**
 * @deprecated Please use ITipTapText instead of this interface.
 */
export type TipTapText = ITipTapText;

/**
 * @deprecated Please use ITipTapVariable instead of this interface.
 */
export type TipTapVariable = ITipTapVariable;

/**
 * @deprecated Please use ITipTapRichText instead of this interface.
 */
export type RichText = ITipTapRichText;

export type FigmaApiStyles = "Italic" | "Bold" | "Extra Bold" | "Regular" | "Bold Italic" | "Extra Bold Italic";

export interface FigmaPluginTextSegment {
  characters: string;
  start: number;
  end: number;
  fontName: {
    family: string;
    style: FigmaApiStyles;
  };
  textDecoration: "UNDERLINE" | "NONE";
  indentation: number;
}

export interface StyleOverride {
  fontFamily?: string;
  fontPostScriptName?: string | null;
  fontWeight?: number;
  italic?: boolean;
  textDecoration?: string;
}

export interface FigmaApiText {
  id: string;
  name: string;
  characters: string;
  style: {
    fontFamily?: string;
    fontPostScriptName?: string;
    fontWeight?: number;
    fontSize?: number;
    textDecoration?: string;
    textAlignHorizontal?: string;
    textAlignVertical?: string;
    textAutoResize?: string;
    letterSpacing?: number;
    lineHeightPx?: number;
    lineHeightPercent?: number;
    lineHeightUnit?: string;
  };
  characterStyleOverrides: number[];
  styleOverrideTable: Record<string, StyleOverride>;
  lineIndentations: number[];
}

export interface TextItemFigmaIntegration {
  position: { x: number; y: number; width: number; height: number };
  type: string;
}

export interface ActualComponentSchema {
  _id: Types.ObjectId;
  workspace_ID: Types.ObjectId;
  doc_ID: Types.ObjectId;
  apiID: string | null;
  /**
   * The ID of the corresponding text node in Figma. Will be `null` for
   * text items in draft or unlinked groups.
   */
  figma_node_ID: string | null;
  /**
   * During resync, if we're not able to detect a Figma frame that should be attached to a given group,
   * all of the text items in that group have their `figma_node_ID` set to null. Since there are a
   * variety of ways in which this might happen accidentally (deleting the frame, componentizing the frame,
   * moving the frame to different page), caching the last known `figma_node_ID` allows us to much
   * more easily restore a Figma connection if necessary.
   *
   * We also move components to the graveyard when they're deleted from a frame but the frame remains linked.
   * In that case, resync does not unset the value of `figma_node_ID`, which means that we do not need to
   * set the cached value.
   *
   * This field will likely only ever have a value if the ActualComponent is in the graveyard.
   */
  figma_node_ID_cached?: string | null;
  text: string;
  integrations: {
    figma?: TextItemFigmaIntegration | {};
    figmaV2?: {
      instances?: {
        position: { x: number; y: number; width: number; height: number };
        figmaNodeId: string;
      }[];
    };
  };
  assignee: Types.ObjectId | null;
  assignedAt: Date | null;
  rich_text: RichText;
  variables: ActualComponentVariableSchema[];
  plurals: PluralSchema[];
  lastSync: string | null;
  lastSyncRichText: RichText | null;
  notes: string | null;
  tags: string[];
  status: ActualComponentStatus;
  date_time_created: Date | undefined;
  in_graveyard: boolean;
  is_hidden: boolean;
  graveyard_apiID: string | null;
  has_conflict: boolean;
  ws_comp: Types.ObjectId | null;
  comment_threads: ICommentThread[] | Types.ObjectId[];
  variants: VariantSchema[];
  isSample: boolean;
  characterLimit: number | null;
}

export const ZActualComponentStatus = z.enum(["NONE", "WIP", "REVIEW", "FINAL"]);
export type ActualComponentStatus = z.infer<typeof ZActualComponentStatus>;

/**
 * @deprecated Please use ITextItem instead of this interface.
 */
export interface ActualComponentInterface<IdType = string> {
  _id: IdType;
  workspace_ID: IdType;
  doc_ID: IdType;
  apiID: string | null;
  figma_node_ID: string | null;
  text: string;
  assignee: IdType | null;
  assignedAt: Date | null;
  rich_text: RichText;
  variables: ActualComponentVariableSchema[];
  plurals: PluralSchema[];
  lastSync: string | null;
  lastSyncRichText: RichText | null;
  notes: string | null;
  tags: string[];
  status: ActualComponentStatus;
  date_time_created: Date | undefined;
  in_graveyard: boolean;
  is_hidden: boolean;
  graveyard_apiID: string | null;
  has_conflict: boolean;
  ws_comp: IdType | null;
  comment_threads: ICommentThread[] | IdType[];
  variants: VariantSchema[];
  createdAt: Date;
  /**
   * This gets updated every time the document in MongoDB gets updated,
   * which is not necessarily the same as when the text is modified.
   */
  updatedAt: Date;
  /**
   * In contrast, like its name suggests, this gets updated every time
   * text is changed from the Ditto side.
   */
  text_last_modified_at: Date;
  characterLimit: number | null;
  isSample: boolean;
}

const ZTextItemFigmaIntegration = z.object({
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
  type: z.string(),
});

export const ZFigmaV2Instance = z.object({
  figmaNodeId: z.string(),
  lastReconciledRichText: ZTipTapRichText.nullable().optional(),
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
});

export type IFFigmaV2Instance = FrontendSchema<z.infer<typeof ZFigmaV2Instance>>;

const ZTextItemFigmaIntegrationV2 = z
  .object({
    instances: z.array(ZFigmaV2Instance).optional(),
  })
  .optional();

export type ITextItemFigmaIntegration = z.infer<typeof ZTextItemFigmaIntegration>;

const ZTextItemIntegrations = z.object({
  figma: ZTextItemFigmaIntegration.optional(),
  figmaV2: ZTextItemFigmaIntegrationV2.optional(),
});

const ZTextItemBaseVariable = z.object({
  variable_id: ZObjectId,
  name: z.string(),
});

const ZTextItemVariableNumber = ZTextItemBaseVariable.merge(
  z.object({
    type: z.literal("number"),
    data: z.object({
      example: z.number(),
      fallback: z.union([z.number(), z.string()]).optional(),
    }),
  })
);

const ZTextItemVariableString = ZTextItemBaseVariable.merge(
  z.object({
    type: z.literal("string"),
    data: z.object({
      example: z.string(),
      fallback: z.string().optional(),
    }),
  })
);

const ZTextItemVariableHyperlink = ZTextItemBaseVariable.merge(
  z.object({
    type: z.literal("hyperlink"),
    data: z.object({
      text: z.string(),
      url: z.string(),
    }),
  })
);

const ZTextItemVariableList = ZTextItemBaseVariable.merge(
  z.object({
    type: z.literal("list"),
    data: z.array(z.string()),
  })
);

const ZTextItemVariableMap = ZTextItemBaseVariable.merge(
  z.object({
    type: z.literal("map"),
    data: z.record(z.string()),
  })
);

export const ZTextItemVariable = z.discriminatedUnion("type", [
  ZTextItemVariableString,
  ZTextItemVariableNumber,
  ZTextItemVariableHyperlink,
  ZTextItemVariableList,
  ZTextItemVariableMap,
]);

export type ITextItemVariable = z.infer<typeof ZTextItemVariable>;
export type IFTextItemVariable = FrontendSchema<ITextItemVariable>;
export type IBTextItemVariable = BackendSchema<ITextItemVariable>;

export const ZTextItemPluralType = z.enum(["zero", "one", "two", "few", "many", "other"]);
export type TextItemPluralType = z.infer<typeof ZTextItemPluralType>;

export const ZTextItemPlural = z.object({
  form: ZTextItemPluralType,
  text: z.string(),
  rich_text: ZTipTapRichText,
  variables: z.array(ZObjectId),
});
export type ITextItemPlural = z.infer<typeof ZTextItemPlural>;
export type IFTextItemPlural = FrontendSchema<ITextItemPlural>;

export const TEXT_ITEM_STATUSES = ["NONE", "WIP", "REVIEW", "FINAL"] as const;
export const DEFAULT_TEXT_ITEM_STATUS = "NONE" as const;
export const ZTextItemStatus = z.enum(TEXT_ITEM_STATUSES);
export type ITextItemStatus = z.infer<typeof ZTextItemStatus>;

export const ZVariableRichValue = z.object({
  rich_text: ZTipTapRichText,
  plurals: z.array(ZTextItemPlural),
  variables: z.array(ZTextItemVariable),
  text: z.string(),
  characterLimit: z.number().nullable(),
});
export type ITextItemVariableRichValue = z.infer<typeof ZVariableRichValue>;

export const ZTextItemVariant = z.object({
  variantId: ZObjectId,
  text: z.string(),
  rich_text: ZTipTapRichText,
  lastSync: z.string().nullable(),
  lastSyncRichText: ZTipTapRichText.nullable(),
  variables: z.array(ZTextItemVariable),
  plurals: z.array(ZTextItemPlural),
  text_last_modified_at: z.date(),
  status: ZTextItemStatus.optional(),
});
export type ITextItemVariant = z.infer<typeof ZTextItemVariant>;
export type IFTextItemVariant = FrontendSchema<ITextItemVariant>;

export const ZTextItemVariantUpdate = z.object({
  variantId: z.string(),
  status: ZTextItemStatus.optional(),
  richText: ZTipTapRichText.optional(),
  /* TODO: Add support updating for complex inputs: https://linear.app/dittowords/issue/DIT-8363/support-complex-metadata-on-variants */
});
export type ITextItemVariantUpdate = z.infer<typeof ZTextItemVariantUpdate>;

export const filterableFields = {
  text: z.string(),
  rich_text: ZTipTapRichText,
  notes: z.string().nullable(),
  tags: z.array(z.string()),
  status: ZTextItemStatus,
  assignee: ZObjectId.nullable(),
  variants: z.array(ZTextItemVariant),
  variables: z.array(ZTextItemVariable),
  plurals: z.array(ZTextItemPlural),
};

export const ZTextItemFilterableFields = z.object(filterableFields);
export type ITextItemFilterableFields = z.infer<typeof ZTextItemFilterableFields>;

export function extractFilterableFields(textItem: ITextItem): ITextItemFilterableFields {
  const obj: Partial<ITextItemFilterableFields> = {};
  Object.keys(filterableFields).forEach((key) => {
    const castKey = key as keyof ITextItemFilterableFields;
    obj[castKey] = textItem[castKey] as any;
  });
  return obj as ITextItemFilterableFields;
}

export const ZTextItem = z.object({
  _id: ZObjectId,
  workspace_ID: ZObjectId,
  doc_ID: ZObjectId,
  apiID: z.string().nullable(),
  integrations: ZTextItemIntegrations,
  figma_node_ID: z.string().nullable(),
  figma_node_ID_cached: z.string().nullable(),
  lastSync: z.string().nullable(),
  lastSyncRichText: ZTipTapRichText.nullable(),
  date_time_created: z.date(),
  in_graveyard: z.boolean(),
  graveyard_apiID: z.string().nullable(),
  is_hidden: z.boolean(),
  ws_comp: ZObjectId.nullable(),
  comment_threads: z.array(ZObjectId), // In mongo, the ids of all comment threads, in the FE, only unresolved threads are included
  text_last_modified_at: z.date(),
  has_conflict: z.boolean(),
  isSample: z.boolean(),
  characterLimit: z.number().nullable(),
  assignedAt: z.date().nullable(),
  ...filterableFields,
  blockId: ZObjectId.nullable().optional(),
  version: z.union([z.literal(1), z.literal(2)]).optional(),
  sortKey: z.string().optional(),
});

export type ITextItem = z.infer<typeof ZTextItem>;

export const ZTextItemCreationSource = z.enum(["web_app", "plugin_text_import", "plugin_block_import"]);
export type ITextItemCreationSource = z.infer<typeof ZTextItemCreationSource>;
