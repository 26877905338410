import * as RDropdownMenu from "@radix-ui/react-dropdown-menu";
import { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu";
import React, { useCallback } from "react";
import DropdownMenuItem from "../../atoms/DropdownMenuItem";
import DropdownMenuLabel from "../../atoms/DropdownMenuLabel";
import DropdownMenuSeparator from "../../atoms/DropdownMenuSeparator";
import DropdownTrigger from "../../atoms/DropdownTrigger";
import style from "./index.module.css";

type MenuOption = {
  type: "option";
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

type MenuLabel = {
  type: "label";
  label: string;
};

type MenuSeparator = {
  type: "separator";
};

type DropdownMenuItem = MenuOption | MenuLabel | MenuSeparator;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  items: DropdownMenuItem[];
  RDropdownMenuContentProps?: DropdownMenuContentProps & React.RefAttributes<HTMLDivElement>;
}

export function DropdownMenu(props: IProps) {
  return (
    <RDropdownMenu.Root>
      <RDropdownMenu.Trigger asChild>
        <DropdownTrigger />
      </RDropdownMenu.Trigger>

      <RDropdownMenu.Portal>
        <RDropdownMenu.Content className={style.dropdownMenuContent} {...props.RDropdownMenuContentProps}>
          {props.items.map((item, index) => (
            <React.Fragment key={index}>
              {item.type === "option" && <MenuItem key={index} item={item} />}

              {item.type === "label" && (
                <RDropdownMenu.Label key={index} asChild>
                  <DropdownMenuLabel>{item.label}</DropdownMenuLabel>
                </RDropdownMenu.Label>
              )}

              {item.type === "separator" && (
                <RDropdownMenu.Separator key={index} asChild>
                  <DropdownMenuSeparator />
                </RDropdownMenu.Separator>
              )}
            </React.Fragment>
          ))}
        </RDropdownMenu.Content>
      </RDropdownMenu.Portal>
    </RDropdownMenu.Root>
  );
}

function MenuItem(props: { item: MenuOption }) {
  const { onClick } = props.item;
  const handleOnClick = useCallback(
    function _handleOnClick() {
      // We have to add a quick setTimeout here to ensure that we give the Radix component one more event loop iteration to properly close itself and remove any event listeners. This prevents issues we ran into where trying to present a dialog modal would prevent the event listeners from being removed, making the entire app feel non interactive.
      // We specifically use setTimeout instead of setImmediate here as the plugin doesn't support setImmediate without a polyfill.
      if (onClick) setTimeout(() => onClick(), 0);
    },
    [onClick]
  );

  return (
    <RDropdownMenu.Item className={style.dropdownMenuItemContainer}>
      <DropdownMenuItem onClick={handleOnClick} disabled={props.item.disabled}>
        {props.item.text}
      </DropdownMenuItem>
    </RDropdownMenu.Item>
  );
}

export default DropdownMenu;
