import classNames from "classnames";
import React, { forwardRef } from "react";
import { useNativeProps } from "../../useNativeProps";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

const DropdownMenuSeparator = forwardRef<HTMLDivElement, IProps>(function DropdownMenuSeparator(props, ref) {
  const nativeProps = useNativeProps<HTMLDivElement, IProps>(props, {});
  return (
    <div
      {...nativeProps}
      style={props.style}
      className={classNames(style.DropdownMenuSeparatorWrapper, props.className)}
      data-testid="dropdown-menu-separator"
      ref={ref}
    />
  );
});

export default DropdownMenuSeparator;
