import classNames from "classnames";
import React from "react";
import Text from "../Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  leftText: string;
  rightText: string;
  hoverLeft?: boolean;
  hoverRight?: boolean;
  onClick?: () => void;
}

// Renders a button with two text elements plus a divider in the middle, with an orange gradient border
// Pass in hoverLeft and/or hoverRight props to change the color of that text to action color on hover
// The css can be further generalized if needed for additional use cases

export function BetaButton(props: IProps) {
  return (
    <button className={classNames(style.button, props.className)} onClick={props.onClick} data-testid="beta-button">
      <div className={style.contentWrapper}>
        <Text
          color="secondary"
          size="micro"
          weight="medium"
          className={classNames({ [style.hoverAction]: props.hoverLeft })}
        >
          {props.leftText}
        </Text>
        <div className={style.divider} />
        <Text color="tertiary" size="micro" className={classNames({ [style.hoverAction]: props.hoverRight })}>
          {props.rightText}
        </Text>
      </div>
    </button>
  );
}

export default BetaButton;
