import classNames from "classnames";
import React, { forwardRef } from "react";
import { useNativeProps } from "../../useNativeProps";
import Text from "../Text";
import style from "./index.module.css";

interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const DropdownMenuLabel = forwardRef<HTMLDivElement, IProps>(function DropdownMenuLabel(props, ref) {
  const nativeProps = useNativeProps<HTMLDivElement, IProps>(props, {});
  return (
    <div
      {...nativeProps}
      style={props.style}
      className={classNames(style.DropdownMenuLabelWrapper, props.className)}
      data-testid="dropdown-menu-label"
      ref={ref}
    >
      <Text className={style.labelText}>{props.children}</Text>
    </div>
  );
});

export default DropdownMenuLabel;
