import DraggableProjectCard from "@/components/projects/components/DraggableProjectCard/DraggableProjectCard";
import http from "@/http";
import * as httpDittoProject from "@/http/dittoProject";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useFigmaAuth } from "@/store/FigmaAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import { NS_BETA_URL_PATH_NAME } from "@shared/common/constants";
import { userHasPermission, userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { ACTIVATION_PROGRESS_SEGMENT_COLORS, IFProjectSummary } from "@shared/types/http/project";
import logger from "@shared/utils/logger";
import classnames from "classnames";
import Fuse from "fuse.js";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useHistory, useLocation } from "react-router-dom";
import { getInviteOnlyFoldersEnabled } from "../../../util/folder";
import spinner from "../../assets/small-spinner.gif";
import useSegment from "../../hooks/useSegment";
import * as httpProjects from "../../http/project";
import DraftingImportModal from "../DraftingImportModal";
import { ImportStep } from "../DraftingImportModal/useImport";
import InviteCollaboratorsModal from "../InviteCollaboratorsModal";
import InviteFolderUsers from "../InviteFolderUsers";
import NewFolderModal from "../NewFolderModal";
import OverlayToast from "../OverlayToast";
import { useOverlayToast } from "../OverlayToast/useOverlayToast";
import ProjectFolder from "../ProjectFolder";
import ShareFolderModal from "../ShareFolderModal";
import FolderCard from "../card/folderCard";
import NewFolderCard from "../card/newFolderCard";
import NewCard from "../card/newcard";
import NotificationToast from "../notification-toast";
import PermissionRequiredFolder from "../permissions/PermissionRequired/PermissionRequiredFolder";
import SearchInput from "../search-input/search-input";
import ConfirmationModal from "../shared/confirmation-modal";
import TitleBar from "../title-bar/title-bar";
import style from "./style.module.css";
import { useFolder } from "./useFolder";

function Projects() {
  const queryParams = new URLSearchParams(window.location.search);

  const history = useHistory();
  const { showToast, hideToast, overlayToastProps } = useOverlayToast();

  const [projects, setProjects] = useState<IFProjectSummary[]>([]);
  const [northStarProjects, setNorthStarProjects] = useState<IFProjectSummary[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<IFProjectSummary[]>([]);
  const [importModalOpen, setImportModalOpen] = useState(queryParams.get("openImportModal") ?? false);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [initialImportStep, setInitialImportStep] = useState(
    queryParams.get("openImportModal") ? ImportStep.EXISTING_PROJECT : undefined
  );
  const [notification, setNotification] = useState<{ type: string; message: string } | null>(null);

  const { workspaceInfo } = useWorkspace();
  const segment = useSegment();
  const location = useLocation();
  const { user } = useAuthenticatedAuth();
  const { isFigmaAuthenticated } = useFigmaAuth();
  const isEditEnabled = userHasResourcePermission("project_folder:edit");
  const isCommentEnabled = userHasResourcePermission("project_folder:comment");
  const canCreateNewFolder = userHasPermission("project_folder:create");

  const inviteOnlyFoldersEnabled = getInviteOnlyFoldersEnabled(workspaceInfo);
  const isAdminUser = user?.billingRole === "admin";
  const filtersEnabled = query.length > 0;

  const {
    activeFolder,
    folderId,
    workspaceFolders,
    workspaceFolderIdMap,
    folderModalOpen,
    inviteFolderModalOpen,
    folderConfirmationModalOpen,
    handleConfirmMoveProject,
    openFolderModal,
    closeFolderModal,
    closeInviteFolderModal,
    showShareFolderModal,
    openShareFolderModal,
    closeShareFolderModal,
    handleAddToFolder,
    handleCreateNewFolder,
    handleDeleteFolder,
    handleGetAllFolders,
    handleRemoveFromFolder,
    handleRenameFolder,
    unauthorizedFolderAccess,
    handleFolderMadeInviteOnly,
    handleUserRemovedSelf,
  } = useFolder({ setProjects, inviteOnlyFoldersEnabled, isAdminUser });

  function _handleRemoveFromFolder(folderId: string, docId: string, isNorthStar: boolean) {
    if (isNorthStar) {
      httpDittoProject.moveProjectToFolder({
        projectId: docId,
        folderId: null,
      });
      setNorthStarProjects((prev) => {
        let docIndex = prev.findIndex((doc) => doc._id == docId);
        prev[docIndex].folder = undefined;

        return [...prev];
      });
    } else {
      handleRemoveFromFolder(folderId, docId);
    }
  }
  const currentFolder = folderId ? workspaceFolderIdMap[folderId] : undefined;

  const uniqueFolderNames = useMemo(
    function getUniqueFolderNames() {
      return Array.from(new Set(workspaceFolders.map(({ name }) => name)));
    },
    [workspaceFolders]
  );

  const userHasAnyAccessToContext = useMemo(
    function userHasAnyAccessToContext() {
      return isEditEnabled || isCommentEnabled || location.pathname === "/projects";
    },
    [isEditEnabled, isCommentEnabled, location.pathname]
  );

  useEffect(
    function filterProjectsEffect() {
      let folderIds = workspaceFolders.map((folder) => folder._id);
      let tempDocs = projects.concat(northStarProjects);
      if (!filtersEnabled) {
        tempDocs = tempDocs.filter(
          (doc) => (!folderId && (!doc.folder || !folderIds.includes(doc.folder._id))) || doc.folder?._id === folderId
        );
      }

      if (query) {
        const fuse = new Fuse(tempDocs, {
          includeScore: true,
          ignoreLocation: true,
          location: 0,
          distance: 20,
          threshold: 0,
          keys: ["name"],
        });
        tempDocs = fuse.search(query).map((item) => item.item);
      }

      tempDocs = tempDocs.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

      setFilteredProjects(tempDocs);
    },
    [query, projects, northStarProjects, folderId, workspaceFolders]
  );

  useEffect(function fetchProjectsEffect() {
    fetchProjects();
    fetchNorthStarProjects();
  }, []);

  // show notification toasts for 5s
  useEffect(
    function showNotificationEffect() {
      if (notification)
        setTimeout(() => {
          setNotification(null);
        }, 5000);
    },
    [notification]
  );

  const handleQueryChange = (e) => {
    setQuery(e.target.value.toLowerCase());
  };

  async function fetchProjects() {
    try {
      await handleGetAllFolders(folderId);

      const [request] = httpProjects.getProjectSummaries();
      const { data: projects } = await request;
      for (const project of projects) {
        project.statusProgress.totalPercentageColor = ACTIVATION_PROGRESS_SEGMENT_COLORS.FINAL_PERCENTAGE;
      }

      setProjects(projects);
      setProjectsLoading(false);
    } catch (error) {
      console.error("Error in projects.jsx: ");
      console.error(error);
    }
  }

  async function fetchNorthStarProjects() {
    if (!workspaceInfo.feature_flags.northStar) return;
    try {
      const [request] = httpProjects.getNorthStarProjectSummaries();
      const { data: projects } = await request;
      for (const project of projects) {
        project.statusProgress.totalPercentageColor = ACTIVATION_PROGRESS_SEGMENT_COLORS.FINAL_PERCENTAGE;
      }

      setNorthStarProjects(projects);
    } catch (error) {
      logger.warn("Failed to fetch North Star projects", error);
    }
  }

  const onCreateNewFolder = async (name, inviteOnly = false) => {
    await handleCreateNewFolder(name, inviteOnly);
    closeFolderModal();
  };

  const newProject = () => {
    segment.track({
      event: SegmentEvents.IMPORT_MODAL_OPENED,
    });
    setImportModalOpen(true);
  };

  async function newPopulatedNorthStarProject() {
    try {
      const response = await http.post(`/ditto-project/createPopulatedDittoProject`, {
        name: `Test NS Project ${getDateString()}`,
        numberOfBlocks: 10,
        maxNumberOfTextItemsInBlocks: 10,
        textItemsOutsideOfBlocks: 10,
      });

      history.push(`/${NS_BETA_URL_PATH_NAME}/` + response.data._id);
    } catch (error) {
      logger.warn("Failed to create new North Star project", error);
    }
  }

  const closeNewProject = () => {
    setImportModalOpen(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const {
      destination: { droppableId: folderId },
      draggableId: docId,
      source: { droppableId },
    } = result;
    if (folderId === droppableId || !docId) {
      return;
    }

    const project = filteredProjects.find((doc) => doc._id === docId);
    if (!project) {
      return;
    }

    if (project.isSample) {
      showToast("The sample project cannot be moved into a folder.");
      setTimeout(() => hideToast(), 3000);
    } else if (project.isNorthStar) {
      httpDittoProject.moveProjectToFolder({
        projectId: docId,
        folderId: folderId,
      });
      setNorthStarProjects((prev) => {
        let docIndex = prev.findIndex((doc) => doc._id == docId);
        prev[docIndex].folder = {
          _id: folderId,
          name: workspaceFolderIdMap[folderId].name,
        };

        return [...prev];
      });
    } else {
      handleAddToFolder(folderId, docId, project.isNorthStar);
    }
  };

  // Determine if the user has created any projects yet, for the welcome tooltip
  const { isFirstProject, hasSampleProject } = useMemo(() => {
    // Sample projects only exist in legacy projects
    const sampleProjects = projects.filter((doc) => doc.isSample);
    return {
      isFirstProject: projects.length + northStarProjects.length - sampleProjects.length === 0,
      hasSampleProject: sampleProjects.length > 0,
    };
  }, [projects, northStarProjects]);

  if (unauthorizedFolderAccess || !userHasAnyAccessToContext) {
    return <PermissionRequiredFolder />;
  }
  const isPermissionGroupsEnabled = workspaceInfo?.plan === "enterprise";

  return (
    <div className={style.container}>
      <TitleBar title="Projects" />
      <div className={style.resultscontainer}>
        <div className={classnames("container", style.projresults)}>
          <SearchInput
            className={style.searchInput}
            value={query}
            onChange={handleQueryChange}
            name="Projects"
            loading={false}
            docSearch={false}
          />
          {!projectsLoading && !filtersEnabled && folderId && activeFolder && (
            <ProjectFolder
              folder={activeFolder}
              uniqueFolderNames={uniqueFolderNames}
              docs={filteredProjects}
              openShareFolderModal={openShareFolderModal}
              handleCreateNewProject={newProject}
              handleDeleteFolder={handleDeleteFolder}
              handleRenameFolder={handleRenameFolder}
              handleRemoveFromFolder={_handleRemoveFromFolder}
            />
          )}
          {(!folderId || filtersEnabled) && !projectsLoading && (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                {!filtersEnabled && (
                  <div className={style.folderSection}>
                    <h2>Folders</h2>
                    <div className={classnames("row", style.allProjects)}>
                      {query.length === 0 && (
                        <>
                          {canCreateNewFolder && <NewFolderCard openModal={openFolderModal} />}

                          {workspaceFolders.map((folder, index) => (
                            <FolderCard key={index} folder={folder} />
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className={style.otherProjectsSection}>
                  {!filtersEnabled && <h2>Projects</h2>}
                  <Droppable droppableId="projects" type="PROJECT" isDropDisabled={true}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classnames("row", style.allProjects)}
                      >
                        {isEditEnabled && !filtersEnabled && (
                          <NewCard
                            newProject={newProject}
                            isFirstProject={isFirstProject}
                            hasSampleProject={hasSampleProject}
                          />
                        )}
                        {filteredProjects.map((project, index) => (
                          <DraggableProjectCard
                            key={project._id}
                            project={project}
                            index={index}
                            isDragDisabled={!isEditEnabled || query.length > 0}
                            canRemoveFromFolder={false}
                            handleRemoveFromFolder={handleRemoveFromFolder}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </>
          )}
          {!projectsLoading && query && filteredProjects.length === 0 && (
            <div className={style.noResults}>
              No projects matching <strong>{query}</strong>.
            </div>
          )}
          {projectsLoading && (
            <div className={style.loadMore}>
              <div>Loading</div>
              <img src={spinner} />
            </div>
          )}
        </div>
      </div>
      {importModalOpen && (
        <DraftingImportModal
          hasFigmaConnected={isFigmaAuthenticated}
          onHide={closeNewProject}
          isFirstProject={isFirstProject}
          folder={currentFolder}
          initialStep={initialImportStep}
        />
      )}
      {folderModalOpen && (
        <NewFolderModal
          inviteOnlyFoldersEnabled={inviteOnlyFoldersEnabled}
          onHide={closeFolderModal}
          folderNames={uniqueFolderNames}
          onCreateNewFolder={onCreateNewFolder}
        />
      )}
      {inviteFolderModalOpen && activeFolder && (
        <InviteFolderUsers folder={activeFolder} currentUser={user} onHide={closeInviteFolderModal} />
      )}
      {/* these two modals are for sharing project folders */}
      {!isPermissionGroupsEnabled && showShareFolderModal && activeFolder && (
        <ShareFolderModal
          folder={activeFolder}
          inviteOnlyFoldersEnabled={inviteOnlyFoldersEnabled}
          currentUser={user}
          onHide={closeShareFolderModal}
          handleFolderMadeInviteOnly={handleFolderMadeInviteOnly}
          handleUserRemovedSelf={handleUserRemovedSelf}
        />
      )}
      {isPermissionGroupsEnabled && showShareFolderModal && activeFolder && (
        <InviteCollaboratorsModal
          inviteContext={{
            type: "folder",
            folder: activeFolder,
          }}
          currentUser={user}
          onHide={closeShareFolderModal}
        />
      )}
      {notification && <NotificationToast notification={notification} noPadding={false} />}
      {folderConfirmationModalOpen && (
        <ConfirmationModal
          title="Are you sure you want to move this project?"
          body={
            !folderId ? (
              <>
                You’re moving this project into an <strong>invite-only</strong> folder, so it will{" "}
                <strong>no longer</strong> be accessible to everyone in this workspace.
              </>
            ) : (
              <>
                You’re moving this project out of an <strong>invite-only</strong> folder, so it will now be accessible
                to everyone in this workspace.
              </>
            )
          }
          actionPrimary="Yes, move project"
          actionSecondary="Cancel"
          onPrimary={() => handleConfirmMoveProject(true)}
          onSecondary={() => handleConfirmMoveProject(false)}
        />
      )}
      <OverlayToast {...overlayToastProps} />
    </div>
  );
}

export default Projects;

function getDateString() {
  const date = new Date();
  const pad = (num: number) => num.toString().padStart(2, "0");
  const to12hrTime = (hours: number) => (hours > 12 ? hours - 12 : hours);
  const hours = pad(to12hrTime(date.getHours()));
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${date.getMonth()}/${date.getDate()} ${hours}:${minutes}:${seconds}`;
}
