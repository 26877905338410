import classNames from "classnames";
import React from "react";
import { IChangeItem } from "../../../../shared/types/ActualChange";
import ChangeItemContent from "../../atoms/ChangeItemContent";
import ChangeItemHeader from "../../atoms/ChangeItemHeader";
import ChangeItemCountBadge, { ChangeItemBadgeData } from "../ChangeItemCountBadge";
import style from "./index.module.css";

interface IProps {
  changeItem: IChangeItem;
  onClick?: () => void | Promise<void>;
  badge?: ChangeItemBadgeData;
}

export function ChangeItem({ changeItem, badge, onClick }: IProps) {
  return (
    <div
      className={classNames(style.ChangeItemWrapper, { [style.clickable]: !!onClick })}
      data-testid="change-item"
      onClick={onClick}
    >
      <ChangeItemHeader changeItem={changeItem} />
      <div className={style.ChangeItemContentArea}>
        <ChangeItemContent changeItem={changeItem} />
        {!!badge && <ChangeItemCountBadge type={badge.type} count={badge.count} />}
      </div>
    </div>
  );
}

export default ChangeItem;
