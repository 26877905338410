import useSegment from "@/hooks/useSegment";
import {
  figmaSyncActionAtom,
  figmaSyncErrorMessageAtom,
  figmaSyncMessageAtom,
  isFigmaSyncingAtom,
} from "@/stores/FigmaSync";
import {
  deferredProjectNameAtom,
  editableProjectNameAtom,
  resetEditableProjectNameAtom,
  saveProjectNameActionAtom,
} from "@/stores/Project";
import BetaButton from "@ds/atoms/BetaButton";
import Button from "@ds/atoms/Button";
import InlineEditableName from "@ds/molecules/InlineEditableName";
import { Tooltip } from "@ds/molecules/Tooltip";
import FigmaSyncIcon from "@shared/frontend/FigmaSyncIcon/FigmaSyncIcon";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_INTEREST_GENERAL } from "@shared/segment-event-names";
import { getNoSecondsFormatter } from "@shared/utils/timeAgoFormatters";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import ReactTimeago from "react-timeago";
import style from "./style.module.css";

function NavBar() {
  const figmaSyncErrorMessage = useAtomValue(figmaSyncErrorMessageAtom);
  const isFigmaSyncing = useAtomValue(isFigmaSyncingAtom);
  const figmaSyncAction = useSetAtom(figmaSyncActionAtom);
  const figmaSyncMessage = useAtomValue(figmaSyncMessageAtom);
  const { track } = useSegment();

  // logic to show the inline editable icon when hovering anywhere in the navbar
  const [hovering, setHovering] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(function addHoverListeners() {
    function handleMouseEnter() {
      setHovering(true);
    }

    function handleMouseLeave() {
      setHovering(false);
    }

    const containerEl = containerRef.current;

    containerEl?.addEventListener("mouseenter", handleMouseEnter);
    containerEl?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      containerEl?.removeEventListener("mouseenter", handleMouseEnter);
      containerEl?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const handleFigmaSyncClick = useCallback(() => {
    figmaSyncAction();
  }, [figmaSyncAction]);

  const handleBetaButtonClick = useCallback(() => {
    window.open(LEARN_MORE_URL.BETA_BUTTON, "_blank");
    track({ event: NS_BETA_INTEREST_GENERAL });
  }, [track]);

  const FigmaSyncLabel = useMemo(() => {
    if (figmaSyncMessage instanceof Date) {
      return (
        <span>
          Synced with Ditto&nbsp;
          <ReactTimeago
            date={figmaSyncMessage}
            key={figmaSyncMessage.toString()}
            formatter={getNoSecondsFormatter("less than a minute ago")}
          />
        </span>
      );
    }
    return figmaSyncMessage;
  }, [figmaSyncMessage]);

  return (
    <div className={style.navBarContainer} ref={containerRef}>
      <Suspense fallback={<Skeleton height={17} width={120} baseColor="var(--bg-secondary)" />}>
        <ProjectName hovering={hovering} />
      </Suspense>

      <div className={style.rightSection}>
        <BetaButton leftText="BETA" rightText="Learn more" hoverRight onClick={handleBetaButtonClick} />
        <Tooltip forceText={true} side="left" type="invert" content={FigmaSyncLabel}>
          <Button
            type="icon"
            level={!!figmaSyncErrorMessage ? "danger" : "secondary"}
            showDot={!!figmaSyncErrorMessage}
            onClick={handleFigmaSyncClick}
            className={classNames({ [style.showActiveFigmaSyncButton]: isFigmaSyncing })}
          >
            <FigmaSyncIcon isLoading={isFigmaSyncing} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

function ProjectName(props: { hovering: boolean }) {
  const setProjectName = useSetAtom(editableProjectNameAtom);
  const projectName = useAtomValue(deferredProjectNameAtom);
  const resetProjectName = useSetAtom(resetEditableProjectNameAtom);
  const saveProjectNameAction = useSetAtom(saveProjectNameActionAtom);

  function onReset() {
    resetProjectName();
  }

  return (
    <>
      <InlineEditableName
        name={projectName}
        onChange={setProjectName}
        onSave={saveProjectNameAction}
        onReset={onReset}
        onBlur={onReset}
        variant="header"
        forceHoverState={props.hovering}
      />
      <Helmet>
        <title>{projectName}</title>
      </Helmet>
    </>
  );
}

export default NavBar;
