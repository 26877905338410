import { NotificationContext } from "@/store/notificationContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ReplyIcon from "@mui/icons-material/Reply";
import classnames from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import TimeAgo from "react-timeago";
import { getRenderedLinks } from "../../hooks/useRenderedLinks";
import http, { API } from "../../http";
import { getCurrentPageLocation, pageLocationEnum } from "../../utils/history";
import CommentEditor, { Mention } from "../comment-editor/comment-editor";

import { AuthenticatedAuthState } from "@/hooks/auth";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { ActualComponentVariableSchema, RichText } from "@shared/types/TextItem";
import SuggestionResolution from "../comment-editor/SuggestionResolution";
import EditDiff from "../editdiff/editdiff";
import { FirstComment } from "./FirstComment";
import style from "./style.module.css";

const urlExpression = /([\w\d-.@:%\/\+~#=]{1,256}\.[\w\d]{2,6}(?:(?:[?\/][-\w\d@:%&\/\[\]?._\+~#=]*)|(?!\w)))/;
const mentionExpression = /(<@.+?>)/;
const exp = new RegExp(`${mentionExpression.source}|${urlExpression.source}`, "g");

const getNameGivenId = (id, workspaceUsers) => {
  if (id.startsWith("sample_")) {
    return `${id.replace("sample_", "")}`;
  }

  let index = workspaceUsers.findIndex((user) => user.userId === id);
  if (index >= 0) {
    return workspaceUsers[index].name;
  }
  return "[removed user]";
};

const MENTION_EXPRESSION = /<@(.+?)>/g;
const applyMentionSubstitution = (jsx, workspaceUsers): React.ReactNode[] => {
  if (typeof jsx !== "string") {
    return [jsx];
  }

  const parts: React.ReactNode[] = jsx.split(MENTION_EXPRESSION);
  for (let i = 1; i < parts.length; i += 2) {
    const name = getNameGivenId(parts[i], workspaceUsers);
    parts[i] = (
      <span className={style.mention} key={`comment-${name}-${i}`}>
        {"@" + name}
      </span>
    );
  }
  return parts;
};

export interface SuggestionData {
  text_before?: string;
  text_after?: string;
  rich_text_before?: RichText;
  rich_text_after?: RichText;
  accepted: boolean | null;
  variables?: ActualComponentVariableSchema[];
}

export interface SuggestionMetadata {
  compId?: string;
  wsCompId?: string;
}

export interface CommentThreadProps {
  comments: {
    createdAt: string;
    text: string;
    updatedAt: string;
    user_id: string;
    user_name: string;
    _id: string;
  }[];
  createdAt: string;
  text: string;
  updatedAt: string;
  user_id: string;
  user_name: string;
  _id: string;
  default_replies_open: boolean;
  doc_name: string | undefined;
  isActiveComment: boolean;
  isDisabled: boolean;
  is_resolved: boolean;
  should_autofocus: boolean;
  thread_id: string;
  workspaceUsers: Mention[];
  comp_id?: string;
  ws_comp_id?: string;
  isInlineComment: boolean;
  className?: string;
  classNameCommentEditor?: string;
  suggestion?: SuggestionData | null;
  onAcceptSuggestion: (threadId: string, data: SuggestionData, metadata: SuggestionMetadata) => void;
  onRejectSuggestion: (threadId: string, data: SuggestionData, metadata: SuggestionMetadata) => void;

  fetchCompInfo: (shouldUpdateCompHistory: boolean) => void;
  forceShowToast: (arg: { title: string; body: string; autoHide: boolean }) => void;
  updateCommentThread: (threadObject: any, incrementCompResultComments?: boolean) => void;
  handleCommentChangeClick: (componentId: string, threadId: string, someBool?: boolean) => void;
  handleInlineReplyClick: (threadId: string) => void;
  handleScrollTo: (componentId: string) => void;
  handleDocUpdateParent: () => void;
  handleHistoryUpdate: (updateCompHistory: boolean, updateDocHistory: boolean) => void;

  onPostReply?: (commentThread: { _id: string; comments: {}[] }) => void;
  onResolve?: (commentThread: { _id: string; is_resolved: boolean }) => void;
}

interface CommentThreadContextProps {
  fetchNotifications: () => void;
  user: AuthenticatedAuthState["user"];
}

export type CommentThreadFunctionProps = Pick<
  CommentThreadProps,
  | "handleHistoryUpdate"
  | "handleInlineReplyClick"
  | "handleScrollTo"
  | "handleDocUpdateParent"
  | "handleCommentChangeClick"
  | "updateCommentThread"
  | "fetchCompInfo"
  | "forceShowToast"
  | "onAcceptSuggestion"
  | "onRejectSuggestion"
>;

export type CommentThreadOtherProps = Omit<CommentThreadProps, keyof CommentThreadFunctionProps>;

const Reply = ({ comment }) => (
  <div className={style.reply}>
    <div className={style.nameAndTime}>
      {comment.user_name}, <TimeAgo date={comment.createdAt} minPeriod={30} key={comment.createdAt.toString()} />
    </div>
    <div className={style.commentText}>{comment.displayJsx}</div>
  </div>
);

export const CommentThread = (props: CommentThreadProps & CommentThreadContextProps) => {
  const {
    comp_id,
    ws_comp_id,
    handleCommentChangeClick,
    handleInlineReplyClick,
    isDisabled,
    isActiveComment,
    isInlineComment,
    thread_id,
    is_resolved,
    comments,
    updateCommentThread,
    should_autofocus,
    default_replies_open,
    handleHistoryUpdate,
    doc_name,
    handleDocUpdateParent,
    fetchCompInfo,
    forceShowToast,
    workspaceUsers,
    handleScrollTo,
    className,
    classNameCommentEditor,
    onPostReply,
    onResolve,
    suggestion,
    user,
    fetchNotifications,
  } = props;

  const location = useLocation();
  const isInComponentLibrary = getCurrentPageLocation(location) === pageLocationEnum.componentLibrary;
  const [repliesOpen, setRepliesOpen] = useState(should_autofocus || default_replies_open);

  const suggestionButtonsDisabled = user?.role === "commenter";

  useEffect(() => {
    if (isActiveComment) {
      setRepliesOpen(true);
    } else {
      setRepliesOpen(false);
    }
  }, [isActiveComment]);

  const toggleRepliesOpen = () => {
    setRepliesOpen(!repliesOpen);
  };

  const formattedComments = useMemo(() => {
    let formatted = comments.map((comment) => ({
      ...comment,
      displayJsx: applyMentionSubstitution(comment.text, workspaceUsers),
    }));

    // Suggestions should have the first comment in the thread render a diff
    // between the current text and the text that is being suggested.
    if (suggestion) {
      formatted[0] = {
        ...formatted[0],
        displayJsx: [
          <span key="title" className={style.suggestionLabel}>
            Suggestion
          </span>,
          <EditDiff
            key="diff"
            showRichText
            text_before={suggestion.text_before}
            text_after={suggestion.text_after}
            rich_text_before={suggestion.rich_text_before}
            rich_text_after={suggestion.rich_text_after}
          />,
        ],
      };
    }

    formatted.forEach((val, index, arr) => {
      val.displayJsx = val.displayJsx
        .map((jsx) => {
          if (typeof jsx === "string") {
            return getRenderedLinks(jsx, "primary");
          } else {
            return jsx;
          }
        })
        .flat();
      arr[index] = val;
    });
    return formatted;
  }, [workspaceUsers, comments, suggestion]);

  const postReply = async (commentText, mentionedUsersInfo, clearInput) => {
    try {
      const { url, body } = API.comments.post.postToThread;
      const { data: newReplyCompThread } = await http.post(
        url(thread_id),
        body({
          text: commentText,
          doc_name: doc_name,
          mentionedUserIds: mentionedUsersInfo.map((user) => user.userId),
          from: "web_app",
        })
      );

      if (onPostReply) {
        onPostReply(newReplyCompThread);
      }

      setRepliesOpen(true);

      // 1. Update project history and comp history
      handleHistoryUpdate(true, true);
      // 2. Update thread history
      updateCommentThread(newReplyCompThread, false);
      // 3. Clear input
      clearInput();

      markCommentNotifsRead();
    } catch (e) {
      if (forceShowToast) {
        forceShowToast({
          title: "⚠️ Error replying to comment",
          body: "Sorry! We had an error posting your reply. Please try again.",
          autoHide: true,
        });
      }
      console.error("e is: ", e);
    }
  };

  const resolveCommentThread = async (options: { suggestionAccepted?: boolean } = {}) => {
    try {
      const { url, body } = API.comments.post.resolveThread;
      const { data: resolvedCommentThread } = await http.post(
        url(thread_id),
        body({
          is_resolved: !is_resolved,
          doc_name: doc_name,
          suggestion_accepted: options.suggestionAccepted || undefined,
          from: "web_app",
        })
      );
      if (onResolve) {
        onResolve(resolvedCommentThread);
      }
      setRepliesOpen(false);
      handleHistoryUpdate(false, true);
      updateCommentThread(resolvedCommentThread);
      handleDocUpdateParent();
      fetchCompInfo(false);
      markCommentNotifsRead();
    } catch (e) {
      if (forceShowToast) {
        forceShowToast({
          title: "⚠️ Error resolving comment",
          body: "Sorry! We had an issue resolving your comment. Please try again.",
          autoHide: true,
        });
      }
      console.log("error is: ", e);
    }
  };

  // If the user has any notifications associated with this comment thread, mark them as read
  const markCommentNotifsRead = async () => {
    try {
      const { url } = API.user.put.notifMarkCommentAsRead;
      await http.put(url(thread_id));

      fetchNotifications();
    } catch (e) {
      console.error("Error marking notifications as read:", e);
    }
  };

  return (
    <div
      onClick={() => {
        if (isInlineComment && !isActiveComment && !is_resolved) {
          if (!comp_id) {
            return;
          }

          handleInlineReplyClick(thread_id);
          handleCommentChangeClick(comp_id, thread_id, false);
          handleScrollTo(comp_id);
        }
      }}
    >
      <div className={className || style.commentAndInput} data-testid="comment-thread">
        {suggestion && is_resolved && <SuggestionResolution suggestion={suggestion} />}
        <div
          className={classnames({
            [style.thread]: true,
            [style.isResolved]: is_resolved,
          })}
        >
          <FirstComment
            {...props}
            comment={formattedComments[0]}
            suggestionButtonsDisabled={suggestionButtonsDisabled}
            resolveCommentThread={resolveCommentThread}
          />
          {formattedComments.length > 1 && (
            <div className={style.replies}>
              <div onClick={() => toggleRepliesOpen()} className={style.repliesToggle}>
                <span
                  data-testid="view-replies-button"
                  className={classnames({
                    [style.numReplies]: true,
                    [style.repliesOpen]: repliesOpen,
                    [style.repliesClosed]: !repliesOpen,
                  })}
                >
                  <ModeCommentIcon className={style.icon} />
                  {repliesOpen ? "Hide " : "View "}
                  {formattedComments.length - 1}
                  {formattedComments.length > 2 ? " Replies" : " Reply"}
                </span>
                {repliesOpen ? (
                  <KeyboardArrowUpIcon className={style.icon} />
                ) : (
                  <KeyboardArrowDownIcon className={style.icon} />
                )}
              </div>
              {repliesOpen &&
                formattedComments.map((comment, i) => {
                  if (i !== 0) {
                    return <Reply key={i} comment={comment}></Reply>;
                  }
                })}
            </div>
          )}
        </div>
        {((isInlineComment && isActiveComment && !is_resolved) || //is an active comment in Project Activity
          (!isInlineComment && !is_resolved)) &&
          !isDisabled && ( //is any unresolved comment in Text Activity
            <div className={style.replyInputWrapper}>
              <CommentEditor
                key={thread_id}
                mentions={workspaceUsers}
                saveContentCallback={postReply}
                placeholderText={"Leave a reply..."}
                shouldAutofocus={should_autofocus}
                className={classNameCommentEditor}
              />
            </div>
          )}
        {isInlineComment && (
          <div className={style.commentThreadButtons}>
            {!isActiveComment && !is_resolved && !isDisabled && (
              <div className={style.replyLink} data-testid="reply-button">
                <ReplyIcon className={style.icon} />
                Reply
              </div>
            )}
            <div
              className={style.commentLink}
              onClick={(e) => {
                if (!comp_id) {
                  return;
                }

                window?.analytics?.track(`Go to ${!isInComponentLibrary ? "Text" : "Component"} Clicked`);
                handleCommentChangeClick(comp_id, thread_id);
                handleScrollTo(comp_id);
                e.stopPropagation();
              }}
            >
              Go to {!isInComponentLibrary ? "text" : "component"} {"->"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default (props: CommentThreadProps) => {
  const { user } = useAuthenticatedAuth();
  const { fetchNotifications } = useContext(NotificationContext);
  return <CommentThread {...props} user={user} fetchNotifications={fetchNotifications} />;
};
