import useSegment from "@/hooks/useSegment";
import { isSubscribedToBetaLibraryUpdatesAtom, subscribeToBetaLibraryUpdatesActionAtom } from "@/stores/Auth";
import LoadingMessage from "@ds/molecules/LoadingMessage";
import LibraryComingSoon from "@ds/organisms/LibraryComingSoon";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_LIBRARY_INTEREST } from "@shared/segment-event-names";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import style from "./style.module.css";

const defaultStore = getDefaultStore();

function createTrackingEvent(action: string) {
  return {
    event: NS_BETA_LIBRARY_INTEREST,
    properties: {
      action,
      location: "project-nav-panel",
    },
  };
}

function LibraryPanel() {
  const isSubscribed = useAtomValue(isSubscribedToBetaLibraryUpdatesAtom, { store: defaultStore });
  const subscribeToUpdates = useSetAtom(subscribeToBetaLibraryUpdatesActionAtom, { store: defaultStore });
  const { track } = useSegment();

  const handleSubscribe = useCallback(async () => {
    subscribeToUpdates();
    track(createTrackingEvent("subscribe"));
  }, [subscribeToUpdates, track]);

  const handleClickLearnMore = useCallback(() => {
    window.open(LEARN_MORE_URL.LIBRARY, "_blank");
    track(createTrackingEvent("learn-more"));
  }, [track]);

  return (
    <div className={style.libraryPanelContainer}>
      <LibraryComingSoon
        layout="vertical"
        isSubscribed={isSubscribed}
        onClickSubscribe={handleSubscribe}
        onClickLearnMore={handleClickLearnMore}
      />
    </div>
  );
}

// Note: Suspense is not necessary right now, but future proofing
function LibraryPanelWithSuspense() {
  return (
    <Suspense fallback={<LoadingMessage text="Loading..." />}>
      <LibraryPanel />
    </Suspense>
  );
}

export default LibraryPanelWithSuspense;
