import React, { useContext, useEffect, useRef, useState } from "react";
import http, { API } from "../../../../http";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NotesIcon from "@mui/icons-material/Notes";
import TextareaAutosize from "react-textarea-autosize";
import CompactLabel from "../../../../components/CompactLabel";
import OutlineButton from "../../../../components/OutlineButton";
import StatusSelect from "../../../../components/StatusSelect";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ButtonSecondary from "../../../../components/button/buttonsecondary";
import TagInput from "../../../../components/tag-input/tag-input";
import { TOAST_TYPES } from "../../../../defs";
import { UnsavedChangesContext } from "../../../../store/unsavedChangesContext";
import { GROUP_REDUCER_TYPES } from "../../state/groupStateActions";
import { TextItem } from "../../state/types";
import { useProjectContext } from "../../state/useProjectState";
import style from "./CreateTextItem.module.css";

interface CreateTextItemProps {
  projectId: string;
  groupId: string;
  tagSuggestions: string[];
  showToast: (params: { title: string; body: string; type: string; autoHide: boolean }) => void;
  setCreateTextItemPanelStatus: (val: { show: boolean; groupId: string | null }) => void;
  setUpdateDocHistory: (val: boolean) => void;
  setSelectedComp: (comp: TextItem) => void;
}

const CreateTextItem = ({
  projectId,
  groupId,
  tagSuggestions,
  showToast,
  setCreateTextItemPanelStatus,
  setUpdateDocHistory,
  setSelectedComp,
}: CreateTextItemProps) => {
  const {
    groupState: [, groupStateDispatch],
    projectSummary: [, refreshProjectSummary],
  } = useProjectContext();
  const [isSaving, setIsSaving] = useState(false);

  const [status, setStatus] = useState("NONE");
  const [text, setText] = useState("");
  const notes = useRef("");
  const [tags, setTags] = useState<{ id?: string; name: string }[]>([]);

  const [showNotes, setShowNotes] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const {
    canSaveEdits: [canSaveEdits, setCanSaveEdits],
    setModalParams,
  } = useContext(UnsavedChangesContext);

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    notes.current = e.target.value;
  };

  const onDeleteTag = (index: number) => {
    const tagsCopy = tags.slice(0);
    setTags(tagsCopy.splice(index, 1));
  };

  const onAddTag = (tag: { name: string }) => {
    const existingTag = tags.find((existing) => existing.name === tag.name);
    if (!existingTag) {
      setTags([...tags, { name: tag.name }]);
    }
  };

  const createTextItem = async () => {
    const tagNames = tags.map((tag) => tag.name);
    const { url, body } = API.project.post.createTextItem;

    const reqUrl = url(projectId, groupId);
    const reqBody = body({
      text,
      status,
      notes: notes.current,
      tags: tagNames,
    });

    try {
      setIsSaving(true);
      const { data } = await http.post(reqUrl, reqBody);
      refreshProjectSummary();
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.ADD_TEXT_ITEM_TO_GROUP,
        groupId,
        comp: data,
      });
      setUpdateDocHistory(true);
      setCreateTextItemPanelStatus({ show: false, groupId: null });
      setSelectedComp(data);
    } catch (error) {
      setIsSaving(false);
      showToast({
        title: "⚠️ Error saving",
        body: "Sorry! We had an issue saving your new text item.",
        type: TOAST_TYPES.edit_error,
        autoHide: true,
      });
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);

    if (!canSaveEdits && e.target.value !== "") {
      setCanSaveEdits(true);
    } else if (canSaveEdits && e.target.value === "") {
      setCanSaveEdits(false);
    }
  };

  useEffect(() => {
    setModalParams({
      saveCallback: async () => {
        createTextItem();
        setCanSaveEdits(false);
      },
      discardCallback: async () => {
        setCreateTextItemPanelStatus({ show: false, groupId: null });
        setCanSaveEdits(false);
      },
    });
  }, [setModalParams, createTextItem, setCanSaveEdits, setCreateTextItemPanelStatus]);

  return (
    <div className={style.container}>
      <div className={style.header}>Add Text Item</div>
      <div className={style.form}>
        {canSaveEdits && <div className={style.warning}>You have unsaved changes!</div>}
        <StatusSelect status={status} handleStatusChange={handleStatusChange} />
        <CompactLabel className={style.textLabel} text="Text" Icon={NotesIcon} />
        <TextareaAutosize placeholder="No text." onChange={handleTextChange} maxRows={8} />
        <div className={style.textAreaButtons}>
          {!showNotes && <OutlineButton Icon={InfoOutlinedIcon} text="Notes" onClick={() => setShowNotes(true)} />}
          {!showTags && <OutlineButton Icon={LocalOfferIcon} text="Tags" onClick={() => setShowTags(true)} />}
        </div>
        {showNotes && (
          <div className={style.notesArea}>
            <CompactLabel Icon={InfoOutlinedIcon} text="Notes" />
            <div className={style.textBoxWrapper}>
              <TextareaAutosize
                data-testid="notes-input"
                placeholder="No notes written."
                onChange={handleNotesChange}
                maxRows={8}
              />
            </div>
          </div>
        )}
        {showTags && (
          <div className={style.tagsArea}>
            <CompactLabel Icon={LocalOfferIcon} text="Tags" />{" "}
            <TagInput
              tags={tags}
              disabled={false}
              inputAttributes={{ disabled: false }}
              onDeleteTag={onDeleteTag}
              onAddTag={onAddTag}
              tagSuggestions={tagSuggestions}
              placeholder="Add new tag"
            />
          </div>
        )}
        <div className={style.buttons}>
          <ButtonSecondary
            text="Cancel"
            onClick={() => setCreateTextItemPanelStatus({ show: false, groupId: null })}
            disabled={false}
          />
          <ButtonPrimary
            text={isSaving ? "Saving..." : "Create"}
            onClick={() => createTextItem()}
            disabled={!canSaveEdits || isSaving}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTextItem;
