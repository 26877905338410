import { useFloating } from "@floating-ui/react-dom";
import EditOutlined from "@mui/icons-material/EditOutlined";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import ReactTimeago from "react-timeago";
import { getNoSecondsFormatter } from "../../../../shared/utils/timeAgoFormatters";
import EditedText from "../../atoms/EditedText";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  changeTime: string;
  textBefore: string;
  textAfter: string;
  pinned: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  scrollContainerId: string;
  previewsContainerId: string;
}

export function ChangeIndicator(props: IProps) {
  const { pinned, onClick } = props;
  const { refs, floatingStyles, update } = useFloating({
    strategy: "fixed",
    placement: "top-start",
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const container = containerRef.current;
  const diffContentRef = useRef<HTMLDivElement>(null);
  const diffContent = diffContentRef.current;

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    onClick?.(e);
  }

  useEffect(
    function calculateDiffWrapperDimensions() {
      if (diffContent) {
        const { width, height } = diffContent.getBoundingClientRect();
        container?.style.setProperty("--diff-content-width", `${width}px`);
        container?.style.setProperty("--diff-content-height", `${height}px`);
      }
    },
    [diffContent, container]
  );

  // The useFloating hook doesn't automatically update the position of the floating element -- we need to manually
  // recalculate the position whenever the anchoring element has shifted on the page:
  // - when the container element changes size in some way (page resizes, different number of elements in list, etc.)
  // - when the container is scrolled
  // Because we're using Radix's ScrollArea component elsewhere, those are actually two separate "container" elements, so
  // we take two separate IDs as props to watch for changes on both.
  useEffect(
    function updateFloatingPosition() {
      const scrollContainer = document.getElementById(props.scrollContainerId);
      const previewsContainer = document.getElementById(props.previewsContainerId);

      const resizeObserver = new ResizeObserver(() => {
        update();
      });

      if (previewsContainer) {
        resizeObserver.observe(previewsContainer);
        scrollContainer?.addEventListener("scroll", update);
      }

      return () => {
        resizeObserver.disconnect();
        scrollContainer?.removeEventListener("scroll", update);
      };
    },
    [update, props.scrollContainerId, props.previewsContainerId]
  );

  return (
    <>
      <div ref={refs.setReference} className={style.anchor} />
      <div className={style.floatingContainer} ref={refs.setFloating} style={floatingStyles}>
        <div
          className={classNames(style.changeIndicator, { [style.pinnedOpen]: pinned }, props.className)}
          ref={containerRef}
          onClick={handleClick}
        >
          <div className={style.iconWrapper}>
            <Icon Icon={<EditOutlined />} color="invert" size="xxs" />
          </div>

          <div className={style.diffWrapper}>
            <div className={style.diffContent} ref={diffContentRef}>
              <Text inline className={style.diffHeader} size="micro" color="secondary" weight="light">
                Changed{" "}
                <ReactTimeago
                  date={props.changeTime}
                  minPeriod={30}
                  formatter={getNoSecondsFormatter("less than a minute ago")}
                />
              </Text>
              <EditedText
                className={style.diff}
                size="micro"
                textBefore={props.textBefore}
                textAfter={props.textAfter}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeIndicator;
