import {
  ZComponentFolderPermission,
  ZComponentFolderPermissionActionEnum,
  ZGeneralPermissionsActionEnum,
  ZPermissionGroup,
  ZProjectFolderPermission,
  ZProjectFolderPermissionActionEnum,
  ZVariableFolderPermission,
  ZVariableFolderPermissionActionEnum,
  ZVariantFolderPermission,
  ZVariantFolderPermissionActionEnum,
} from "@shared/types/PermissionGroups";
import { z } from "zod";
import { BackendSchema, FrontendSchema, ZObjectId, arrayOfAll } from "./lib";

const ZOnboardingStateEnum = z.union([z.literal("user"), z.literal("workspace"), z.literal("finished")]);
type IOnboardingStateEnum = z.infer<typeof ZOnboardingStateEnum>;

const arrayOfAllOnboardingStates = arrayOfAll<IOnboardingStateEnum>();

export const OnboardingStateEnum = arrayOfAllOnboardingStates(["user", "workspace", "finished"]);

export const ZRole = z.enum(["admin", "editor", "commenter"]);
export type IRole = z.infer<typeof ZRole>;

export type IBRole = BackendSchema<IRole>;
export type IFRole = FrontendSchema<IRole>;

const arrayOfAllRoles = arrayOfAll<IRole>();

export const RoleEnum = arrayOfAllRoles(["admin", "editor", "commenter"]);

/**
 * User flags are used to store "localStorage-like" flags, but across the plugin
 * and webapp.
 *
 * The array of strings is intended to be used as a list of documents -- i.e.,
 * if we dismiss the projectsWithCompSuggestionNudgeDisabled for a project, we add the project
 * ID to the array.
 */
export const ZUserFlags = z.object({
  projectsWithCompSuggestionNudgeDisabled: z.array(z.string()),
  hasSeenSampleProjectTour: z.boolean(),
  hasSeenPluginTour: z.boolean(),
});

export type IUserFlags = z.infer<typeof ZUserFlags>;

// this type represents an object containing just one flag
// e.g. { hasSeenPluginTour: true }
export type UserFlag<K extends keyof IUserFlags> = {
  [key in K]: IUserFlags[key];
};

export type FlagName = keyof IUserFlags;

export const UserJobs = ["NONE", "WRITER", "DESIGN", "ENGINEER", "PRODUCT", "MARKETING", "LEGAL", "OTHER"] as const;
export type IUserJob = (typeof UserJobs)[number];
export const ZUserJobs = z.enum(UserJobs);

export const ZUserMetrics = z.object({
  firstCommentedAt: z.date().nullable(),
  firstEditedAt: z.date().nullable(),
  firstComponentAction: z.date().nullable(),
  firstStatusChange: z.date().nullable(),
  firstAssignment: z.date().nullable(),
  firstWeekLogins: z.number(),
  firstMonthLogins: z.number(),
});

export const defaultUserMetrics = {
  firstCommentedAt: null,
  firstEditedAt: null,
  firstComponentAction: null,
  firstStatusChange: null,
  firstAssignment: null,
  firstWeekLogins: 0,
  firstMonthLogins: 0,
};

export type IUserMetrics = z.infer<typeof ZUserMetrics>;

export const ZUser = z.object({
  _id: ZObjectId,
  picture: z.string().nullable().optional(),
  userId: z.string(),
  secondaryUserIds: z.array(z.string()),
  email: z.string(),
  name: z.string(),
  figmaAuth: z.object({
    token: z.string(),
    refreshToken: z.string(),
    expiresAt: z.number(),
  }),
  workspaceId: ZObjectId,
  onboardingState: ZOnboardingStateEnum,
  finishedDittoOverview: z.boolean(),
  permissionGroups: z.array(z.string()),
  role: ZRole,
  isFigmaEditor: z.boolean(),
  subscriptionSettings: z.object({
    productUpdates: z.boolean(),
    appNotifs: z.boolean(),
  }),
  latestLegalAgreement: z.nullable(z.date()),
  job: ZUserJobs,
  createdAt: z.date(),
  flags: ZUserFlags,
  metrics: ZUserMetrics,
  projectsSeen: z.record(z.string(), z.date()),
  projectFoldersSeen: z.record(z.string(), z.date()),
  isSubscribedToBetaLibraryUpdates: z.boolean().optional(),
});

export type IUser = z.infer<typeof ZUser>;
export type IBUser = BackendSchema<IUser>;
export type IFUser = FrontendSchema<IUser>;

export const ZUserPermissionGroups = z.object({
  groups: z.array(
    z.object({
      _id: z.string(),
      name: z.string(),
    })
  ),
  resources: z.record(
    z.object({
      resource_id: z.string(),
      resource_type: z.union([
        ZProjectFolderPermission.shape.resource_type,
        ZComponentFolderPermission.shape.resource_type,
        ZVariantFolderPermission.shape.resource_type,
        ZVariableFolderPermission.shape.resource_type,
      ]),
      access: z.array(
        z.object({
          action: z.union([
            ZProjectFolderPermissionActionEnum,
            ZComponentFolderPermissionActionEnum,
            ZVariantFolderPermissionActionEnum,
            ZVariableFolderPermissionActionEnum,
          ]),
          permissionGroups: z.array(z.string()),
        })
      ),
    })
  ),
  general: z.array(
    z.object({
      action: ZGeneralPermissionsActionEnum,
      permissionGroups: z.array(z.string()),
    })
  ),
});

const ZPermission = z.object({
  _id: ZObjectId,
  entity_id: ZObjectId,
  entity_type: z.string(),
  user_id: ZObjectId,
  data: z.object({}),
  workspace_id: ZObjectId,
});

export type IPermission = z.infer<typeof ZPermission>;

// TODO: Remove this after permission groups rolls out
export type IPermissionSchema = IPermission;

export const ZUserWithPermissions = ZUser.extend({
  permissionGroups: ZUserPermissionGroups,
  permissions: z.array(ZPermission),
});

type IUserWithPermissions = z.infer<typeof ZUserWithPermissions>;
export type IBUserWithPermissions = Omit<BackendSchema<IUserWithPermissions>, "permissions"> & {
  permissions: IPermissionSchema[];
};
export type IFUserWithPermissions = Omit<FrontendSchema<IUserWithPermissions>, "figmaAuth"> & {
  isFigmaAuthenticated: boolean;
};

export const ADMIN_DEFAULT_PERMISSION_GROUP_NAME = "admin";
export const EDITOR_DEFAULT_PERMISSION_GROUP_NAME = "editor";
export const COMMENTER_DEFAULT_PERMISSION_GROUP_NAME = "commenter";

// Permission Groups
export type IUserPermissionGroups = z.infer<typeof ZUserPermissionGroups>;
export type IBUserPermissionGroups = BackendSchema<IUserPermissionGroups>;
export type IFUserPermissionGroups = FrontendSchema<IUserPermissionGroups>;

const ZPermissionGroupWithUsers = ZPermissionGroup.extend({
  users: z.array(ZUser),
  invitedUserEmails: z.array(z.string()),
});

type IPermissionGroupWithUsers = z.infer<typeof ZPermissionGroupWithUsers>;

export type IBPermissionGroupWithUsers = BackendSchema<IPermissionGroupWithUsers>;
export type IFPermissionGroupWithUsers = FrontendSchema<IPermissionGroupWithUsers>;
