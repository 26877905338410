import { useWorkspace } from "@/store/workspaceContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MergeIcon from "@mui/icons-material/Merge";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { default as classNames, default as classnames } from "classnames";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { Component, MultiSelection } from "../../views/Components/useSelectionState";
import { CharLimitInputType } from "../CharacterLimitRow/CharacterLimitRow";
import CharacterLimitRow from "../CharacterLimitRow/index";
import CompactLabel from "../CompactLabel";
import StatusSelect from "../StatusSelect";
import UserSelect from "../UserSelect";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import TagInput from "../tag-input/tag-input";
import style from "./style.module.css";

interface Props {
  actions: {
    detachAndDeleteAll: (componentIds: string[]) => void;
    moveToFolder: (componentIds: string[]) => void;
    mergeComponents: (componentIds: string[]) => void;
  };
  selectionState: MultiSelection;
  tagSuggestions: { name: string }[];
  handleComponentUpdates: (
    componentIds: string[],
    data: {
      assigneeId: string | null | undefined;
      status: string | undefined;
      tagsAdded: string[];
      tagsDeleted: string[];
      characterLimit: number | undefined;
    }
  ) => void;
}

const EditMultiWsComp = (props: Props) => {
  const segment = useSegment();

  const {
    selectionState,
    tagSuggestions,
    handleComponentUpdates,
    actions: { detachAndDeleteAll, moveToFolder, mergeComponents },
  } = props;

  const {
    setModalParams,
    checkDetailPanelChanges,
    canSaveEdits: [, setCanSaveEdits],
  } = useContext(UnsavedChangesContext);

  const { workspaceInfo, users } = useWorkspace();
  const formattedUserOptions = useMemo(() => {
    if (!users) return [];
    return users.map((user) => ({
      id: user._id,
      name: user.name,
    }));
  }, [users]);

  const { selectedIds: multiSelectedIds, selectedComponents: multiSelectedComps } = selectionState;

  const initialState = useRef(computeState(multiSelectedComps));
  const [state, setState] = useState(initialState.current);

  useEffect(() => {
    initialState.current = computeState(multiSelectedComps);
    setState(initialState.current);
  }, [multiSelectedComps]);

  const hasUnsavedChanges = useMemo(() => {
    const assigneeChanged = state.assigneeId !== initialState.current.assigneeId;
    const statusChanged = state.status !== initialState.current.status;

    const tagSetInitial = createTagSet(initialState.current.tags);
    const tagSetCurrent = createTagSet(state.tags);

    const tagsChanged =
      initialState.current.tags.some((t) => !tagSetCurrent.has(t)) || state.tags.some((t) => !tagSetInitial.has(t));

    const charLimitChanged = state.charLimit !== initialState.current.charLimit;

    return assigneeChanged || statusChanged || tagsChanged || charLimitChanged;
  }, [state]);

  useEffect(() => {
    setCanSaveEdits(hasUnsavedChanges);

    return () => setCanSaveEdits(false);
  }, [hasUnsavedChanges]);

  const isEditEnabled = userHasResourcePermission("component_folder:edit");

  const [isSaving, setIsSaving] = useState(false);

  const handleStatusChange = (value: string) => {
    const status = value as typeof state.status;
    setState((s) => ({ ...s, status }));
  };

  const onDeleteTag = (removedTagIndex: number) => {
    let tags = [...state.tags];
    tags.splice(removedTagIndex, 1);

    setState((s) => ({
      ...s,
      tags,
    }));
  };

  const onAddTag = (tag) => {
    // case insensitive search because we don't want duplicates with different casing
    const match = state.tags.find((existing) => existing.toUpperCase() === tag.name.toUpperCase());
    if (!match) {
      setState((s) => ({ ...s, tags: [...s.tags, tag.name] }));
    }
  };

  const setCharLimitInput = (charLimit) => {
    setState((s) => ({ ...s, charLimit }));
  };

  const resetChanges = () => {
    setState(initialState.current);
  };

  const saveEdits = async () => {
    segment.track({
      event: SegmentEvents.COMPONENT_MULTI_EDIT_SAVED,
      properties: {
        num: multiSelectedIds.length,
      },
    });

    setIsSaving(true);

    const createTagSet = (tags: string[]) => tags.reduce((s, t) => s.add(t), new Set<string>());

    const tagSetInitial = createTagSet(initialState.current.tags);
    const tagSetCurrent = createTagSet(state.tags);

    const tagsAdded = state.tags.filter((t) => !tagSetInitial.has(t));
    const tagsDeleted = initialState.current.tags.filter((t) => !tagSetCurrent.has(t));

    const status =
      state.status && state.status !== "MIXED" && state.status !== initialState.current.status
        ? state.status
        : undefined;

    // don't send null for assigneeId if it's not actually changed
    const newAssigneeId =
      state.assigneeId === "MIXED" || state.assigneeId === initialState.current.assigneeId
        ? undefined
        : state.assigneeId;

    const characterLimit =
      state.charLimit && state.charLimit !== "MIXED" && state.charLimit !== initialState.current.charLimit
        ? state.charLimit
        : undefined;

    const { url, body } = API.ws_comp.put.updateMultiple;
    await http.put(
      url,
      body({
        assignee: newAssigneeId,
        ids: multiSelectedIds,
        status,
        tags_added: tagsAdded,
        tags_deleted: tagsDeleted,
        docId: undefined,
        characterLimit,
      })
    );

    handleComponentUpdates(multiSelectedIds, {
      assigneeId: newAssigneeId,
      status,
      tagsAdded,
      tagsDeleted,
      characterLimit,
    });

    setIsSaving(false);
  };

  const onSaveEditsClick = () => {
    saveEdits();
  };

  useEffect(() => {
    setModalParams({
      saveCallback: saveEdits,
      discardCallback: resetChanges,
    });
  }, [setModalParams, saveEdits, resetChanges]);

  const canMoveFolders = (workspaceInfo.plan === "enterprise" || workspaceInfo.plan === "growth") && isEditEnabled;

  const sampleComponentsSelected = useMemo(() => {
    return selectionState.selectedComponents.some((c) => c.isSample);
  }, [selectionState.selectedComponents]);

  return (
    <div className={classnames([style.container, style.sidebar])} data-testid="multi-ws-edit-side-panel">
      <div className={style.numberOfComponentsSelected}>Edit {multiSelectedIds.length} Selected</div>
      <div className={classnames(style.editComp, "edit-component")}>
        <div className={style.form}>
          {hasUnsavedChanges && <div className={style.warning}>You have unsaved changes!</div>}
          <StatusSelect status={state.status} handleStatusChange={handleStatusChange} />
          <div className={style.assignArea}>
            <CompactLabel Icon={AccountCircleIcon} text="Assign" />
            <UserSelect
              placeholder="No teammate assigned"
              setSelectedUserId={(userId) => setState((s) => ({ ...s, assigneeId: userId }))}
              selectedUserId={state.assigneeId}
              users={formattedUserOptions}
              disabled={!isEditEnabled}
            />
          </div>
          {(isEditEnabled || state.tags.length > 0) && (
            <div className={style.tagsArea}>
              <CompactLabel text="Tags" Icon={LocalOfferIcon} />
              <TagInput
                tags={state.tags.map((name) => ({ name }))}
                disabled={!isEditEnabled}
                onDeleteTag={onDeleteTag}
                onAddTag={onAddTag}
                inputAttributes={{ disabled: !isEditEnabled }}
                tagSuggestions={tagSuggestions}
              />
            </div>
          )}

          <CharacterLimitRow charLimitInput={state.charLimit} setCharLimitInput={setCharLimitInput} />

          <br />
          <div className={style.buttons}>
            <ButtonSecondary text="Cancel" onClick={() => resetChanges()} disabled={!hasUnsavedChanges} />
            <ButtonPrimary
              text={isSaving ? "Saving..." : "Save Edits"}
              onClick={onSaveEditsClick}
              disabled={!hasUnsavedChanges || isSaving}
            />
          </div>
        </div>
        <div className={style.actions}>
          {isEditEnabled && (
            <div
              data-tip
              data-for="detach-all-tooltip"
              className={classNames({
                [style.disabled]: sampleComponentsSelected,
              })}
              onClick={() => {
                if (sampleComponentsSelected) return;
                detachAndDeleteAll(selectionState.selectedIds);
              }}
            >
              <DeleteIcon className={style.icon} />
              Detach all instances and delete components
            </div>
          )}
          {canMoveFolders && (
            <div
              data-tip
              data-for="move-tooltip"
              className={classNames({
                [style.disabled]: sampleComponentsSelected,
              })}
              onClick={() => {
                if (sampleComponentsSelected) return;
                moveToFolder(selectionState.selectedIds);
              }}
            >
              <FolderIcon className={style.icon} />
              Move to folder
            </div>
          )}
          {isEditEnabled && (
            <div
              data-tip
              data-for="merge-components-tooltip"
              className={classNames({
                [style.disabled]: sampleComponentsSelected,
              })}
              onClick={() => {
                if (sampleComponentsSelected) return;
                mergeComponents(selectionState.selectedIds);
              }}
            >
              <MergeIcon className={style.icon} />
              Merge Components
            </div>
          )}
        </div>
        {sampleComponentsSelected && (
          <ReactTooltip id="detach-all-tooltip" place="bottom" effect="solid">
            Deleting is disabled for sample data.
          </ReactTooltip>
        )}
        {sampleComponentsSelected && (
          <ReactTooltip id="move-tooltip" place="bottom" effect="solid">
            Sample data cannot be moved to other folders.
          </ReactTooltip>
        )}
        {sampleComponentsSelected && (
          <ReactTooltip id="merge-components-tooltip" place="bottom" effect="solid">
            Sample components cannot be merged.
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};

export default EditMultiWsComp;

function getCommonTags(allTags: string[], totalComponents: number) {
  if (allTags.length === 0) {
    return [];
  }

  const counts: { [tag: string]: number } = {};
  for (let i = 0; i < allTags.length; i++) {
    const tag = allTags[i];
    counts[tag] = counts[tag] ? counts[tag] + 1 : 1;
  }

  const tags: string[] = [];
  for (const tag in counts) {
    if (counts[tag] === totalComponents) {
      tags.push(tag);
    }
  }

  return tags;
}

function createTagSet(tags: string[]) {
  return tags.reduce((s, t) => s.add(t), new Set<string>());
}

function computeState(components: Component[]) {
  const assignees = components.map(({ instances: [{ assignee }] }) => (assignee ? assignee.toString() : null));
  const assigneeId = assignees.every((a) => a === assignees[0]) ? assignees[0] || null : "MIXED";

  const statuses = components.map(({ instances: [{ status }] }) => status);
  const status = statuses.every((s) => s === statuses[0]) ? statuses[0] || "NONE" : ("MIXED" as const);

  const allTags = components.map(({ instances: [{ tags }] }) => tags).flat();
  const tags = getCommonTags(allTags, components.length);

  const charLimits = components.map(({ instances: [{ characterLimit }] }) => characterLimit).flat();
  const charLimit: CharLimitInputType = charLimits.every((c) => c === charLimits[0]) ? charLimits[0] || "" : "MIXED";

  return {
    assigneeId,
    status,
    tags,
    tagsAdded: [] as string[],
    tagsRemoved: [] as string[],
    charLimit,
  };
}
