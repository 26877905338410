import logger from "./logger";

/**
 * Wait t milliseconds before doing the next thing.
 * ex: await wait(1000); to wait 1 second
 */
export function wait(t: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, t);
  });
}

/**
 * Automatically retry a request until a validation function returns true or maxRetries is reached.
 * @param request - The request to make
 * @param requestName - The name of the request for logging
 * @param fallbackResponse - The response to return if maxRetries is reached without a valid result
 * @param validationFn - Optionally, a function to validate the response and trigger retries if validation fails (otherwise just retries on errors)
 * @param initialDelay - Optionally, an initial delay in milliseconds before making the first request - default is none
 * @param delay - The delay between retries in milliseconds - default is 1000 (1 second)
 * @param maxRetries - The maximum number of retries - default is 3
 * @param currentRetry - The current number of retries - used for recursion, don't pass in manually
 */
export default async function fetchWithRetry<T>({
  request,
  requestName,
  fallbackResponse,
  validationFn = () => true,
  initialDelay = 0,
  delay = 1000,
  maxRetries = 3,
  currentRetry = 0,
}: {
  request: () => Promise<T>;
  requestName: string;
  fallbackResponse: T;
  validationFn?: (response: T) => boolean;
  initialDelay?: number;
  delay?: number;
  maxRetries?: number;
  currentRetry?: number;
}): Promise<{ success: boolean; response: T; error?: Error | undefined }> {
  let response: T | undefined = undefined;
  let error: Error | undefined = undefined;

  if (initialDelay && currentRetry === 0) {
    await wait(initialDelay);
  }

  try {
    response = await request();
    if (validationFn(response)) {
      logger.info(`${requestName}: success in ${currentRetry} retries`);
      return { success: true, response };
    }
  } catch (e) {
    error = e as Error;
  }

  if (currentRetry < maxRetries) {
    await wait(delay);
    currentRetry++;
    return fetchWithRetry({ request, requestName, validationFn, fallbackResponse, delay, maxRetries, currentRetry });
  } else {
    return { success: false, response: response || fallbackResponse, error };
  }
}
