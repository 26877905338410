import classNames from "classnames";
import React from "react";
import { LEARN_MORE_URL } from "../../../../shared/frontend/learnMoreLinks";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  onClickLearnMore?: () => void;
  dividerAbove?: boolean;
  dividerBelow?: boolean;
}

export function DeveloperIdSection(props: IProps) {
  function handleClickLearnMore() {
    window.open(LEARN_MORE_URL.DEVELOPER_ID, "_blank");
    props.onClickLearnMore?.();
  }

  return (
    <div
      className={classNames(style.container, props.className, {
        [style.borderTop]: props.dividerAbove,
        [style.borderBottom]: props.dividerBelow,
      })}
      data-testid="developer-id-section"
    >
      <Text size="small" color="primary" weight="strong">
        Dev ID
      </Text>
      <Text size="micro" color="secondary" inline>
        Developer integrations with your codebase in the new Ditto beta are coming soon.{" "}
        <Text size="micro" color="action" asLink inline onClick={handleClickLearnMore}>
          Learn more
        </Text>
      </Text>
    </div>
  );
}

export default DeveloperIdSection;
