import { detailsPanelEditStateAtom, selectedBlockIdAtom, selectionTypeAtom } from "@/stores/ProjectSelection";
import BlockDetails from "@/views/NS/ProjectNS/components/DetailsPanel/BlockDetails";
import TextItemDetails from "@/views/NS/ProjectNS/components/DetailsPanel/TextItemDetails";
import VariantsPanel from "@/views/NS/ProjectNS/components/DetailsPanel/VariantsPanel";
import Scrollbar from "@ds/molecules/Scrollbar";
import { useAtomValue } from "jotai";
import EditTabBar from "../EditTabBar";
import TextItemActivity from "../TextItemActivity";
import TextItemComments from "../TextItemComments";
import TitleRow from "../TitleRow";
import style from "./style.module.css";

function ProjectDetailsPanel() {
  const selectionType = useAtomValue(selectionTypeAtom);
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const selectedTab = useAtomValue(detailsPanelEditStateAtom);

  return (
    <>
      <TitleRow />
      <EditTabBar />
      <Scrollbar className={style.scrollArea} disableScrollX>
        {selectedTab === "EDIT" && (
          <>
            {selectionType === "text" && <TextItemDetails />}
            {selectionType === "block" && selectedBlockId && <BlockDetails selectedBlockId={selectedBlockId} />}
          </>
        )}
        {selectedTab === "ACTIVITY" && <TextItemActivity />}
        {selectedTab === "COMMENTS" && <TextItemComments />}
        {selectedTab === "VARIANTS" && <VariantsPanel />}
      </Scrollbar>
    </>
  );
}

export default ProjectDetailsPanel;
