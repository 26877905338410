import { IChangeItem } from "@shared/types/ActualChange";
import { ICommentThread } from "@shared/types/CommentThread";
import { IBComponent } from "@shared/types/Component";
import { ITextItem } from "@shared/types/TextItem";
import { IUpdateTextItemsBody } from "@shared/types/http/Component";
import { createHttpRequest } from "./lib/createHttpRequest";

const ROOT = "/comp";
const MULTI = `${ROOT}/multi`;
const INFO = `${ROOT}/info`;

export default {
  get: {
    all: {
      url: `${ROOT}/all`,
    },
    allTags: {
      url: ({ excludeWsComps, sortAlpha, excludeSampleData }) =>
        `${ROOT}/alltags?excludeWsComps=${excludeWsComps}&sortAlpha=${sortAlpha}&excludeSampleData=${excludeSampleData}`,
    },
    historyAndComments: {
      url: (comp_id) => `${ROOT}/historyAndComments/${comp_id}`,
    },
    info: {
      url: (id) => `${INFO}/${id}`,
    },
  },
  post: {
    editApiId: {
      url: (comp_id) => `${ROOT}/editApiId/${comp_id}`,
      body: ({ newApiId, doc_ID, from }) => ({
        newApiId,
        doc_ID,
        from,
      }),
    },
    multiBlock: {
      url: `${ROOT}/multiblock`,
      body: ({ doc_id, groupId, comp_ids, fromFigma }) => ({
        doc_id,
        groupId,
        comp_ids,
        fromFigma,
      }),
    },
    multiHide: {
      url: `${ROOT}/multihide`,
      body: ({ comp_ids, from, doc_ID, is_hidden }) => ({
        comp_ids,
        from,
        doc_ID,
        is_hidden,
      }),
    },
    multiStatus: {
      url: `${ROOT}/multistatus`,
      body: ({ comp_ids, status, doc_name, doc_id, figma_node_ids }) => ({
        comp_ids,
        status,
        doc_name,
        doc_id,
        figma_node_ids,
      }),
    },
    multiTags: {
      url: `${ROOT}/multitags`,
      body: ({ comp_ids, added_tags, deleted_tags, doc_id, doc_name }) => ({
        comp_ids,
        added_tags,
        deleted_tags,
        doc_id,
        doc_name,
      }),
    },
    infoByNode: {
      url: `${MULTI}/infoByNode`,
      body: ({ docId, nodes }) => ({
        docId,
        nodes,
      }),
    },
    infoByBatch: {
      url: `${INFO}/batch`,
      body: ({ ids }) => ({ ids }),
    },
    updateTagsByNode: {
      url: `${MULTI}/updateTagsByNode`,
      body: ({ docId, nodeIds, addedTags, deletedTags }) => ({
        docId,
        nodeIds,
        addedTags,
        deletedTags,
      }),
    },
    updateStatusById: {
      url: (id) => `${ROOT}/updateStatus/${id}`,
      body: ({ editAll, newCompInfo }) => ({ editAll, newCompInfo }),
    },
    updateStatusByNode: {
      url: `${MULTI}/updateStatusByNode`,
      body: ({ docId, nodeIds, compIds, status, docName }) => ({
        docId,
        nodeIds,
        compIds,
        status,
        docName,
      }),
    },
    multiText: {
      url: `${ROOT}/multitext`,
      body: ({
        comp_ids,
        text_after,
        doc_name,
        doc_id,
        figma_node_ids,
        text_before,
        rich_text,
        variables_before,
        variables_after,
      }) => ({
        comp_ids,
        text_after,
        doc_name,
        doc_id,
        figma_node_ids,
        text_before,
        rich_text,
        variables_before,
        variables_after,
      }),
    },
    infoBatch: {
      url: `${INFO}/batch`,
      body: ({ ids }) => ({
        ids,
      }),
    },
    page: {
      url: ({ applySearchFilter = false } = { applySearchFilter: false }) =>
        `${ROOT}/page` + (applySearchFilter ? `?applySearchFilter=true` : ""),
      body: ({ page_size, index }) => ({
        page_size,
        index,
      }),
    },
    search: {
      url: `${ROOT}/search`,
      body: ({ query, assignee, tags, status, devID, index, pageSize }) => ({
        query,
        assignee,
        tags,
        status,
        devID,
        index,
        pageSize,
      }),
    },
    setIsHidden: {
      url: (id) => `${ROOT}/set_is_hidden/${id}`,
      body: ({ is_hidden }) => ({ is_hidden }),
    },
    update: {
      url: (comp_id) => `${ROOT}/update/${comp_id}`,
      body: ({ editAll, newCompInfo, changeInfo, originalText, type }) => ({
        editAll,
        newCompInfo,
        changeInfo,
        originalText,
        type,
      }),
    },
    updateCharacterLimit: {
      url: (comp_id) => `${ROOT}/update-character-limit/${comp_id}`,
      body: ({ characterLimit }) => ({
        characterLimit,
      }),
    },
    updateFromPlugin: {
      url: (doc_id) => `${ROOT}/updateFromPlugin/${doc_id}`,
      body: ({ nodes, newCompInfo, changeInfo }) => ({
        nodes,
        newCompInfo,
        changeInfo,
      }),
    },
    restore: {
      url: (comp_id) => `${ROOT}/restore/${comp_id}`,
      body: ({
        text_before,
        text_after,
        variables_before,
        variables_after,
        rich_text_before,
        rich_text_after,
        doc_id,
        doc_name,
        figma_node_id,
        from,
      }) => ({
        text_before,
        text_after,
        variables_before,
        variables_after,
        rich_text_before,
        rich_text_after,
        doc_id,
        doc_name,
        figma_node_id,
        from,
      }),
    },
    resolveConflict: {
      url: (id) => `${ROOT}/resolveConflict/${id}`,
      body: ({ currText, newText }) => ({
        currText,
        newText,
      }),
    },
  },
  put: {
    assign: {
      url: `${ROOT}/assign`,
      body: ({ userId, projectId, textItemIds }) => ({
        userId,
        projectId,
        textItemIds,
      }),
    },
  },
};

export const getMultiTextItems = createHttpRequest<
  { textItemIds: string[] },
  (Omit<ITextItem, "ws_comp"> & {
    ws_comp: Pick<IBComponent, "_id" | "type" | "name" | "apiID" | "folder_id">;
  })[]
>({
  method: "get",
  url: `${MULTI}/info`,
  getConfig: (args) => ({ params: { textItemIds: args.textItemIds } }),
});

export const update = createHttpRequest<
  {
    data: IUpdateTextItemsBody;
  },
  void
>({
  method: "put",
  url: "/comp",
  getConfig: (data) => ({
    data,
  }),
});

export const getHistoryAndComments = createHttpRequest<{ textItemId: string }, (IChangeItem | ICommentThread)[]>({
  method: "get",
  getUrl: (args) => `/comp/historyAndComments/${args.textItemId}`,
});
