import DragIndicator from "@mui/icons-material/DragIndicator";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";
import { EMPTY_RICH_TEXT } from "../../../../shared/frontend/constants";
import useIsComponentVisible from "../../../../shared/frontend/hooks/useIsComponentVisible";
import { ActualComponentStatus, ITipTapRichText } from "../../../../shared/types/TextItem";
import { IUser } from "../../../../shared/types/User";
import Avatar from "../../atoms/Avatar";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import EditableTextArea from "../../atoms/EditableTextArea";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import Tooltip from "../../molecules/Tooltip";
import VariantBadge, { VariantBadgeData, VariantNotPresentBadge } from "../../molecules/VariantBadge";
import style from "./index.module.css";

export type TextItemComponentState = "default" | "focus" | "disabled" | "dragging" | "loading";

// none: not editable
// editable: allow focusing the input but don't show the save/cancel buttons
// editing: show save/cancel buttons
export type TextItemEditState = "none" | "editable" | "editing";

/**
 * Text item levels:
 * - default: the default level, with all metadata and badges visible
 * - empty-variant: a variant with no text
 * - compact: a compact level, with badges visible but wrapped below the text
 * - minimal: show notes and tags, but not badges
 * - essential: show only status and instance count
 */
export type TextItemLevel = "default" | "empty-variant" | "compact" | "minimal" | "essential";

interface IProps {
  defaultValue?: ITipTapRichText;
  /**
   * The status of the component.
   */
  status?: ActualComponentStatus;
  variants?: VariantBadgeData[];
  assignee?: Pick<IUser, "name" | "picture">;
  numComments?: number;
  instanceCount?: number;
  tags?: string[];
  notes?: string | null;
  placeholder?: string;
  variantNotPresent?: boolean;
  hideVariantsBadge?: boolean;

  canDrag?: boolean;

  state?: TextItemComponentState;
  editState?: TextItemEditState;
  expansion?: "inline" | "block";
  variant?: "default" | "published" | "unmanaged";
  level?: TextItemLevel;
  showTextIcon?: boolean;
  autoFocus?: boolean;
  metadataCanWrap?: boolean;
  highlightedPhrase?: string;

  onClickSave?: (value: ITipTapRichText) => Promise<void> | void;
  onClickCancel?: () => Promise<void> | void;
  onEscape?: () => Promise<void> | void;
  onTextChange?: (richText: ITipTapRichText) => void;
  onTextFocus?: React.FocusEventHandler<HTMLDivElement>;
  onTextBlur?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: () => void;

  className?: string;
  style?: React.CSSProperties;
}

export function TextItem(props: IProps) {
  const { onClick, onClickSave, onClickCancel, onEscape, onTextChange, onTextFocus, onTextBlur } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { isVisibleRef } = useIsComponentVisible(ref, 1);
  const level = props.level ?? "default";

  const handleSave = useCallback(
    (richText: ITipTapRichText) => {
      return onClickSave?.(richText);
    },
    [onClickSave]
  );

  async function handleCancel() {
    await onClickCancel?.();
  }

  async function handleClick() {
    onClick?.();
  }

  useEffect(() => {
    // When the component becomes focused, scroll it into view if it's not already visible.
    if (props.state === "focus" && !isVisibleRef.current && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [props.state, isVisibleRef]);

  const showComments = (props.numComments ?? 0) > 0;
  const showBadges =
    props.editState !== "editing" &&
    props.level !== "minimal" &&
    props.level !== "essential" &&
    (!!props.variants || showComments || !!props.assignee || !!props.variantNotPresent);
  const showNotes = props.notes && props.notes.length > 0 && props.level !== "essential";
  const showTags = props.tags && props.tags.length > 0 && props.level !== "essential";

  const wrapBadges = props.level === "compact";
  const showInstances = props.instanceCount !== undefined;

  return (
    <div
      style={props.style}
      className={classNames(
        style.TextItemWrapper,
        {
          [style.inline]: props.expansion === "inline",
          [style[`state-${props.state}`]]: props.state,
          [style[`edit-state-${props.editState}`]]: props.editState,
          [style[`variant-${props.variant}`]]: props.variant,
          [style[`level-${props.level}`]]: props.level,
        },
        props.className
      )}
      data-testid="text-item"
      ref={ref}
      onClick={handleClick}
    >
      <div className={style.borderWrapper} />
      {props.status && (
        <div
          className={classNames(style.status, {
            [style[`status-${props.status}`]]: props.status,
          })}
        />
      )}
      {props.canDrag && <Icon Icon={<DragIndicator />} size="xs" color="minimal" />}
      {props.showTextIcon && <TextIcon />}

      <div className={style.main}>
        <div className={style.textItemVerticalSplit}>
          <div
            className={classNames({
              [style.textRow]: true,
              [style.textRowCompact]: level === "compact" || level === "minimal" || level === "essential",
            })}
          >
            {/* TODO: Handle highlighting with rich text support. */}
            <EditableInput
              autoFocus={props.autoFocus}
              defaultValue={props.defaultValue ?? EMPTY_RICH_TEXT}
              editState={props.editState || "none"}
              highlightedPhrase={props.highlightedPhrase}
              placeholder={props.placeholder}
              onSave={handleSave}
              onClickCancel={handleCancel}
              onEscape={onEscape}
              onTextChange={onTextChange}
              onFocus={onTextFocus}
              onBlur={onTextBlur}
            />

            {!wrapBadges && showBadges && (
              <Badges
                variantNotPresent={props.variantNotPresent}
                variants={props.variants}
                hideVariantsBadge={props.hideVariantsBadge}
                numComments={props.numComments}
                assignee={props.assignee}
                instanceCount={props.instanceCount}
                level={props.level}
              />
            )}
          </div>

          {showInstances && <InstanceCountBadge instanceCount={props.instanceCount ?? 0} level={props.level} />}
        </div>

        {wrapBadges && showBadges && (
          <Badges
            variantNotPresent={props.variantNotPresent}
            variants={props.variants}
            numComments={props.numComments}
            assignee={props.assignee}
            instanceCount={props.instanceCount}
            level={props.level}
          />
        )}

        {(showNotes || showTags) && (
          <div className={style.notesArea}>
            {showNotes && (
              <Text size="micro" color="secondary">
                {props.notes}
              </Text>
            )}

            {showTags && (
              <div className={style.tags}>
                {props.tags?.map((tag, i) => (
                  <Badge key={tag + i} type="outline" borderRadius="md" size="xs" className={style.tag}>
                    {tag}
                  </Badge>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function Badges(props: {
  variants?: VariantBadgeData[];
  numComments?: number;
  assignee?: Pick<IUser, "name" | "picture">;
  level?: TextItemLevel;
  instanceCount?: number;
  hideVariantsBadge?: boolean;
  variantNotPresent?: boolean;
}) {
  return (
    <div className={style.badges}>
      {!props.hideVariantsBadge && (
        <>{props.variantNotPresent ? <VariantNotPresentBadge /> : <VariantBadge variants={props.variants} />}</>
      )}
      {!!props.numComments && (
        <Tooltip content={`${props.numComments} open comment${props.numComments === 1 ? "" : "s"}`} type="invert">
          <Badge className={style.commentsBadge} type="outline" size="sm" borderRadius="lg">
            <Icon className={style.reflect} Icon={<ModeCommentIcon />} size="micro" color="primary" />
            {props.numComments}
          </Badge>
        </Tooltip>
      )}
      {props.assignee && (
        <Tooltip content={props.assignee.name} type="invert">
          <Avatar userName={props.assignee.name} picture={props.assignee.picture} size="sm" />
        </Tooltip>
      )}
    </div>
  );
}

function InstanceCountBadge(props: { instanceCount: number; level?: TextItemLevel }) {
  return (
    <div className={style.instanceBadges}>
      {props.level !== "compact" && props.level !== "minimal" && <div className={style.badgeDivider} />}
      <Tooltip content={`${props.instanceCount} instances in design`} type="invert">
        <Badge
          className={style.instanceCount}
          type={props.instanceCount ? "action-outline" : "subtle"}
          size="sm"
          borderRadius="md"
        >
          {props.instanceCount}
        </Badge>
      </Tooltip>
    </div>
  );
}

interface EditableInputProps {
  autoFocus?: boolean;
  editState: TextItemEditState;
  defaultValue: ITipTapRichText;
  highlightedPhrase?: string;
  placeholder?: string;
  onSave: (richText: ITipTapRichText) => void;
  onClickCancel: () => Promise<void> | void;
  onEscape?: () => Promise<void> | void; // By default, hitting escape runs onClickCancel. Pass a custom handler here to override
  onTextChange?: (richText: ITipTapRichText) => void;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

function EditableInput(props: EditableInputProps) {
  const { onSave, onClickCancel, onEscape, onTextChange, onFocus, onBlur } = props;
  const [inputValue, setInputValue] = React.useState(props.defaultValue);
  const [isSaving, setIsSaving] = React.useState(false);

  function handleChange(richText: ITipTapRichText) {
    setInputValue(richText);
    onTextChange?.(richText);
  }

  const handleSave = useCallback(
    (passedInInputValue: ITipTapRichText) => {
      setIsSaving(true);
      onSave(passedInInputValue);
      setIsSaving(false);
    },
    [onSave]
  );

  function handleEditorKeydown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.metaKey && e.key === "Enter") {
      handleSave(inputValue);
    }
    if (e.key === "Escape") {
      onEscape ? onEscape() : onClickCancel();
    }
  }

  const isEditable = props.editState === "editable" || props.editState === "editing";
  const isEditing = props.editState === "editing";

  return (
    <div className={style.editableInput}>
      <EditableTextArea
        placeholder={props.placeholder ?? "Edit text"}
        onKeyDown={handleEditorKeydown}
        onTextChange={handleChange}
        onFocus={onFocus}
        onBlur={(e) => {
          if (onBlur) onBlur(e);
        }}
        editable={isEditable}
        editing={isEditing}
        content={props.defaultValue}
        highlightedPhrase={props.highlightedPhrase}
        autoFocus={props.autoFocus}
        onEnter={handleSave}
      />
      {isEditing && (
        <div className={style.inlineEditButtons}>
          <Button disabled={isSaving} size="micro" level="secondary" onClick={onClickCancel}>
            Cancel
          </Button>

          <Button disabled={isSaving} size="micro" level="primary" onClick={() => handleSave(inputValue)}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

function TextIcon() {
  return (
    <Icon
      Icon={
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3H13V4V6H12V4H8.5V12H10V13H6V12H7.5V4H4V6H3V4V3Z"
            fill="#A3A3A3"
          />
        </svg>
      }
      size="xs"
    />
  );
}

export default TextItem;
