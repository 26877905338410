import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/FolderOpen";
import React, { useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import spinner from "../../../assets/small-spinner.gif";

import ButtonPrimary from "@/components/button/buttonprimary";
import { IFVariantFolderGroup } from "@shared/types/Variant";
import type {
  IABPlatformIntegrationModalItemFrontend,
  IFABPlatformIntegrationModalData,
} from "@shared/types/VariantGroup";

import Tooltip from "@/../shared/frontend/Tooltip";
import classNames from "classnames";
import style from "./ABIntegrationModal.module.css";

interface IState {
  loading: boolean;
  flags: IABPlatformIntegrationModalItemFrontend[];
  selectedFlagIndex: number;
  variantFoldersExpanded: Set<string>;
}
const getInitialState = (props: Props): IState => {
  return {
    loading: false,
    flags: props.data.map((f) => {
      return {
        ...f,
        syncedVariants: new Set(f.syncedVariants),
        lockedVariants: new Map(f.lockedVariants.map((v) => [v._id, v.reason])),
      };
    }),
    selectedFlagIndex: 0,
    variantFoldersExpanded: new Set(),
  };
};
const getInitialSyncedVariantsMap = (props: Props) => {
  const map = new Map<string, Set<string>>();
  props.data.forEach((f) => {
    map.set(f.flagId, new Set(f.syncedVariants));
  });
  return map;
};

export interface Props {
  // the name of the 3rd plarty platform
  platformName: string;
  // the name of the thing that a variant maps to in the 3rd party platform
  foreignVariantName: string;
  noFlagsFoundMessage?: string;
  data: IFABPlatformIntegrationModalData;
  variants: IFVariantFolderGroup[];
  isExistingConnection?: boolean;
  onUpdateConnection: (data: IFABPlatformIntegrationModalData) => void;
  onRemoveConnection: () => void;
  onBack?: () => void;
  onClose?: () => void;
}

export const ABIntegrationModal = (props: Props) => {
  const initialSyncedVariantsMap = useRef(getInitialSyncedVariantsMap(props));
  const [state, setState] = useState<IState>(getInitialState(props));

  // there are unsaved changes if, for some flag,
  // the initial set of synced variants is different
  // from the current set of synced variants
  const hasUnsavedChanges = React.useMemo(() => {
    for (const flag of state.flags) {
      const initialVariantsSet = initialSyncedVariantsMap.current.get(flag.flagId);
      if (!initialVariantsSet) {
        throw new Error(`Couldn't find initial variants set for flag ${flag.flagId}`);
      }

      const currentVariantsSet = flag.syncedVariants;

      const difference = getSymmetricDifference(initialVariantsSet, currentVariantsSet);
      // return true the first time that a difference is found
      if (difference.size > 0) {
        return true;
      }
    }

    // return false by default: all synced variants for all flags were found to be unchanged
    return false;
  }, [state]);

  const primaryButtonDisabled = !hasUnsavedChanges || state.loading;
  const primaryButtonText = () => {
    if (!connectionAlreadyExists) {
      return state.loading ? "Adding Connection..." : "Add Connection";
    }

    return state.loading ? "Updating Connection..." : "Update Connection";
  };

  const numFlagsConnected = state.flags.filter((f) => f.syncedVariants.size > 0).length;

  const flagsConnectedText =
    numFlagsConnected > 0
      ? `${numFlagsConnected} flag${numFlagsConnected > 1 ? "s" : ""} connected`
      : "No flags connected";

  const connectionAlreadyExists = !!props.isExistingConnection;

  const title = connectionAlreadyExists ? (
    <span className={style.title}>Manage {props.platformName} Connection</span>
  ) : (
    <span className={style.title}>
      <ChevronLeftIcon className={style.titleChevron} onClick={props.onBack} />
      <span>{props.platformName} Connection Setup</span>
    </span>
  );

  if (state.flags.length === 0) {
    return (
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.modalDialog}
        onHide={props.onClose}
        centered
      >
        <BootstrapModal.Header className={style.modalHeader}>
          <BootstrapModal.Title className={style.titleContainer}>{title}</BootstrapModal.Title>
          <CloseIcon className={style.modalCloseIcon} onClick={props.onClose} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <div className={style.flagsNotFound}>
            {props.noFlagsFoundMessage || `No feature flags found in your ${props.platformName} workspace.`}
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }

  const selectedFlag = state.flags[state.selectedFlagIndex];
  if (!selectedFlag) {
    throw new Error("No flag selected");
  }

  // used for rendering collapsable variant folder groups
  const variantFolders = React.useMemo(
    () => props.variants.filter((v) => v.folderId && v.variants.length > 0),
    [props.variants]
  );

  // used for rendering root-level variants
  const rootVariants = React.useMemo(
    () => props.variants.filter((v) => !v.folderId)[0]?.variants || [],
    [props.variants]
  );

  const onVariantCheckboxChange = (variantId: string, isLocked: boolean) => () => {
    if (isLocked) {
      return;
    }

    const flagId = selectedFlag.flagId;
    setState((s) => {
      const flagIndex = s.flags.findIndex((f) => f.flagId === flagId);
      if (flagIndex === -1) {
        throw new Error("Couldnt find currently selected flag");
      }

      const { syncedVariants } = s.flags[flagIndex];
      syncedVariants.has(variantId) ? syncedVariants.delete(variantId) : syncedVariants.add(variantId);

      return { ...s };
    });
  };

  const onVariantFolderCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const variantFolderId = e.target.value;
    const variantFolder = variantFolders.find((v) => v.folderId === variantFolderId);
    if (!variantFolder) throw new Error(`Couldn't find variant group ${variantFolderId}`);

    const variantFolderIsSelected = variantFolder.variants.every((v) => selectedFlag.syncedVariants.has(v.variantId));

    const flagId = selectedFlag.flagId;

    setState((s) => {
      const flagIndex = s.flags.findIndex((f) => f.flagId === flagId);
      if (flagIndex === -1) {
        throw new Error("Couldnt find currently selected flag");
      }

      variantFolder.variants.forEach((v) => {
        if (s.flags[flagIndex].lockedVariants.has(v.variantId)) {
          return;
        }

        variantFolderIsSelected
          ? s.flags[flagIndex].syncedVariants.delete(v.variantId)
          : s.flags[flagIndex].syncedVariants.add(v.variantId);
      });

      return { ...s };
    });
  };

  const createOnFlagClick = (flagId: string) => () => {
    const flagIndex = state.flags.findIndex((f) => f.flagId === flagId);
    if (flagIndex === -1) throw new Error(`Couldn't find flag ${flagId}`);

    setState((s) => ({ ...s, selectedFlagIndex: flagIndex }));
  };

  const onToggleFolderExpanded = (folderId: string) => () =>
    setState((s) => {
      const isExpanded = s.variantFoldersExpanded.has(folderId);
      isExpanded ? s.variantFoldersExpanded.delete(folderId) : s.variantFoldersExpanded.add(folderId);
      return { ...s };
    });

  const onUpdateConnection = () => {
    setState((s) => ({ ...s, loading: true }));
    props.onUpdateConnection(
      state.flags.map((f) => ({
        flagId: f.flagId,
        flagName: f.flagName,
        syncedVariants: Array.from(f.syncedVariants.values()),
        lockedVariants: Array.from(f.lockedVariants.entries()).map(([_id, reason]) => ({
          _id,
          reason,
        })),
      }))
    );
  };

  return (
    <>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.modalDialog}
        onHide={props.onClose}
        centered
      >
        <BootstrapModal.Header className={style.modalHeader}>
          <BootstrapModal.Title className={style.titleContainer}>{title}</BootstrapModal.Title>
          <CloseIcon className={style.modalCloseIcon} onClick={props.onClose} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          {state.loading && (
            <div className={style.loadingContainer}>
              <img src={spinner} alt="Loading..." />
            </div>
          )}
          <div className={style.flagPanel}>
            <div className={style.flagPanelFlags}>
              <p className={style.flagPanelTitle}>{props.platformName} Flags</p>
              {state.flags.map((flag) => {
                return (
                  <div
                    key={flag.flagId}
                    className={classNames({
                      [style.flag]: true,
                      [style.flagSelected]: selectedFlag.flagId === flag.flagId,
                      [style.flagHasSyncedVariants]: flag.syncedVariants.size > 0,
                    })}
                    onClick={createOnFlagClick(flag.flagId)}
                  >
                    <div className={style.flagLeft}>{flag.flagName}</div>
                    <div className={style.flagRight}>
                      {flag.syncedVariants.size > 0 && (
                        <span className={style.variantsSyncedIndicator}>{flag.syncedVariants.size}</span>
                      )}
                      <ChevronRightIcon className={style.chevron} />
                    </div>
                  </div>
                );
              })}
            </div>
            <button className={style.flagPanelRemoveConnectionButton} onClick={props.onRemoveConnection}>
              Remove Connection
            </button>
          </div>
          <div className={style.variantPanel}>
            <div className={style.variantPanelHeader}>
              <p className={style.variantPanelTitle}>Select variants to sync</p>
              <p className={style.variantPanelDescription}>
                Select the Ditto variants you want to sync to this {props.platformName} flag as{" "}
                {props.foreignVariantName}s.
              </p>
            </div>
            <div className={style.variantPanelContent}>
              {variantFolders.map((variantFolder) => {
                if (!variantFolder.folderId) {
                  return <React.Fragment key="none" />;
                }

                const syncedVariantsCount = variantFolder.variants.filter((v) =>
                  selectedFlag.syncedVariants.has(v.variantId)
                ).length;

                const folderIsSelected = variantFolder.variants.every((v) =>
                  selectedFlag.syncedVariants.has(v.variantId)
                );

                const folderIsExpanded = state.variantFoldersExpanded.has(variantFolder.folderId);

                return (
                  <div
                    key={variantFolder.folderId}
                    className={classNames({
                      [style.variantFolderExpandable]: true,
                      [style.variantFolderExpandableSelected]: folderIsSelected,
                      [style.variantFolderExpandableExpanded]: folderIsExpanded,
                    })}
                  >
                    <div
                      className={style.variantFolderNameContainer}
                      onClick={onToggleFolderExpanded(variantFolder.folderId)}
                    >
                      <input
                        type="checkbox"
                        checked={folderIsSelected}
                        onChange={onVariantFolderCheckboxChange}
                        onClick={(e) => e.stopPropagation()}
                        value={variantFolder.folderId}
                      />{" "}
                      <div className={style.name}>
                        <FolderIcon className={style.folderIcon} />{" "}
                        <span>
                          {variantFolder.folderName}{" "}
                          {syncedVariantsCount > 0 && (
                            <span className={style.variantsSyncedIndicator}>{syncedVariantsCount}</span>
                          )}
                        </span>
                      </div>
                      <div className={style.variantFolderExpandIconContainer}>
                        <ChevronLeftIcon className={style.variantFolderExpandIcon} />
                      </div>
                    </div>
                    <div className={style.variantFolderVariants}>
                      {variantFolder.variants.map((variant) => {
                        const isSelected = selectedFlag.syncedVariants.has(variant.variantId);
                        const lockedReason = selectedFlag.lockedVariants.get(variant.variantId);
                        const isLocked = !!lockedReason;

                        return (
                          <div
                            key={variant.variantId}
                            className={classNames({
                              [style.variant]: true,
                              [style.variantSelected]: isSelected,
                              [style.variantLocked]: !!lockedReason,
                            })}
                            onClick={onVariantCheckboxChange(variant.variantId, isLocked)}
                            data-tip
                            data-for={`locked-${variant.variantId}`}
                          >
                            <input
                              type="checkbox"
                              checked={isSelected}
                              onClick={(e) => e.stopPropagation()}
                              onChange={onVariantCheckboxChange(variant.variantId, isLocked)}
                            />
                            <div className={style.name}>{variant.variantName}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {rootVariants.map((variant) => {
                const isSelected = selectedFlag.syncedVariants.has(variant.variantId);
                const lockedReason = selectedFlag.lockedVariants.get(variant.variantId);
                const isLocked = !!lockedReason;

                return (
                  <Tooltip
                    key={variant.variantId}
                    className={style.lockedTooltip}
                    disabled={!isLocked}
                    content={
                      <span>
                        "{variant.variantName}" {lockedReason}
                      </span>
                    }
                    placement="bottom"
                    theme="dark"
                  >
                    <div
                      className={classNames({
                        [style.variant]: true,
                        [style.variantSelected]: isSelected,
                        [style.variantLocked]: isLocked,
                      })}
                      onClick={onVariantCheckboxChange(variant.variantId, isLocked)}
                    >
                      <input
                        type="checkbox"
                        value={variant.variantId}
                        checked={isSelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={onVariantCheckboxChange(variant.variantId, isLocked)}
                      />
                      <div className={style.name}>{variant.variantName}</div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.modalFooter}>
          <p className={style.flagsConnectedText}>{flagsConnectedText}</p>
          <ButtonPrimary text={primaryButtonText()} disabled={primaryButtonDisabled} onClick={onUpdateConnection} />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

function getSymmetricDifference<T>(a: Set<T>, b: Set<T>) {
  const diffA = new Set(Array.from(a).filter((element) => !b.has(element)));
  const diffB = new Set(Array.from(b).filter((element) => !a.has(element)));
  return new Set([...diffA, ...diffB]);
}
