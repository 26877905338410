import { IFNotification } from "@shared/types/Notification";
import { default as classNames } from "classnames";
import React, { forwardRef } from "react";
import TimeAgo from "react-timeago";
import style from "./NotificationsArea.module.css";

const timeFormatter = (value, unit) => {
  let newUnit = "";
  if (unit === "second") newUnit = "s";
  if (unit === "minute") newUnit = "m";
  if (unit === "hour") newUnit = "h";
  if (unit === "day") newUnit = "d";
  if (unit === "week") newUnit = "w";
  if (unit === "month") newUnit = "m";
  if (unit === "year") newUnit = "y";
  return value + newUnit;
};

interface NotificationsAreaProps {
  unreadNotifs: IFNotification[];
  readNotifs: IFNotification[];
  closeNotificationsArea: () => void;
  markAllNotifsAsRead: () => void;
  markNotifAsRead: (notifId: string) => void;
  goToDocComment: (docId: string, isNorthStar: boolean, compId?: string, commentThreadId?: string) => void;
  goToLibraryComment: (wsCompId: string, commentThreadId?: string) => void;
  checkDetailPanelChanges: (callback: () => void) => void;
}

const NotificationsArea = forwardRef(function (
  props: NotificationsAreaProps,
  ref: React.ForwardedRef<HTMLDivElement | null>
) {
  const {
    unreadNotifs,
    readNotifs,
    markAllNotifsAsRead,
    markNotifAsRead,
    goToDocComment,
    goToLibraryComment,
    checkDetailPanelChanges,
  } = props;

  return (
    <div className={style.notificationsDropdown} ref={ref}>
      <div className={style.header}>
        <div className={style.label}>Notifications</div>
        <div
          className={classNames({
            [style.markAsRead]: true,
            [style.disabled]: unreadNotifs.length === 0,
          })}
          onClick={markAllNotifsAsRead}
        >
          Mark all as read
        </div>
      </div>
      <div className={style.allNotifs}>
        <div className={style.unreads}>
          <div className={style.sublabel}>
            Unread
            {unreadNotifs.length > 0 && ` (${unreadNotifs.length})`}
          </div>
          {unreadNotifs.length > 0 ? (
            unreadNotifs.map((notif, index) => (
              <NotifItem
                index={index}
                notification={notif}
                key={notif._id}
                checkDetailPanelChanges={checkDetailPanelChanges}
                goToDocComment={goToDocComment}
                goToLibraryComment={goToLibraryComment}
                markNotifAsRead={markNotifAsRead}
              />
            ))
          ) : (
            <div className={style.noNotifs}>No unread notifications! 🎉</div>
          )}
        </div>
        {readNotifs.length > 0 && (
          <div className={style.reads}>
            <div className={style.sublabel}>Older</div>
            {readNotifs.map((notif, index) => (
              <NotifItem
                index={index}
                notification={notif}
                key={notif._id}
                checkDetailPanelChanges={checkDetailPanelChanges}
                goToDocComment={goToDocComment}
                goToLibraryComment={goToLibraryComment}
                markNotifAsRead={markNotifAsRead}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

interface NotifItemProps {
  index: number;
  notification: IFNotification;
  checkDetailPanelChanges: (callback: () => void) => void;
  goToDocComment: (docId: string, isNorthStar: boolean, compId?: string, commentThreadId?: string) => void;
  goToLibraryComment: (wsCompId: string, commentThreadId?: string) => void;
  markNotifAsRead: (notifId: string) => void;
}

const NotifItem = (props: NotifItemProps) => {
  const { markNotifAsRead, goToDocComment, goToLibraryComment, checkDetailPanelChanges, index } = props;

  const {
    _id,
    actorUserName,
    action,
    docId,
    compId,
    compIds,
    commentThreadId,
    dateTime,
    docName,
    wsCompId,
    wsCompIds,
    isRead,
    isNorthStar,
  } = props.notification;

  function onClick() {
    checkDetailPanelChanges(() => {
      if (!wsCompId && docId) {
        const compToGoTo = compId || compIds?.[0] || "";
        goToDocComment(docId, isNorthStar, compToGoTo, commentThreadId ?? undefined);
      }
      if (wsCompId || (wsCompIds && wsCompIds.length > 0)) {
        const wsCompToGoTo = wsCompId || wsCompIds?.[0] || "";
        goToLibraryComment(wsCompToGoTo, commentThreadId ?? undefined);
      }
    });
    if (!isRead) markNotifAsRead(_id);
  }

  const isCompLibrary = wsCompId || (wsCompIds && wsCompIds.length > 0);
  return (
    <div
      key={_id}
      onClick={onClick}
      className={classNames(style.notifItem, {
        [style.unread]: !isRead,
      })}
      data-testid={`notif-item-${isRead ? "read" : "unread"}-${index}`}
    >
      <span className={style.notifText}>
        <span className={style.actorName}>{actorUserName}</span> {action} in {isCompLibrary ? "the " : " "}
        <span className={style.docName}>{isCompLibrary ? "component library" : docName}</span>
      </span>
      <TimeAgo date={dateTime} key={dateTime.toString()} formatter={timeFormatter} className={style.time} />
    </div>
  );
};

export default NotificationsArea;
