import DittoComponents from "@/ditto/components__root__base.json";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import Code from "@mui/icons-material/Code";
import Extension from "@mui/icons-material/Extension";
import ExtensionOutlined from "@mui/icons-material/ExtensionOutlined";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import Language from "@mui/icons-material/Language";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { UNIVERSAL_SEARCH_QUERY_PARAM } from "../CommandBar/CommandBar";
import CategoryOutlineRounded from "../icons/CategoryOutlineRounded";
import CategoryRounded from "../icons/CategoryRounded";
import GridViewOutlineRounded from "../icons/GridViewOutlineRounded";
import GridViewRoundedIcon from "../icons/GridViewRounded";
import HomeOutlineRounded from "../icons/HomeOutlineRoundedIcon";
import HomeRounded from "../icons/HomeRoundedIcon";
import SettingsOutlineRounded from "../icons/SettingsOutlineRounded";

type NavRoute = {
  name: string;
  path: string;
  icon: React.FC;
  activeIcon?: React.FC;
  exact?: boolean;
  shortcut?: (string | JSX.Element)[];
};

const navRoutes: Record<string, NavRoute> = {
  home: {
    name: DittoComponents.home.text,
    path: "/",
    icon: HomeOutlineRounded,
    activeIcon: HomeRounded,
    exact: true,
  },
  developers: {
    name: DittoComponents.developers.text,
    path: "/developers",
    icon: Code,
    exact: false,
  },
  search: {
    name: DittoComponents.search.text,
    path: `?${UNIVERSAL_SEARCH_QUERY_PARAM}=true`,
    icon: SearchIcon,
    exact: false,
    shortcut: navigator.userAgent.includes("Mac") ? ["⌘", "K"] : [<KeyboardControlKeyIcon key="K" />, "K"],
  },
  components: {
    name: DittoComponents.components.text,
    path: "/components",
    icon: Language,
    exact: false,
  },
  projects: {
    name: DittoComponents.projects.text,
    path: "/projects",
    icon: GridViewOutlineRounded,
    activeIcon: GridViewRoundedIcon,
    exact: true,
  },
  variants: {
    name: DittoComponents.variants.text,
    path: "/variants",
    icon: () => <CallSplitIcon transform="rotate(90)" />,
    exact: false,
  },
  variables: {
    name: DittoComponents.variables.text,
    path: "/variables",
    icon: CategoryOutlineRounded,
    activeIcon: CategoryRounded,
    exact: false,
  },
  integrations: {
    name: DittoComponents.integrations.text,
    path: "/integrations",
    icon: ExtensionOutlined,
    activeIcon: Extension,
    exact: false,
  },
  settings: {
    name: DittoComponents.settings.text,
    path: "/account",
    icon: SettingsOutlineRounded,
    exact: false,
  },
} as const;

export { navRoutes };
