import {
  AddVariantFormChanges,
  DEFAULT_ADD_VARIANT_CHANGES,
  editableTextItemVariantChangesAtom,
  resetAddVariantFormChangesActionAtom,
  setAddVariantFormChangesActionAtom,
} from "@/stores/Editing";
import { removeVariantFromTextItemsActionAtom } from "@/stores/Project";
import {
  derivedOnlySelectedTextItemAtom,
  derivedOnlySelectedTextItemVariantsAtom,
  detailsPanelPropsAtom,
  getVariantKey,
  textItemVariantsFamilyAtom,
} from "@/stores/ProjectSelection";
import {
  attachVariantActionAtom,
  blockSelectedVariantIdFamilyAtom,
  updateTextItemVariantActionAtom,
} from "@/stores/Variants";
import { deferredVariantsAtom } from "@/stores/Workspace";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import { ComboboxOption } from "@ds/molecules/BaseCombobox";
import AddVariantForm from "@ds/organisms/AddVariantForm";
import TextItemVariant, { ITextItemVariantRef } from "@ds/organisms/TextItemVariant";
import { serializeTipTapRichText } from "@shared/frontend/richText/serializer";
import { ActualComponentStatus, ITextItemVariantUpdate, ITipTapRichText } from "@shared/types/TextItem";
import { AddVariantData, AddVariantUpdateType } from "@shared/types/Variant";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RichTextInput } from "../Metadata";
import style from "./style.module.css";

function VariantsPanel() {
  const selectedTextItem = useAtomValue(derivedOnlySelectedTextItemAtom);
  const wsVariants = useAtomValue(deferredVariantsAtom);
  const selectedTextItemVariants = useAtomValue(derivedOnlySelectedTextItemVariantsAtom);
  const blockSelectedVariantId = useAtomValue(blockSelectedVariantIdFamilyAtom(selectedTextItem?.blockId ?? null));
  const [detailsPanelProps, setDetailsPanelProps] = useAtom(detailsPanelPropsAtom);
  const [addVariantFormOpen, setAddVariantFormOpen] = useState(false);
  const setAddVariantFormHasChanges = useSetAtom(setAddVariantFormChangesActionAtom);
  const resetAddVariantFormHasChanges = useSetAtom(resetAddVariantFormChangesActionAtom);
  const setAttachVariantAction = useSetAtom(attachVariantActionAtom);
  const setUpdateTextItemVariantAction = useSetAtom(updateTextItemVariantActionAtom);
  const setEditableTextItemVariantChanges = useSetAtom(editableTextItemVariantChangesAtom);
  const removeVariantFromTextItemsAction = useSetAtom(removeVariantFromTextItemsActionAtom);

  // Map to keep track of whether each TextItemVariant form has changes (maps variantId to flag)
  const [variantChangesMap, setVariantChangesMap] = useState<Record<string, boolean>>({});
  const [variantsFilter, setVariantsFilter] = useState("");

  const variantFormRefs = useRef<Record<string, ITextItemVariantRef>>({});

  // Variant options for add variant form
  const variantOptions: ComboboxOption[] = useMemo(
    () =>
      wsVariants
        // Remove the currently selected variant from the options in the combobox for adding a new variant
        .filter(
          (variant) =>
            !Object.values(selectedTextItemVariants).some(
              (selectedVariant) => selectedVariant.variantId === variant._id
            )
        )
        .map((variant) => ({ value: variant._id, label: variant.name })),
    [wsVariants, selectedTextItemVariants]
  );

  // Filter that's available when there are 3 or more variants on a text item
  const filteredAndSortedSelectedTextItemVariants = useMemo(() => {
    if (selectedTextItem?._id) {
      return (
        Object.values(selectedTextItemVariants)
          .filter((variant) => variant.name.toLowerCase().includes(variantsFilter.toLowerCase()))
          .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }))
          .sort((a, b) => {
            // Put matching variant first
            if (a.variantId === blockSelectedVariantId) return -1;
            if (b.variantId === blockSelectedVariantId) return 1;
            return 0;
          }) ?? []
      );
    }
    return [];
  }, [blockSelectedVariantId, selectedTextItem?._id, selectedTextItemVariants, variantsFilter]);

  const defaultAddVariantOption: ComboboxOption | undefined = useMemo(() => {
    if (!detailsPanelProps.variants?.defaultVariant) return;
    return {
      value: detailsPanelProps.variants.defaultVariant.id,
      label: detailsPanelProps.variants.defaultVariant.name,
    };
  }, [detailsPanelProps.variants?.defaultVariant]);

  useEffect(() => {
    setAddVariantFormOpen(false);
    resetAddVariantFormHasChanges();
    setVariantsFilter("");
  }, [selectedTextItem?._id, blockSelectedVariantId, resetAddVariantFormHasChanges]);

  // Open/close the add variant form based on external triggers
  useEffect(
    function syncShowAddVariantForm() {
      if (detailsPanelProps?.variants?.showAddForm) {
        setAddVariantFormOpen(true);
        if (detailsPanelProps.variants.defaultVariant?.id) {
          setAddVariantFormHasChanges({ ...DEFAULT_ADD_VARIANT_CHANGES, selectedVariant: true });
        } else {
          resetAddVariantFormHasChanges();
        }
      } else {
        setAddVariantFormOpen(false);
        resetAddVariantFormHasChanges();
      }
    },
    [
      detailsPanelProps?.variants?.showAddForm,
      detailsPanelProps?.variants?.defaultVariant?.id,
      resetAddVariantFormHasChanges,
      setAddVariantFormHasChanges,
    ]
  );

  // Reset the variant form based on external triggers
  useEffect(
    function handleResetVariantForm() {
      // We received a signal to reset the form - call the reset method then clear the resetFormState flag
      if (detailsPanelProps?.resetFormState) {
        function resetVariantFormStates() {
          Object.values(variantFormRefs.current).forEach((ref) => ref.resetFormState());
          setVariantChangesMap({});
        }

        resetVariantFormStates();
        setDetailsPanelProps((prevProps) => ({
          ...prevProps,
          resetFormState: false,
        }));
      }
    },
    [detailsPanelProps?.resetFormState, setDetailsPanelProps]
  );

  /**
   * Handler for when one of the TextItemVariant forms in the Variants panel has changes.
   * Updates the global Jotai state that tracks which variants have unsaved changes.
   *
   * @param hasChanges - Boolean indicating if the specific variant form has unsaved changes
   * @param variantId - ID of the variant the form is associated with
   */
  const onHasTextItemVariantFormChanges = useCallback(
    (hasChanges: boolean, variantId: string) => {
      setVariantChangesMap((prev) => {
        if (prev[variantId] === hasChanges) return prev;

        const newMap = { ...prev, [variantId]: hasChanges };
        setEditableTextItemVariantChanges(newMap);
        return newMap;
      });
    },
    [setEditableTextItemVariantChanges]
  );

  const onAddVariantClick = useCallback(() => {
    setAddVariantFormOpen(true);
    resetAddVariantFormHasChanges();
  }, [setAddVariantFormOpen, resetAddVariantFormHasChanges]);

  const onCancelVariantAdd = useCallback(() => {
    setAddVariantFormOpen(false);
    resetAddVariantFormHasChanges();
    setDetailsPanelProps((prevProps) => ({ ...prevProps, variants: {} }));
  }, [setAddVariantFormOpen, resetAddVariantFormHasChanges, setDetailsPanelProps]);

  const onSaveVariant = useCallback(
    (variant: AddVariantData, updateType: AddVariantUpdateType) => {
      if (!selectedTextItem?._id) return;
      setAttachVariantAction({ variant, updateType, textItemId: selectedTextItem._id });
      setAddVariantFormOpen(false);
      resetAddVariantFormHasChanges();
      setDetailsPanelProps((prevProps) => ({ ...prevProps, variants: {} }));
    },
    [
      setAttachVariantAction,
      setAddVariantFormOpen,
      resetAddVariantFormHasChanges,
      setDetailsPanelProps,
      selectedTextItem?._id,
    ]
  );

  const onUpdateTextItemVariant = useCallback(
    (update: ITextItemVariantUpdate) => {
      if (!selectedTextItem?._id) return;
      setUpdateTextItemVariantAction({ textItemId: selectedTextItem._id, update });
    },
    [setUpdateTextItemVariantAction, selectedTextItem?._id]
  );

  // On each interaction with the Add Variant form,
  // determine whether the form has changes that should trigger a discard confirmation
  const handleNewVariantFormUpdate = useCallback(
    ({
      selectedVariant,
      status,
      richText,
    }: {
      selectedVariant?: ComboboxOption | null;
      status?: ActualComponentStatus;
      richText?: ITipTapRichText;
    }) => {
      let changes: Partial<AddVariantFormChanges> = {};
      if (selectedVariant !== undefined) {
        changes.selectedVariant = !!selectedVariant;
      }
      if (status) {
        changes.status = status !== "NONE";
      }
      if (richText) {
        const { text } = serializeTipTapRichText(richText, { type: "display" });
        changes.richText = text !== "";
      }
      if (Object.keys(changes).length) {
        setAddVariantFormHasChanges(changes);
      }
    },
    [setAddVariantFormHasChanges]
  );

  const handleOnDeleteVariant = useCallback(
    function _handleOnDeleteVariant(variantId: string) {
      if (!selectedTextItem?._id) return;
      removeVariantFromTextItemsAction({ textItemIds: [selectedTextItem._id], variantId });
    },
    [removeVariantFromTextItemsAction, selectedTextItem?._id]
  );

  // Multiselection is not currently supported for variants
  if (!selectedTextItem) {
    return <></>;
  }

  return (
    <div className={style.VariantsPanel}>
      <div className={style.baseVariantSection}>
        <Text size="small" weight="strong">
          Base text
        </Text>
        <TextInput value={selectedTextItem.text} disabled />
      </div>
      {!addVariantFormOpen && (
        <div className={style.addVariantButtonWrapper}>
          <Button expansion="block" level="outline" onClick={onAddVariantClick}>
            Add variant
          </Button>
        </div>
      )}
      {addVariantFormOpen && (
        <AddVariantForm
          selectedTextItemVariantNames={Object.values(selectedTextItemVariants).map((v) => v.name)}
          variantOptions={variantOptions}
          placeholder={selectedTextItem.text}
          defaultOption={defaultAddVariantOption}
          onEdit={handleNewVariantFormUpdate}
          onCancel={onCancelVariantAdd}
          onSave={onSaveVariant}
          RichTextInput={RichTextInput}
        />
      )}
      {/* Show filter when 3 or more variants on a text item */}
      {selectedTextItemVariants.length >= 3 && (
        <div className={style.filterVariantsInputWrapper}>
          <TextInput value={variantsFilter} onChange={setVariantsFilter} placeholder="Filter variants..." />
        </div>
      )}
      {filteredAndSortedSelectedTextItemVariants.map((selectedVariant, idx) => {
        const className = idx === 0 && selectedTextItemVariants.length >= 3 ? style.borderTopHidden : "";
        return (
          <TextItemVariantWrapper
            key={`${selectedVariant.variantId}-${selectedTextItem._id}`}
            className={className}
            variantId={selectedVariant.variantId}
            textItemId={selectedTextItem._id}
            onSave={onUpdateTextItemVariant}
            onDeleteVariant={handleOnDeleteVariant}
            onHasChanges={(hasChanges) => onHasTextItemVariantFormChanges(hasChanges, selectedVariant.variantId)}
            RichTextInput={RichTextInput}
          />
        );
      })}
    </div>
  );
}

interface ITextItemVariantWrapperProps {
  variantId: string;
  textItemId: string;
  onSave: (update: ITextItemVariantUpdate) => void;
  onDeleteVariant: (variantId: string) => void;
  onHasChanges: (hasChanges: boolean) => void;
  RichTextInput: typeof RichTextInput;
  className?: string;
}

/**
 * Memoized wrapper for TextItemVariant that only takes in variantId, textItemId (and callbacks, refs).
 *
 * We use this wrapper to prevent re-renders of a TextItemVariantForm with active edits when saving edits in another TextItemVariant form,
 * by only passing in variantId and textItemId and using those to get the text item variant data from Jotai.
 */
const TextItemVariantWrapper = memo(function _TextItemVariantWrapper(props: ITextItemVariantWrapperProps) {
  const textItemVariant = useAtomValue(textItemVariantsFamilyAtom(getVariantKey(props.textItemId, props.variantId)));
  if (!textItemVariant) return <></>;

  return (
    <TextItemVariant
      className={props.className}
      variantId={textItemVariant.variantId}
      variantStatus={textItemVariant.status}
      variantRichText={textItemVariant.rich_text}
      variantText={textItemVariant.text}
      variantVariables={textItemVariant.variables}
      variantName={textItemVariant.name}
      placeholder={textItemVariant.placeholder}
      RichTextInput={props.RichTextInput}
      onSave={props.onSave}
      onDeleteVariant={props.onDeleteVariant}
      onHasChanges={props.onHasChanges}
    />
  );
});

export default VariantsPanel;
