import { ToastNotification } from "@/components/notification-toast";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { NS_PROJECT_ID_KEY, PROJECT_ID_KEY, useFigmaAuth } from "@/store/FigmaAuthContext";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import sharedStyles from "../style.module.css";
import Toast from "../toast";
import ConnectionsPanel from "./ConnectionsPanel";
import UserPanel from "./UserPanel";

interface UserTabProps {
  permissionGroups: { _id: string; name: string }[];
}

export default function UserTab({ permissionGroups }: UserTabProps) {
  const [notification, setNotification] = useState<ToastNotification | null>(null);

  const { user } = useAuthenticatedAuth();
  const { search } = useLocation();
  const { isFigmaAuthenticated, handleFigmaDisconnect, handleFigmaAuthWithRedirect } = useFigmaAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const projectId = searchParams.get(PROJECT_ID_KEY);
    const nsProjectId = searchParams.get(NS_PROJECT_ID_KEY);
    if (projectId) {
      localStorage.setItem(PROJECT_ID_KEY, projectId);
    }
    if (nsProjectId) {
      localStorage.setItem(NS_PROJECT_ID_KEY, nsProjectId);
    }

    if (searchParams.get("reauthorize_figma") == "true") {
      handleFigmaDisconnect();
      authenticateWithFigma();
    }
  }, [search]);

  const authenticateWithFigma = async () => {
    await handleFigmaAuthWithRedirect();
  };

  const revokeFigmaConnection = async () => {
    handleFigmaDisconnect();
    setNotification({
      type: "success",
      message: "Figma connection removed successfully",
      time: 5000,
    });
  };

  return (
    <div>
      {notification && <Toast notification={notification} />}
      <div className={sharedStyles.container}>
        <UserPanel user={user} permissionGroups={permissionGroups} />
      </div>
      <div className={sharedStyles.container}>
        <ConnectionsPanel
          addFigmaConnection={authenticateWithFigma}
          isFigmaEditor={user.isFigmaEditor}
          isFigmaAuthenticated={isFigmaAuthenticated}
          revokeFigmaConnection={revokeFigmaConnection}
        />
      </div>
    </div>
  );
}
