import classNames from "classnames";
import React from "react";
import style from "./FigmaSyncIcon.module.css";

function FigmaSyncIcon({ isLoading }: { isLoading: boolean }) {
  return (
    <svg
      className={classNames(style.arrowLoader, { [style.isLoading]: isLoading })}
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={style.arrowLoaderBase}
        d="M6 12C4.325 12 2.90625 11.4187 1.74375 10.2562C0.58125 9.09375 0 7.675 0 6C0 4.325 0.58125 2.90625 1.74375 1.74375C2.90625 0.58125 4.325 0 6 0C6.8625 0 7.6875 0.178125 8.475 0.534375C9.2625 0.890625 9.9375 1.4 10.5 2.0625V0H12V5.25H6.75V3.75H9.9C9.5 3.05 8.95312 2.5 8.25937 2.1C7.56562 1.7 6.8125 1.5 6 1.5C4.75 1.5 3.6875 1.9375 2.8125 2.8125C1.9375 3.6875 1.5 4.75 1.5 6C1.5 7.25 1.9375 8.3125 2.8125 9.1875C3.6875 10.0625 4.75 10.5 6 10.5C6.9625 10.5 7.83125 10.225 8.60625 9.675C9.38125 9.125 9.925 8.4 10.2375 7.5H11.8125C11.4625 8.825 10.75 9.90625 9.675 10.7437C8.6 11.5812 7.375 12 6 12Z"
        fill="currentColor"
      />
      <path
        className={style.arrowLoaderSpinning}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 2.68629 9.31371 0 6 0V1.5C8.48528 1.5 10.5 3.51472 10.5 6H12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FigmaSyncIcon;
