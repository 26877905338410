import useSegment from "@/hooks/useSegment";
import DeveloperIdSection from "@ds/molecules/DeveloperIdSection";
import { NS_BETA_INTEREST_DEV_ID } from "@shared/segment-event-names";

function DeveloperId() {
  const { track } = useSegment();

  function onClickLearnMore() {
    track({ event: NS_BETA_INTEREST_DEV_ID });
  }
  return <DeveloperIdSection onClickLearnMore={onClickLearnMore} dividerBelow />;
}

export default DeveloperId;
