import { ITipTapParagraph, ITipTapRichText, ITipTapVariable } from "@shared/types/TextItem";
import { assertUnreachable } from "@shared/utils/assertUnreachable";

/**
 * Serializes a variable node into a text representation and extracts its variable ID.
 */
function serializeVariableNode(variable: ITipTapVariable, options?: IOptions): { text: string; variableId: string } {
  const type = options?.type ?? "database";
  switch (type) {
    case "database": {
      return { text: `{{${variable.attrs.name}}}`, variableId: variable.attrs.variableId };
    }
    case "display": {
      return { text: variable.attrs.text, variableId: variable.attrs.variableId };
    }
    default: {
      assertUnreachable(type, `Unsupported serialization type ${type}`);
    }
  }
}

/**
 * Serializes a paragraph node, combining text and variable nodes into a single string
 * and collecting all variable IDs used within the paragraph.
 */
function serializeParagraphNode(
  paragraph: ITipTapParagraph,
  options?: IOptions
): { text: string; variableIds: string[] } {
  let text = "";
  let variableIds = new Set<string>();

  if (!paragraph.content) {
    return {
      text: "",
      variableIds: [],
    };
  }

  for (const node of paragraph.content) {
    if (node.type === "text") {
      text += node.text;
    } else if (node.type === "variable") {
      const serializedVariable = serializeVariableNode(node, options);
      text += serializedVariable.text;
      variableIds.add(serializedVariable.variableId);
    }
  }
  return {
    text,
    variableIds: Array.from(variableIds),
  };
}

type IOptions =
  | {
      /**
       * This is the default serialization type, used for preparing rich text to be stored as a plain text
       * string in the database. Variables are represented with {{variableName}} placeholders.
       */
      type?: "database";
    }
  | {
      /**
       * This serialization type is used for preparing rich text to be displayed in a plain text string to the
       * user on the front-end. Variables are represented by their interpolated display value, which is computed
       * differently for each type of variable (and is stored as metadata on the variable node in the `rich_text`
       * JSON).
       */
      type?: "display";
    };

/**
 * Serializes a TipTap rich text structure into a plain text string and collects
 * all variable IDs used throughout the rich text content.
 */
export function serializeTipTapRichText(
  richText: ITipTapRichText,
  options?: IOptions
): { text: string; variableIds: string[] } {
  let stringContent: string[] = [];
  const variableIdsUsed = new Set<string>();
  for (const paragraph of richText.content) {
    const serializedParagraph = serializeParagraphNode(paragraph, options);
    stringContent.push(serializedParagraph.text);
    serializedParagraph.variableIds.forEach((variableId) => variableIdsUsed.add(variableId));
  }
  const text = stringContent.join("\n");

  return {
    text,
    variableIds: Array.from(variableIdsUsed),
  };
}
