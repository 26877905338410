import Add from "@mui/icons-material/Add";
import React from "react";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import style from "./index.module.css";

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  options: IOption[];
  selectedOptions: IOption[];
  setSelectedOptions: (options: IOption[]) => void;
  removeFilter?: () => void;

  className?: string;
  style?: React.CSSProperties;
}

export function AddFiltersDropdown(props: IProps) {
  return (
    <DropdownCombobox
      asToggle
      forceToggledDisplay={props.selectedOptions.length > 0}
      multiple
      showTextInput={false}
      options={props.options}
      selectedOptions={props.selectedOptions}
      onChange={props.setSelectedOptions}
      OptionComponent={OptionComponent}
      TriggerComponent={TriggerComponent}
      onRemoveFilter={props.removeFilter}
      triggerLeadingIcon={<Add />}
      closeOnSelect={true}
    />
  );
}

interface ITriggerProps {
  selectedOptions: IOption[];
}
function TriggerComponent(props: ITriggerProps) {
  return (
    <Text size="small" color="primary">
      {props.selectedOptions.length === 0 && "Filter"}
      {props.selectedOptions.length > 0 &&
        `${props.selectedOptions.length} filter${props.selectedOptions.length === 1 ? "" : "s"} applied`}
    </Text>
  );
}

interface IOptionProps {
  option: IOption;
  query: string;
  selected: boolean;
}
function OptionComponent(props: IOptionProps) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <span>{props.option.label}</span>
    </Text>
  );
}

export default AddFiltersDropdown;
