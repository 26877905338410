import * as httpDittoProject from "@/http/dittoProject";
import { getActivityByTextItemId } from "@/http/dittoProject";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import paginatedAtom from "@shared/frontend/stores/paginatedAtom";
import { extractTextItemIds, IChangeItem } from "@shared/types/ActualChange";
import logger from "@shared/utils/logger";
import { validateChangeItems } from "@shared/utils/validateChangeItems";
import { atom } from "jotai";
import { atomFamily, splitAtom, unwrap } from "jotai/utils";
import { projectIdAtom } from "./Project";

// MARK -- Project Activity Atoms

const {
  valueAtom: _projectActivityAtom,
  fetchNextPageActionAtom,
  hasMoreAtom,
  loadingAtom,
} = paginatedAtom<IChangeItem, string | null>({
  dependencyAtom: projectIdAtom,
  pageSize: 20,
  async pageRequest({ page, pageSize }, projectId) {
    try {
      if (!projectId) return [];

      const [request] = httpDittoProject.getActivityByProjectId({ projectId, page, pageSize });
      const response = await request;

      return validateChangeItems(response.data);
    } catch (error) {
      logger.error("Failed to fetch project activity", { context: { projectId, page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Project Activity",
});

export const projectActivitySplitAtom = splitAtom(unwrap(_projectActivityAtom, (prev) => prev ?? []));

export const projectActivityAtom = _projectActivityAtom;
export const fetchNextActivityPageActionAtom = fetchNextPageActionAtom;
export const hasMoreActivityAtom = hasMoreAtom;
export const activityLoadingAtom = loadingAtom;

// MARK -- Text Item Activity Atoms

/**
 * This atom represents change history for individual text items.
 * It is an atomFamily keyed by textItemId.
 */
export const selectedTextItemActivityFamilyAtom = atomFamily((textItemId: string | null) => {
  const { valueAtom: familyAtom } = asyncMutableDerivedAtom<IChangeItem[]>({
    loadData: async () => {
      if (!textItemId) return [];

      const [request] = getActivityByTextItemId({ textItemId });
      const result = await request;

      const validItems = validateChangeItems(result.data);
      return validItems;
    },
  });

  familyAtom.debugLabel = `Selected Activity Family ${textItemId}`;

  return familyAtom;
});

/**
 * This atom is an action that updates any selected activity family atoms already in memory with new changes.
 * Use this for updating the selectedTextItemActivityFamilyAtom when a list of new changes are received via websocket.
 */
export const updateSelectedTextItemActivityActionAtom = atom(null, async (get, set, changeItems: IChangeItem[]) => {
  const idsInCache = [...selectedTextItemActivityFamilyAtom.getParams()];

  // Group all changes by textItemId
  const changesByTextItemId = new Map<string, IChangeItem[]>();

  changeItems.toReversed().forEach(async (changeItem) => {
    const textItemIds = extractTextItemIds(changeItem);
    if (!textItemIds.length) return;

    textItemIds.forEach(async (textItemId) => {
      if (!idsInCache.includes(textItemId)) return;

      if (!changesByTextItemId.has(textItemId)) {
        changesByTextItemId.set(textItemId, []);
      }
      changesByTextItemId.get(textItemId)!.unshift(changeItem);
    });
  });

  // Update each text item's activity with latest changes
  for (const [textItemId, changes] of changesByTextItemId) {
    const existingValue = await get(selectedTextItemActivityFamilyAtom(textItemId));
    set(selectedTextItemActivityFamilyAtom(textItemId), [...changes, ...existingValue]);
  }
});
