import { useAtomRef } from "@/hooks/useAtomRef";
import { flattenedProjectItemsAtom, isEmptyProjectAtom } from "@/stores/Project";
import Text from "@ds/atoms/Text";
import Scrollbar from "@ds/molecules/Scrollbar";
import { atom, useAtomValue } from "jotai";
import { createRef, Suspense, useMemo } from "react";
import BlockNavItem from "./BlockNavItem";
import LeftSidebarContentSkeleton from "./LoadingSkeleton";
import { HiddenProjectItemsNavItem, LoadingNavItem, MessageNavItem } from "./SimpleNavItems";
import TextNavItemWithDrag from "./TextNavItem";
import style from "./style.module.css";

export const leftSidebarScrollRefAtom = atom<HTMLDivElement | null>(null);

function TextPanel() {
  const { flattenedProjectItems } = useAtomValue(flattenedProjectItemsAtom);
  const isEmptyProject = useAtomValue(isEmptyProjectAtom);

  /**
   * Store a mapping from block ID to an array of refs to text items associated
   * with that block.
   */
  const { blockTextItemRefs } = useMemo(() => {
    const blockTextItemRefs = new Map<string, Array<{ id: string; ref: React.RefObject<HTMLDivElement> }>>();
    let currentBlockId: string | null = null;

    flattenedProjectItems.forEach((item) => {
      if (item.type === "block") {
        currentBlockId = item._id;
        blockTextItemRefs.set(currentBlockId, []);
      } else if (item.type === "text") {
        const ref = createRef<HTMLDivElement>();
        if (currentBlockId) {
          blockTextItemRefs.get(currentBlockId)?.push({ id: item._id, ref });
        }
      }
    });

    return { blockTextItemRefs };
  }, [flattenedProjectItems]);

  let currentBlockId: string | null = null;

  if (isEmptyProject) {
    return (
      <>
        <Text color="tertiary" size="small" className={style.emptyStateMessage}>
          Blocks and text in your project will appear here
        </Text>
        <HiddenProjectItemsNavItem />
      </>
    );
  }

  return (
    <>
      {flattenedProjectItems.map((item) => {
        if (item.type === "block") {
          currentBlockId = item._id;
          const textRefs = blockTextItemRefs.get(currentBlockId) || [];
          return (
            <Suspense key={item._id} fallback={<LoadingNavItem />}>
              {/* Pass the array of refs to BlockNavItem */}
              <BlockNavItem item={item} textRefs={textRefs.map((entry) => entry.ref)} />
            </Suspense>
          );
        } else if (item.type === "text") {
          const textRefEntry = blockTextItemRefs.get(currentBlockId!)?.find((entry) => entry.id === item._id);
          return (
            <Suspense key={item._id} fallback={<LoadingNavItem />}>
              <TextNavItemWithDrag item={item} ref={textRefEntry?.ref} />
            </Suspense>
          );
        } else if (item.type === "message") {
          return (
            <Suspense key={item._id} fallback={<LoadingNavItem />}>
              <MessageNavItem item={item} />
            </Suspense>
          );
        }
        return null;
      })}
      <HiddenProjectItemsNavItem />
    </>
  );
}

function TextPanelWrapper() {
  const scrollContentRef = useAtomRef(leftSidebarScrollRefAtom);

  return (
    <Scrollbar disableScrollX className={style.scrollWrapper} {...{ scrollContentRef }}>
      <div className={style.textPanelWrapper}>
        <Suspense fallback={<LeftSidebarContentSkeleton />}>
          <TextPanel />
        </Suspense>
      </div>
    </Scrollbar>
  );
}

export default TextPanelWrapper;
