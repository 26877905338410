import CallSplitIcon from "@mui/icons-material/CallSplit";
import classNames from "classnames";
import React, { useMemo } from "react";
import { ActualComponentStatus } from "../../../../shared/types/TextItem";
import Badge from "../../atoms/Badge";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import OverlappingBubbles from "../OverlappingBubbles";
import Tooltip from "../Tooltip";
import style from "./index.module.css";

export type VariantBadgeData = { variantId: string; variantName: string; status: ActualComponentStatus };

const VARIANT_STATUS_ICON_LIMIT = 3;

function getVariantStatusText(status: ActualComponentStatus) {
  switch (status) {
    case "NONE":
      return "No status";
    case "WIP":
      return "Work in Progress";
    case "REVIEW":
      return "Ready for Review";
    case "FINAL":
      return "Final";
  }
}

interface IVariantBadgeWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

function VariantBadgeWrapper(props: IVariantBadgeWrapperProps) {
  return (
    <Badge
      className={classNames(style.VariantBadgeWrapper, props.className)}
      type="outline"
      borderRadius="lg"
      size="sm"
    >
      <Icon Icon={<CallSplitIcon />} color="secondary" size="xxs" className={style.rotateRight} />
      {props.children}
    </Badge>
  );
}

interface IVariantBadgeProps extends IVariantBadgeWrapperProps {
  variants?: VariantBadgeData[];
}

export function VariantBadge(props: IVariantBadgeProps) {
  const { variants = [], ...wrapperProps } = props;

  const variantStatuses = useMemo(() => variants.map((v) => v.status) || [], [variants]);

  const variantTooltipContent = useMemo(() => {
    if (!variants.length) return null;

    return (
      <div className={style.tooltip}>
        {variants.map((variant) => (
          <Text key={variant.variantId} color="invert">
            {variant.variantName}: {getVariantStatusText(variant.status)}
          </Text>
        ))}
      </div>
    );
  }, [variants]);

  return (
    <Tooltip content={variantTooltipContent} type="invert">
      <VariantBadgeWrapper {...wrapperProps}>
        <Text size="micro" color={variants.length ? "secondary" : "tertiary"}>
          {variants.length}
        </Text>
        {variantStatuses.length > 0 && (
          <OverlappingBubbles
            filledBubbles
            size="sm"
            statuses={variantStatuses}
            bubbleLimit={VARIANT_STATUS_ICON_LIMIT}
          />
        )}
      </VariantBadgeWrapper>
    </Tooltip>
  );
}

export function VariantNotPresentBadge() {
  return (
    <VariantBadgeWrapper>
      <Text color="tertiary" size="micro">
        Variant not present
      </Text>
    </VariantBadgeWrapper>
  );
}

export default VariantBadge;
