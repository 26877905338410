import useAutoScroll, { composeCallbacks } from "@/hooks/useAutoScroll";
import {
  addNewTextItemActionAtom,
  cancelNewTextItemActionAtom,
  isInlineEditingNewTextAtom,
  newTextItemLocationFamilyAtom,
  newTextItemTextValueAtom,
  reorderTextItemsActionAtom,
  saveNewTextItemActionAtom,
} from "@/stores/Editing";
import {
  forceShowAllTextItemsInBlockAtomFamily,
  moveBlocksActionAtom,
  renameBlockActionAtom,
  shiftBlockOrderActionAtom,
} from "@/stores/Project";
import { searchAtom } from "@/stores/ProjectFiltering";
import {
  blockIsSelectedAtom,
  onClickBlockActionAtom,
  selectedItemsAtom,
  selectionTypeAtom,
} from "@/stores/ProjectSelection";
import { blockSelectedVariantIdFamilyAtom, variantTabsForBlockFamilyAtom } from "@/stores/Variants";
import Button from "@ds/atoms/Button";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import TextItem from "@ds/molecules/TextItem";
import TextItemBlock from "@ds/molecules/TextItemBlock";
import { IVariantTab, VariantTabs } from "@ds/molecules/VariantTabs";
import { IDittoBlockData } from "@shared/types/http/DittoProject";
import { ITipTapRichText } from "@shared/types/TextItem";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { last } from "lodash";
import { memo, useCallback, useState } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { textItemListScrollRefAtom } from "../TextItemList";
import TextItemRow from "../TextItemRow";
import style from "./style.module.css";

const TextItemBlockWrapper = memo(function TextItemBlockWrapper(props: {
  textItemBlock: IDittoBlockData;
  index: number;
  maxIndex: number;
  betweenBlocksRowRef?: React.RefObject<HTMLDivElement | null>;
}) {
  const { textItemBlock } = props;

  // variants
  const variantTabs = useAtomValue(variantTabsForBlockFamilyAtom(textItemBlock._id));
  const [blockSelectedVariantId, setBlockSelectedVariantId] = useAtom(
    blockSelectedVariantIdFamilyAtom(textItemBlock._id)
  );

  const [projectContentSearchQuery] = useAtom(searchAtom);
  const newTextItemLocation = useAtomValue(newTextItemLocationFamilyAtom(textItemBlock._id));
  const isInlineEditingNewText = useAtomValue(isInlineEditingNewTextAtom);
  const getSelectionType = useAtomCallback((get) => get(selectionTypeAtom));
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);

  const addNewTextItemAction = useSetAtom(addNewTextItemActionAtom);
  const saveNewTextItemAction = useSetAtom(saveNewTextItemActionAtom);
  const cancelNewTextItemAction = useSetAtom(cancelNewTextItemActionAtom);

  const isSelected = useAtomValue(blockIsSelectedAtom(textItemBlock._id));
  const onClickBlockAction = useSetAtom(onClickBlockActionAtom);
  const renameBlockAction = useSetAtom(renameBlockActionAtom);
  const shiftBlockOrderAction = useSetAtom(shiftBlockOrderActionAtom);
  const scrollProps = useAutoScroll(textItemScrollContainer);
  const setSelectedItems = useSetAtom(selectedItemsAtom);

  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const moveBlockItemsAction = useSetAtom(moveBlocksActionAtom);
  const setNewTextItemTextValue = useSetAtom(newTextItemTextValueAtom);

  const [forceShowAllTextItemsInBlock, setForceShowAllTextItemsInBlock] = useAtom(
    forceShowAllTextItemsInBlockAtomFamily(textItemBlock._id || "")
  );
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const isAddingTextItemToBlock = !!newTextItemLocation;

  const activeVariant = variantTabs.find((v) => v.id === blockSelectedVariantId) ?? { id: "__base__", name: "Base" };

  // filtering
  const numHiddenResultsTextItems = textItemBlock.allTextItems.length - textItemBlock.textItems.length;
  const textItemType = forceShowAllTextItemsInBlock ? "allTextItems" : "textItems";

  function handleRenameBlock(name: string) {
    if (textItemBlock._id) renameBlockAction(textItemBlock._id, name);
    setIsDragDisabled(false);
  }

  function onTabClick(tab: IVariantTab) {
    setBlockSelectedVariantId(tab.id);
  }

  function onCancelNewTextItem() {
    cancelNewTextItemAction({ skipConfirmation: true });
  }

  function newTextItemOnEscape() {
    cancelNewTextItemAction({ skipConfirmation: false });
  }

  const onNewTextItemTextChange = useCallback(
    (richText: ITipTapRichText) => {
      setNewTextItemTextValue(richText);
    },
    [setNewTextItemTextValue]
  );

  const onMoveBlockUp = useCallback(
    function _onMoveBlockUp() {
      shiftBlockOrderAction({ blockId: textItemBlock._id, direction: "up" });
    },
    [shiftBlockOrderAction, textItemBlock._id]
  );

  const onMoveBlockDown = useCallback(
    function _onMoveBlockDown() {
      shiftBlockOrderAction({ blockId: textItemBlock._id, direction: "down" });
    },
    [shiftBlockOrderAction, textItemBlock._id]
  );

  const onClickBlock = useCallback(
    function _onClickBlock() {
      if (textItemBlock._id) {
        onClickBlockAction(textItemBlock._id);
      }
    },
    [onClickBlockAction, textItemBlock._id]
  );

  const onFocusName = useCallback(
    function _onFocusName() {
      setIsDragDisabled(true);

      if (textItemBlock._id) {
        setSelectedItems({ type: "block", id: textItemBlock._id });
      }
    },
    [setSelectedItems, textItemBlock._id]
  );

  const onBlurName = useCallback(function _onBlurName() {
    setIsDragDisabled(false);
  }, []);

  if (textItemBlock._id === null) {
    const renderTextItemsNotInBlock = textItemBlock.textItems.length > 0 || isAddingTextItemToBlock;

    return (
      <>
        {renderTextItemsNotInBlock && (
          <div className={style.textItemsNotInBlock}>
            {newTextItemLocation === "start" && (
              <TextItem
                autoFocus
                onClickSave={saveNewTextItemAction}
                onClickCancel={onCancelNewTextItem}
                onEscape={newTextItemOnEscape}
                editState="editing"
                onTextChange={onNewTextItemTextChange}
                className={style.textItemNotInBlock}
              />
            )}
            {textItemBlock.textItems.map((textItem) => (
              <TextItemRow
                key={textItem._id}
                textItemId={textItem._id}
                highlightedPhrase={projectContentSearchQuery}
                activeVariantId={activeVariant.id}
                activeVariantName={activeVariant.name}
                className={style.textItemNotInBlock}
              />
            ))}
            {newTextItemLocation === "end" && (
              <TextItem
                autoFocus
                onClickSave={saveNewTextItemAction}
                onClickCancel={onCancelNewTextItem}
                onEscape={newTextItemOnEscape}
                editState="editing"
                onTextChange={onNewTextItemTextChange}
                className={style.textItemNotInBlock}
              />
            )}
          </div>
        )}
      </>
    );
  }

  function handleDrop(itemIds: string[], dragLocation: "above" | "below" | null) {
    const selectionType = getSelectionType();

    if (selectionType === "text") {
      /**
       * Base reference text item on either the first or last item in the block, dependent on
       * drag location.
       */
      const referenceTextItem =
        dragLocation === "above" ? textItemBlock.allTextItems[0] : last(textItemBlock.allTextItems);

      reorderTextItemsAction([
        {
          textItemIds: itemIds,
          blockId: textItemBlock._id,
          before: dragLocation === "above" ? referenceTextItem?._id : undefined,
          after: dragLocation === "below" ? referenceTextItem?._id : undefined,
        },
      ]);
    } else if (selectionType === "block") {
      if (!textItemBlock._id) {
        throw new Error("Attempted to drop onto the root block, this should never happen");
      }

      moveBlockItemsAction({
        blockIds: itemIds,
        destinationBlockId: textItemBlock._id,
        direction: dragLocation,
      });
    }
  }

  const draggableItems = [
    {
      "ditto/blockItem": textItemBlock._id,
      "plain/text": textItemBlock._id,
    },
  ];

  function setBlockRowToDragging() {
    if (props.betweenBlocksRowRef) {
      props.betweenBlocksRowRef.current?.setAttribute("data-dragging", "true");
    }
  }

  function setBlockRowToNotDragging() {
    if (props.betweenBlocksRowRef) {
      props.betweenBlocksRowRef.current?.removeAttribute("data-dragging");
    }
  }

  function onDragStart(e: DragStartEvent) {
    if (textItemBlock._id) {
      onClickBlockAction(textItemBlock._id);
      setBlockRowToDragging();
    }
  }

  const onDragEnd = setBlockRowToNotDragging;
  const onDragCancel = setBlockRowToNotDragging;

  const dragProps = composeCallbacks([scrollProps, { onDragStart, onDragEnd, onDragCancel }]);

  return (
    <DragAndDroppable
      getDraggableItems={() => draggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string(), "ditto/blockItem": z.string() }}
      onDrop={handleDrop}
      isDragDisabled={isDragDisabled}
      {...dragProps}
    >
      {(dragAndDropProps) => {
        const selectionType = getSelectionType();

        const droppingBlock = dragAndDropProps.isDropTarget && selectionType === "block";
        const droppingBlockAbove = droppingBlock && dragAndDropProps.dragLocation === "above";
        const droppingBlockBelow = droppingBlock && dragAndDropProps.dragLocation === "below";

        const droppingText = dragAndDropProps.isDropTarget && selectionType === "text";
        const droppingTextAbove = droppingText && dragAndDropProps.dragLocation === "above";
        const droppingTextBelow = droppingText && dragAndDropProps.dragLocation === "below";

        const isHidingTextItems = numHiddenResultsTextItems > 0 && !forceShowAllTextItemsInBlock;

        return (
          <div className={style.textItemBlockAndVariants}>
            {droppingBlockAbove && (
              <div className={style.dropIndicatorWrapper}>
                <div className={style.dropIndicator} />
              </div>
            )}
            {variantTabs.length > 0 && (
              <VariantTabs variantTabs={variantTabs} activeVariant={activeVariant} onTabClick={onTabClick} />
            )}
            <TextItemBlock
              isEmpty={!isAddingTextItemToBlock && textItemBlock.allTextItems.length === 0}
              onClickBlock={onClickBlock}
              name={textItemBlock.name ?? "Block"}
              onSaveName={handleRenameBlock}
              // prevent dragging when we're editing block name
              onFocusName={onFocusName}
              onBlurName={onBlurName}
              highlightedPhrase={projectContentSearchQuery}
              state={isSelected ? "focus" : "default"}
              disableAddTextItem={isInlineEditingNewText || activeVariant.id !== BASE_VARIANT_ID}
              onMoveBlockUp={onMoveBlockUp}
              onMoveBlockDown={onMoveBlockDown}
              canMoveDown={props.index < props.maxIndex}
              canMoveUp={props.index > 0}
              onAddNewTextItem={() => addNewTextItemAction({ blockId: textItemBlock._id })}
              showAddTextItemButton={!isHidingTextItems}
            >
              {droppingTextAbove && (
                <div className={style.dropIndicatorWrapper}>
                  <div className={style.dropIndicator} />
                </div>
              )}
              <div className={style.textItems}>
                {textItemBlock[textItemType].map((textItem) => (
                  <TextItemRow
                    key={textItem._id}
                    textItemId={textItem._id}
                    highlightedPhrase={projectContentSearchQuery}
                    activeVariantId={activeVariant.id}
                    activeVariantName={activeVariant.name}
                  />
                ))}
              </div>
              {droppingTextBelow && (
                <div className={style.dropIndicatorWrapper}>
                  <div className={style.dropIndicator} />
                </div>
              )}
              {isAddingTextItemToBlock && (
                <TextItem
                  autoFocus
                  onClickSave={saveNewTextItemAction}
                  onClickCancel={onCancelNewTextItem}
                  onEscape={newTextItemOnEscape}
                  editState="editing"
                  onTextChange={onNewTextItemTextChange}
                />
              )}
              {isHidingTextItems && (
                <div className={style.hiddenResultsMessageBlock}>
                  <div>
                    {numHiddenResultsTextItems} more text {numHiddenResultsTextItems === 1 ? "item" : "items"} in this
                    block not shown in search result
                  </div>
                  <div className={style.hiddenResultsMessageBlockDivider}>•</div>
                  <Button level="subtle" size="micro" onClick={() => setForceShowAllTextItemsInBlock(true)}>
                    Show all
                  </Button>
                </div>
              )}
            </TextItemBlock>
            {droppingBlockBelow && (
              <div className={style.dropIndicatorWrapper}>
                <div className={style.dropIndicator} />
              </div>
            )}
          </div>
        );
      }}
    </DragAndDroppable>
  );
});

export default TextItemBlockWrapper;
