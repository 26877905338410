import useSegment from "@/hooks/useSegment";
import { subscribeToBetaLibraryUpdates } from "@/http/user";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import Dot from "@ds/atoms/Dot";
import Text from "@ds/atoms/Text";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_LIBRARY_INTEREST } from "@shared/segment-event-names";
import logger from "@shared/utils/logger";
import React, { useCallback, useState } from "react";
import style from "./style.module.css";

/**
 * Renders a banner at the top of the Components page indicating that the Beta
 * Component library is coming soon and allows users to sign up for updates.
 *
 * Should only be rendered with the North Star feature flag enabled.
 */
function BetaBanner() {
  const { user, refreshUser } = useAuthenticatedAuth();
  const isSubscribed = user?.isSubscribedToBetaLibraryUpdates;
  const [isSubscribing, setIsSubscribing] = useState(false);
  const { track } = useSegment();

  const handleClickSubscribe = useCallback(async () => {
    try {
      setIsSubscribing(true);
      const [request] = subscribeToBetaLibraryUpdates();
      await request;
      refreshUser();
      setIsSubscribing(false);
    } catch (e) {
      logger.error("Error subscribing to beta library updates", {}, e);
    }

    track({
      event: NS_BETA_LIBRARY_INTEREST,
      properties: {
        action: "subscribe",
        location: "legacy-library-page",
      },
    });
  }, [refreshUser, track]);

  const handleClickLearnMore = useCallback(() => {
    window.open(LEARN_MORE_URL.LIBRARY, "_blank");
    track({
      event: NS_BETA_LIBRARY_INTEREST,
      properties: {
        action: "learn_more",
        location: "legacy-library-page",
      },
    });
  }, [track]);

  return (
    <div className={style.banner}>
      <div className={style.bannerLeft}>
        <Text color="primary" size="small" weight="medium">
          The new ditto library beta is coming soon
        </Text>
        <Dot size={2} color="icon-secondary" />
        {isSubscribed ? (
          <Text color="secondary" size="small">
            Subscribed to updates
          </Text>
        ) : (
          <Text
            color="action"
            size="small"
            asLink={!isSubscribing}
            onClick={isSubscribing ? undefined : handleClickSubscribe}
          >
            Sign up for updates
          </Text>
        )}
      </div>
      <div>
        <Text color="secondary" size="small" asLink onClick={handleClickLearnMore}>
          Learn more about the New Ditto beta
        </Text>
      </div>
    </div>
  );
}

export default BetaBanner;
