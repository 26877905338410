import classNames from "classnames";
import React from "react";
import { ENTRY_TYPES, IChangeItem } from "../../../../shared/types/ActualChange";
import { ITextItemStatus } from "../../../../shared/types/TextItem";
import EditedText from "../EditedText";
import Text from "../Text";
import style from "./index.module.css";

interface IProps {
  changeItem: IChangeItem;
}

export function ChangeItemContent({ changeItem }: IProps) {
  switch (changeItem.entry_type) {
    case ENTRY_TYPES.DITTO_PROJECT_CREATED:
      return (
        <Text color="primary" size="small">
          {changeItem.doc_name}
        </Text>
      );
    case ENTRY_TYPES.DITTO_PROJECT_RENAMED:
      return <EditedText size="small" textBefore={changeItem.data.previousName} textAfter={changeItem.data.newName} />;
    case ENTRY_TYPES.DITTO_PROJECT_MOVED_TO_FOLDER:
      return (
        <Text color="primary" size="small">
          {changeItem.data.folderNameAfter ?? changeItem.data.folderNameBefore}
        </Text>
      );
    case ENTRY_TYPES.DITTO_BLOCK_CREATED:
      return (
        <Text color="primary" size="small">
          {changeItem.data.name}
        </Text>
      );
    case ENTRY_TYPES.DITTO_BLOCK_UPDATED:
      if (changeItem.data.before.name === changeItem.data.after.name) {
        return (
          <Text color="primary" size="small">
            {changeItem.data.after.name}
          </Text>
        );
      }
      return (
        <EditedText size="small" textBefore={changeItem.data.before.name} textAfter={changeItem.data.after.name} />
      );
    case ENTRY_TYPES.DITTO_BLOCK_DELETED:
      return (
        <Text color="tertiary" size="small">
          {changeItem.data.name}
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_CREATED:
      return (
        <Text color="primary" size="small">
          {changeItem.data.text}
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_DELETED:
      return (
        <Text color="tertiary" size="small">
          {changeItem.data.text}
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_ATTACHED:
      if (changeItem.data.text)
        return (
          <Text color="primary" size="small">
            {changeItem.data.text}
          </Text>
        );
      else
        return (
          <Text color="tertiary" size="small">
            No text value
          </Text>
        );
    case ENTRY_TYPES.SYNC_CONFLICT_RESOLVED:
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_EDIT:
    case ENTRY_TYPES.EDIT:
      return <EditedText size="small" textBefore={changeItem.text_before} textAfter={changeItem.text_after} />;
    case ENTRY_TYPES.STATUS:
    case ENTRY_TYPES.DUPES_STATUS:
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_STATUS:
      return <StatusChanged status={changeItem.status} />;
    case ENTRY_TYPES.COMP_ASSIGNED:
    case ENTRY_TYPES.MULTI_COMP_ASSIGNED:
    case ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE:
    case ENTRY_TYPES.PLURAL_ADDED:
    case ENTRY_TYPES.PLURAL_EDITED:
    case ENTRY_TYPES.PLURAL_REMOVED:
    case ENTRY_TYPES.VARIANT_CREATED:
      return null;
    default:
      // @ts-expect-error This switch should be exhaustive. If this errors, an entry type is missing.
      const never = changeItem.entry_type;
      return null;
  }
}

const statuses: Record<ITextItemStatus, { color: "secondary" | "danger" | "warning" | "positive"; label: string }> = {
  NONE: { color: "secondary", label: "No status" },
  WIP: { color: "danger", label: "Work in Progress" },
  REVIEW: { color: "warning", label: "Ready for Review" },
  FINAL: { color: "positive", label: "Final" },
} as const;

function StatusChanged({ status }: { status: ITextItemStatus }) {
  const { color, label } = statuses[status];

  return (
    <Text
      color="primary"
      size="small"
      className={classNames(style.statusWrapper, {
        [style[`status-color-${color}`]]: color,
      })}
    >
      Marked as{" "}
      <Text size="small" weight="medium">
        {label}
      </Text>
    </Text>
  );
}

function ChangeItemContentWrapper({ changeItem }: IProps) {
  return (
    <div data-testid="change-item-content">
      <ChangeItemContent changeItem={changeItem} />
    </div>
  );
}

export default ChangeItemContentWrapper;
