import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ISelectedVariant } from "./components/VariantDetail/EditVariant";

/**
 * The variants loaded in state on the page
 */
interface ILoadedVariant {
  _id: string;
  name: string;
  apiID: string;
  description: string;
  isSample: boolean;
  integrations: ("split" | "launchDarkly")[];
  components: {
    _id: string;
    name: string;
  }[];
  // TODO: Remove references to legacy docs field after migrating away from its usage:
  // https://linear.app/dittowords/issue/DIT-8889/remove-denormalized-variants-fields-docs-and-components
  docs: {
    _id: string;
    frames: string[];
    doc_ID: {
      _id: string;
      doc_name: string;
      groups: {
        _id: string;
        linking_enabled: boolean;
      }[];
    };
  }[];
  projects: {
    _id: string;
    projectName: string;
    groups?: {
      _id: string;
      linking_enabled: boolean;
    }[];
    // The version of the project the variant is used in, 1 for legacy, 2 for NS
    version: 1 | 2;
  }[];
}

type IVariantSelectionState =
  | {
      type: "none";
    }
  | {
      type: "single";
      /**
       * Variant data nicely formatted for the edit panel
       */
      selectedVariant: ISelectedVariant;
      tab: "edit" | "instances";
    };

function getSelectedVariant(variantId: string, variants: ILoadedVariant[]): ISelectedVariant | null {
  const variant = variants.find((v) => v._id === variantId);
  if (!variant) {
    return null;
  }

  // It's intentional that we push project instances first -- the designs
  // call for all projects to be listed first
  const instances: ISelectedVariant["instances"] = [];

  // TODO: Remove references to legacy docs field after migrating away from its usage:
  // https://linear.app/dittowords/issue/DIT-8889/remove-denormalized-variants-fields-docs-and-components
  variant.docs.forEach((docInstance) => {
    instances.push({
      type: "project",
      projectId: docInstance.doc_ID._id,
      projectName: docInstance.doc_ID.doc_name,
      groupsAttached: docInstance.frames.length,
      groupsTotal: docInstance.doc_ID.groups.length,
    });
  });
  variant.projects.forEach((project) => {
    instances.push({
      type: "NSproject",
      projectId: project._id,
      projectName: project.projectName,
    });
  });
  variant.components.forEach((componentInstance) => {
    instances.push({
      type: "component",
      componentId: componentInstance._id,
      componentName: componentInstance.name,
    });
  });

  return {
    _id: variant._id,
    apiID: variant.apiID,
    description: variant.description,
    name: variant.name,
    instances,
    isSample: variant.isSample,
    integrations: variant.integrations,
  };
}

export function useVariantSelectionState(variants: ILoadedVariant[]) {
  const { id } = useParams<{
    id?: string;
  }>();
  const [selectionStateBase, setSelectionStateBase] = useState<IVariantSelectionState>({
    type: "none",
  });

  useEffect(() => {
    if (id) {
      console.log("handl single select");
      handleSingleSelect(id);
    }
  }, [id]);

  /**
   * Toggles selection on the specified variant. If `shouldToggle` is turned off via the options,
   * calling will refresh the selection state for the specified variant.
   * @param variantId
   * @param options
   */
  const handleSingleSelect = (variantId: string) => {
    setSelectionStateBase((s) => {
      if (s.type === "single" && s.selectedVariant._id === variantId) {
        return { type: "none" };
      }

      const selectedVariant = getSelectedVariant(variantId, variants);
      if (!selectedVariant) {
        return { type: "none" };
      }

      return {
        type: "single",
        selectedVariant,
        tab: "edit",
      };
    });
  };

  /**
   * Selects the specified tab in the single-selected variant edit panel.
   * Noops if the selection state is not `single`.
   * @param tab
   */
  const handleSelectTab = (tab: "edit" | "instances") => {
    if (selectionStateBase.type === "single") {
      setSelectionStateBase({
        ...selectionStateBase,
        tab,
      });
    }
  };

  const clearSelection = () => {
    setSelectionStateBase({ type: "none" });
  };

  // This `useMemo` does a little bit of extra computation that overlaps
  // with `handleSingleSelect`, but ensures that the `selectionState`
  // remains consistent relative to the `variants` prop.
  const selectionState = useMemo(() => {
    if (selectionStateBase.type === "none") {
      return selectionStateBase;
    }

    // Refresh the `selectedVariant` object from the variants in memory,
    // as the variant it corresponds to may have been updated or deleted
    const selectedVariant = getSelectedVariant(selectionStateBase.selectedVariant._id, variants);
    if (!selectedVariant) {
      return { type: "none" };
    }

    return {
      ...selectionStateBase,
      selectedVariant,
    };
  }, [selectionStateBase, variants]);

  return [
    selectionState,
    {
      handleSingleSelect,
      handleSelectTab,
      clearSelection,
    },
  ] as const;
}
