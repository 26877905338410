import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import style from "./index.module.css";

export interface ILibraryComingSoonProps {
  className?: string;
  layout?: "vertical" | "horizontal";
  isSubscribed: boolean;
  onClickSubscribe: () => void;
  onClickLearnMore: () => void;
}

const HORIZONTAL_ICON_STYLE = { width: "16px" };
const VERTICAL_ICON_STYLE = {};

export function LibraryComingSoon(props: ILibraryComingSoonProps) {
  const isHorizontal = props.layout === "horizontal";

  return (
    <div
      className={classNames(
        style.LibraryComingSoonWrapper,
        {
          [style.vertical]: !isHorizontal,
          [style.horizontal]: isHorizontal,
        },
        props.className
      )}
      data-testid="library-coming-soon"
    >
      <div className={style.container}>
        <ImportContactsIcon className={style.icon} style={isHorizontal ? HORIZONTAL_ICON_STYLE : VERTICAL_ICON_STYLE} />
        <div className={style.contentContainer}>
          <div className={style.textContainer}>
            <div className={style.text}>
              <Text
                color="primary"
                size="base"
                weight={isHorizontal ? "base" : "medium"}
                textAlign={isHorizontal ? "left" : "center"}
              >
                The library is coming soon
              </Text>
            </div>
            <div className={style.text}>
              <Text color="secondary" size="small" textAlign={isHorizontal ? "left" : "center"}>
                Publish text to your library to reuse it across projects. Make edits once in your library, and sync
                those edits everywhere that text is used.
              </Text>
            </div>
          </div>
          <div className={style.buttonsContainer}>
            <Button
              level="primary"
              size={isHorizontal ? "micro" : "small"}
              disabled={props.isSubscribed}
              onClick={props.onClickSubscribe}
            >
              {props.isSubscribed ? "Subscribed to updates!" : "Sign me up for updates"}
            </Button>
            <Button level="secondary" size={isHorizontal ? "micro" : "small"} onClick={props.onClickLearnMore}>
              Learn more
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LibraryComingSoon;
