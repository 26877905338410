import * as httpComments from "@/http/commentsTyped";
import * as httpDittoProject from "@/http/dittoProject";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import paginatedAtom from "@shared/frontend/stores/paginatedAtom";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { ICommentThread } from "@shared/types/CommentThread";
import logger from "@shared/utils/logger";
import { atom } from "jotai";
import { atomFamily, splitAtom, unwrap } from "jotai/utils";
import { projectIdAtom, projectStoreAtom, textItemFamilyAtom } from "./Project";
import { commentEditsAtom } from "./ProjectSelection";

/**
 * A family atom that maps comment_thread_id to ICommentThread.
 */
export const { familyAtom: commentFamilyAtom, resetAtom: resetCommentFamilyAtomActionAtom } =
  batchedAsyncAtomFamily<ICommentThread>({
    storeAtom: projectStoreAtom,
    asyncFetchRequest: async (get, ids) => {
      const projectId = get(projectIdAtom);
      if (!projectId) return []; // Should never happen

      const [request] = httpComments.getCommentsByCommentIds({
        commentIds: ids,
        projectId,
        type: "standard",
      });
      const response = await request;

      return response.data;
    },
    getId: (item) => item._id,
    debugPrefix: "Comment",
  });

/**
 * Stores the list of comment_thread_ids to render the Project Comments panel. This is a paginated atom.
 */
const {
  valueAtom: _projectCommentsAtom,
  fetchNextPageActionAtom: _fetchNextCommentsPageActionAtom,
  hasMoreAtom: _hasMoreCommentsAtom,
  loadingAtom: _commentsLoadingAtom,
} = paginatedAtom<string, string | null>({
  dependencyAtom: projectIdAtom,
  pageSize: 20,
  async pageRequest({ page, pageSize }, projectId) {
    try {
      if (!projectId) return [];

      const [request] = httpComments.getCommentsByProjectId({ projectId, page, pageSize, type: "standard" });
      const response = await request;

      return response.data;
    } catch (error) {
      logger.error("Failed to fetch project comments", { context: { projectId, page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Project Comments",
});

export const projectCommentsSplitAtom = splitAtom(unwrap(_projectCommentsAtom, (prev) => prev ?? []));

export const projectCommentsAtom = _projectCommentsAtom;
export const fetchNextCommentsPageActionAtom = _fetchNextCommentsPageActionAtom;
export const hasMoreCommentsAtom = _hasMoreCommentsAtom;
export const commentsLoadingAtom = _commentsLoadingAtom;

/**
 * This atom keeps track of the comments (just thread ids) for a given text item.
 * It is an atomFamily keyed by textItemId.
 */
export const selectedTextItemCommentsFamilyAtom = atomFamily((textItemId: string | null) => {
  const { valueAtom: familyAtom } = asyncMutableDerivedAtom<string[]>({
    loadData: async () => {
      if (!textItemId) return [];

      const [request] = httpComments.getCommentsByTextItemId({ textItemId, type: "standard" });
      const result = await request;
      return result.data;
    },
  });

  familyAtom.debugLabel = `Selected Comment Family ${textItemId}`;

  return familyAtom;
});

/**
 * This atom represents the most recent comment for a given text item.
 * It is an atomFamily that maps textItemId to comment_thread_id.
 */
export const mostRecentTextItemCommentFamilyAtom = atomFamily((textItemId: string | null) => {
  const { valueAtom, refreshAtom } = asyncMutableDerivedAtom<string | null>({
    loadData: async (get) => {
      if (!textItemId) return null;
      const projectId = get(projectIdAtom);
      if (!projectId) return null;

      const [request] = httpDittoProject.getMostRecentCommentThreadId({ projectId, textItemId, type: "standard" });
      const result = await request;

      return result.data?._id ?? null;
    },
  });

  // This allows us to call set(atomFamily(id), REFRESH_SILENTLY) to force a re-fetch
  const familyAtom = atom(
    (get) => get(valueAtom),
    (get, set, newValue: string | null | typeof REFRESH_SILENTLY) => {
      if (newValue === REFRESH_SILENTLY) {
        set(refreshAtom);
      } else {
        set(valueAtom, newValue);
      }
    }
  );

  familyAtom.debugLabel = `Most Recent Comment Family ${textItemId}`;

  return familyAtom;
});

// MARK: Actions

/**
 * An action to add or update a comment in the commentFamilyAtom with the provided comment data.
 */
export const updateCommentActionAtom = atom(null, (_get, set, comment: ICommentThread) => {
  set(commentFamilyAtom(comment._id), comment);
});

/**
 * An action to update the commentEditsAtom with the provided commentId.
 * If typing a new comment, pass "new" as the commentThreadId.
 */
export const updateCommentEditsAtom = atom(
  null,
  (get, set, { commentThreadId, hasUnsavedChanges }: { commentThreadId: string; hasUnsavedChanges: boolean }) => {
    const prevEdits = get(commentEditsAtom);
    const updatedEdits = new Set(prevEdits);

    if (hasUnsavedChanges) {
      set(commentEditsAtom, updatedEdits.add(commentThreadId));
    } else {
      updatedEdits.delete(commentThreadId);
      set(commentEditsAtom, updatedEdits);
    }
  }
);

/**
 * An action to update jotai after we've detected a new comment thread was created.
 */
export const handleCommentCreatedActionAtom = atom(null, async (get, set, newComment: ICommentThread) => {
  // Add the new comment's data to the commentFamilyAtom
  set(updateCommentActionAtom, newComment);

  // Add the new comment to projectCommentsAtom
  set(projectCommentsAtom, (prev) => {
    if (!prev) return prev;
    if (prev.includes(newComment._id)) return prev;
    return [newComment._id, ...prev];
  });

  if (newComment.comp_id) {
    // Update the most recent comment for the text item
    set(mostRecentTextItemCommentFamilyAtom(newComment.comp_id), newComment._id);

    // If this text item's comment family is already loaded, add the new comment to it
    const idsInCache = [...selectedTextItemCommentsFamilyAtom.getParams()];
    if (idsInCache.includes(newComment.comp_id)) {
      const existingComments = await get(selectedTextItemCommentsFamilyAtom(newComment.comp_id));
      if (!existingComments.includes(newComment._id)) {
        set(selectedTextItemCommentsFamilyAtom(newComment.comp_id), [newComment._id, ...existingComments]);
      }
    }

    // Add the id to textItem.comment_threads, which renders the comment count badge on each text item
    const textItem = await get(textItemFamilyAtom(newComment.comp_id));
    if (!textItem.comment_threads.includes(newComment._id)) {
      set(textItemFamilyAtom(newComment.comp_id), {
        ...textItem,
        comment_threads: [newComment._id, ...textItem.comment_threads],
      });
    }
  }
});

/**
 * An action to update jotai after we've detected a comment thread was updated.
 */
export const handleCommentUpdatedActionAtom = atom(null, async (get, set, newComment: ICommentThread) => {
  // Add the new comment's data to the commentFamilyAtom
  set(updateCommentActionAtom, newComment);
});

/**
 * An action to update jotai after we've detected a comment thread was resolved or unresolved.
 */
export const handleCommentResolutionUpdatedActionAtom = atom(
  null,
  async (get, set, { commentThreadId, isResolved }: { commentThreadId: string; isResolved: boolean }) => {
    const existingComment = await get(commentFamilyAtom(commentThreadId));
    if (!existingComment) {
      return;
    }

    // Update the comment data in the commentFamilyAtom
    if (existingComment.is_resolved !== isResolved) {
      set(updateCommentActionAtom, { ...existingComment, is_resolved: isResolved });

      // Changing the resolution could affect which comment is the most recent unresolved, so re-fetch it
      if (existingComment.comp_id) {
        set(mostRecentTextItemCommentFamilyAtom(existingComment.comp_id), REFRESH_SILENTLY);

        // Update the comment_threads array in the text item, which affects the comment count badge
        const textItem = await get(textItemFamilyAtom(existingComment.comp_id));
        // If the comment got resolved, remove it from the text item's comment_threads array
        if (isResolved && textItem.comment_threads.includes(commentThreadId)) {
          set(textItemFamilyAtom(existingComment.comp_id), {
            ...textItem,
            comment_threads: textItem.comment_threads.filter((id) => id !== commentThreadId),
          });
        }
        // If the comment got unresolved, add it to the text item's comment_threads array
        else if (!isResolved && !textItem.comment_threads.includes(commentThreadId)) {
          set(textItemFamilyAtom(existingComment.comp_id), {
            ...textItem,
            comment_threads: [commentThreadId, ...textItem.comment_threads],
          });
        }
      }
    }
  }
);

/**
 * An action to resolve or unresolve a comment thread.
 * This will also trigger a refresh of the most recent comment for the text item, since it may have changed as a result of this action.
 */
export const toggleCommentResolvedActionAtom = atom(null, async (get, set, existingComment: ICommentThread) => {
  const { _id: commentThreadId, is_resolved, comp_id: textItemId } = existingComment;
  const isResolving = !is_resolved;

  const [request] = httpComments.resolveThread({
    commentThreadId: commentThreadId,
    is_resolved: isResolving,
    from: "web_app",
  });

  const response = await request;
  const updatedCommentThread = response.data;
  set(handleCommentResolutionUpdatedActionAtom, {
    commentThreadId: updatedCommentThread._id,
    isResolved: updatedCommentThread.is_resolved,
  });
});

/**
 * An action to post a new comment (start a new thread)
 */
export const postCommentActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      textItemId,
      commentText,
      mentionedUserIds,
    }: { textItemId: string; commentText: string; mentionedUserIds: string[] }
  ) => {
    const projectId = get(projectIdAtom);
    if (!projectId) return; // Should never happen

    const [request] = httpComments.createCommentThread({
      projectId,
      commentText,
      mentionedUserIds,
      textItemId,
      from: "web_app",
    });

    const response = await request;
    const newCommentThread = response.data;
    set(handleCommentCreatedActionAtom, newCommentThread);
  }
);

/**
 *  An action for posting a reply to a comment thread.
 */
export const postCommentReplyActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      commentThreadId,
      commentText,
      mentionedUserIds,
    }: { commentThreadId: string; commentText: string; mentionedUserIds: string[] }
  ) => {
    const [request] = httpComments.postToThread({
      commentThreadId,
      text: commentText,
      mentionedUserIds,
      from: "web_app",
    });

    const response = await request;
    const newReplyCommentThread = response.data;
    set(handleCommentUpdatedActionAtom, newReplyCommentThread);
  }
);
