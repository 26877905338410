import CommandBar from "@/components/CommandBar/CommandBar";
import WebsocketSubscriber from "@/components/WebsocketSubscriber/WebsocketSubscriber";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { BillingProvider } from "@/store/billingContext";
import { NotificationProvider } from "@/store/notificationContext";
import { WorkspaceProvider } from "@/store/workspaceContext";
import Developers from "@/views/Developers";
import HomePage from "@/views/Home";
import Integrations from "@/views/Integrations";
import ProjectPage from "@/views/NS/ProjectNS";
import Settings from "@/views/Settings";
import React, { Suspense } from "react";
import Container from "react-bootstrap/Container";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { routes } from "../../defs";
import { FlaggedFeaturesProvider } from "../../flagged-feature";
import { navRoutes } from "../../nav-routes";
import { UnsavedChangesProvider } from "../../store/unsavedChangesContext";
import { WebappPermissionProvider as UserPermissionProvider } from "../../store/webappPermissionContext";
import history from "../../utils/history";
import Components from "../../views/Components";
import Onboarding from "../../views/Onboarding";
import Project from "../../views/Project";
import Search from "../../views/Search";
import Variables from "../../views/Variables";
import Variants from "../../views/Variants";
import ImpersonateBanner from "../ImpersonateBanner/ImpersonateBanner";
import Navbar from "../Navbar/Navbar_NEW";
import SlackAuthHandler from "../SlackAuthHandler";
import TrustedWorkspaceDebugPanel from "../TrustedWorkspaceDebugPanel/TrustedWorkspaceDebugPanel";
import AuthCallback from "../auth-callback/auth-callback";
import JoinWorkspace from "../join-workspace/join-workspace";
import FigmaAuthScreen from "../login/FigmaAuthScreen";
import FolderPermissionFromParams from "../projects/FolderPermissionFromParams";
import Projects from "../projects/projects";
import PrivateRoute from "../routes/PrivateRoute";
import { SampleProjectRedirect } from "../sample-project-redirect";
import Logout from "./Logout";
import PageTitle from "./PageTitle";
import style from "./style.module.css";

const PAGE_TITLE_DEFAULT = "Ditto • Home";
const PAGE_TITLE_MAP = {
  [navRoutes.developers.path]: "Developers",
  [navRoutes.search.path]: "Search",
  [navRoutes.components.path]: "Components",
  [navRoutes.projects.path]: "Projects",
  [navRoutes.variants.path]: "Variants",
  [navRoutes.variables.path]: "Variables",
  [navRoutes.integrations.path]: "Integrations",
  [navRoutes.settings.path]: "Account Settings",
};

const App = () => {
  const authState = useAuthenticatedAuth();

  return (
    <>
      <WorkspaceProvider>
        <Container fluid className={style.container_fluid}>
          <ImpersonateBanner />
          <Router history={history}>
            <PageTitle titleDefault={PAGE_TITLE_DEFAULT} titleMap={PAGE_TITLE_MAP} />
            <UserPermissionProvider>
              <FlaggedFeaturesProvider>
                <UnsavedChangesProvider>
                  {process.env.IS_LOCAL === "true" && <TrustedWorkspaceDebugPanel />}
                  <NotificationProvider>
                    <BillingProvider>
                      <Switch>
                        <Redirect from={"/account/connections"} to={"/integrations"} />
                        <Redirect from={"/account/devtools"} to={"/developers"} />
                        <PrivateRoute exact path={["/", "/home"]}>
                          <Navbar />
                          <HomePage />
                        </PrivateRoute>
                        <PrivateRoute exact path={[navRoutes.projects.path, routes.nonNavRoutes.folder.path]}>
                          <Navbar />
                          <FolderPermissionFromParams>
                            <Projects />
                          </FolderPermissionFromParams>
                        </PrivateRoute>
                        <PrivateRoute exact path={navRoutes.projects.path + "/sample"}>
                          <Navbar />
                          <SampleProjectRedirect />
                        </PrivateRoute>
                        <PrivateRoute path={navRoutes.search.path}>
                          <Navbar />
                          <Search />
                        </PrivateRoute>
                        {/*
                          The legacy URL of the component library was `/components/all`; this redirect handles
                          the case where a user has the old URL saved, since otherwise "all" will be queried as if
                          it were a component id and will result in an error.
                        */}
                        <Redirect from={navRoutes.components.path + "/all"} to={navRoutes.components.path} />
                        <PrivateRoute
                          exact
                          path={[
                            navRoutes.components.path,
                            navRoutes.components.path + "/:id",
                            navRoutes.components.path + "/folder/:folder_id",
                            navRoutes.components.path + "/folder/:folder_id/:id",
                          ]}
                        >
                          <Navbar />
                          <Components />
                        </PrivateRoute>
                        <PrivateRoute path={navRoutes.settings.path}>
                          <Navbar />
                          <Settings />
                        </PrivateRoute>
                        <Redirect from={routes.nonNavRoutes.doc.path} to={routes.nonNavRoutes.project.path} />
                        <PrivateRoute
                          exact
                          path={[
                            routes.nonNavRoutes.project.path,
                            routes.nonNavRoutes.project.path + "/page/:pageId",
                            routes.nonNavRoutes.project.path + "/page/:pageId/:textItemId",
                            routes.nonNavRoutes.project.path + "/page/:pageId/:textItemId/:commentThreadId",
                            routes.nonNavRoutes.branchProject.path,
                            routes.nonNavRoutes.branchProject.path + "/page/:pageId",
                            routes.nonNavRoutes.branchProject.path + "/page/:pageId/:textItemId",
                          ]}
                        >
                          <Navbar />
                          <Project />
                        </PrivateRoute>
                        {/* North Star */}
                        <Redirect from={`/projectsNS/:id`} to={routes.nonNavRoutes.projectBeta.path} />
                        <PrivateRoute exact path={[routes.nonNavRoutes.projectBeta.path]}>
                          <Navbar />
                          <Suspense fallback={<div>Loading...</div>}>
                            <ProjectPage />
                          </Suspense>
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={[
                            navRoutes.variants.path,
                            navRoutes.variants.path + "/folder/:folder_id",
                            routes.nonNavRoutes.variant.path,
                          ]}
                        >
                          <Navbar />
                          <Variants />
                        </PrivateRoute>
                        <PrivateRoute
                          path={[
                            navRoutes.variables.path + "/folder/:folder_id/:id",
                            navRoutes.variables.path + "/folder/:folder_id",
                            routes.nonNavRoutes.variable.path,
                          ]}
                        >
                          <Navbar />
                          <Variables />
                        </PrivateRoute>
                        <PrivateRoute path={navRoutes.variables.path}>
                          <Navbar />
                          <Variables />
                        </PrivateRoute>
                        <PrivateRoute path={routes.nonNavRoutes.onboarding.path}>
                          <Onboarding />
                        </PrivateRoute>
                        <PrivateRoute exact path={routes.nonNavRoutes.loginFigma.path}>
                          <FigmaAuthScreen />
                        </PrivateRoute>
                        <PrivateRoute path="/developers">
                          <Navbar />
                          <Developers />
                        </PrivateRoute>
                        <PrivateRoute path={"/integrations/slackRedirect"}>
                          <Navbar />
                          <SlackAuthHandler
                            initializeConnection={true}
                            redirectUri={`https://${window.location.host}/integrations/slackRedirect`}
                          />
                        </PrivateRoute>
                        <PrivateRoute path="/integrations">
                          <Navbar />
                          <Integrations />
                        </PrivateRoute>
                        <Route path={["/slackAuth/:docId", "/slackAuth"]}>
                          <SlackAuthHandler
                            initializeConnection={false}
                            redirectUri={`https://${window.location.host}${window.location.pathname}`}
                          />
                        </Route>
                        <Route path={routes.nonNavRoutes.logout.path}>
                          <Logout authState={authState} />
                        </Route>
                        {/* Commoon Routes */}
                        <Route
                          exact
                          path={[
                            routes.nonNavRoutes.join_workspace.path,
                            routes.nonNavRoutes.legacy_join_workspace.path,
                          ]}
                        >
                          <JoinWorkspace authState={authState} />
                        </Route>
                        <Route exact path={routes.nonNavRoutes.authCallback.path}>
                          <AuthCallback />
                        </Route>
                        <Route exact path={routes.nonNavRoutes.logout.path}>
                          <Logout authState={authState} />
                        </Route>
                        {/* Catch All Redirect */}
                        <Route path="*">
                          <Redirect to={"/home"} />
                        </Route>
                      </Switch>
                      <CommandBar />
                    </BillingProvider>
                  </NotificationProvider>
                </UnsavedChangesProvider>
              </FlaggedFeaturesProvider>
            </UserPermissionProvider>
          </Router>
        </Container>
      </WorkspaceProvider>
      <WebsocketSubscriber />
    </>
  );
};

export default App;
