import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import { IFPopulatedNotification } from "@shared/types/Notification";
import { Ditto } from "ditto-react";
import React, { useLayoutEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Pagination from "../pagination";
import Panel from "../panel";
import DiscussionRow from "./DiscussionRow";
import style from "./style.module.css";

export interface IDiscussionPanelProps {
  loading?: boolean;
  notifications: IFPopulatedNotification[];
  page: number;
  pageSize: number;
  total: number;
  onPaginateBack?: () => void;
  onPaginateForward?: () => void;
  onMarkAsRead: (notification: IFPopulatedNotification) => void;
  onReply: (notification: IFPopulatedNotification) => void;
}

export default function DiscussionPanel(props: IDiscussionPanelProps) {
  // If there's more than one page worth of notifications, we don't want the height of the panel
  // to change as the user paginates.
  const useFixedHeight = props.total > props.pageSize;
  const listRef = React.useRef<HTMLDivElement>(null);
  useLayoutEffect(
    function getListHeightOnMount() {
      if (useFixedHeight && listRef.current) {
        listRef.current.style.height = `${listRef.current.clientHeight}px`;
      }
    },
    [props.total]
  );

  return (
    <Panel icon={MarkChatUnreadIcon} count={props.total} titleDittoComponentId="discussion-panel-title">
      <div className={style.notificationList} ref={listRef}>
        {props.notifications.map((notification, i) => (
          <div className={style.rowWrapper} key={notification._id}>
            <DiscussionRow
              notification={notification}
              onMarkAsRead={() => props.onMarkAsRead(notification)}
              onReply={() => props.onReply(notification)}
            />
            {i < props.notifications.length - 1 && <div className={style.divider} />}
          </div>
        ))}
      </div>

      {!props.loading && props.notifications.length === 0 && (
        <div className={style.emptyState}>
          <div className={style.emptyStateText}>
            <Ditto componentId="discussion-empty-state" />
          </div>
        </div>
      )}

      {props.loading && (
        <div className={style.skeletonContainer}>
          <div className={style.skeleton}>
            <Skeleton height={20} containerClassName={style.skeletonTitle} />
            <Skeleton height={14} width={"50%"} />
          </div>
          <div className={style.skeleton}>
            <Skeleton height={20} containerClassName={style.skeletonTitle} />
            <Skeleton height={14} width={"50%"} />
          </div>
          <div className={style.skeleton}>
            <Skeleton height={20} containerClassName={style.skeletonTitle} />
            <Skeleton height={14} width={"50%"} />
          </div>
        </div>
      )}
      {props.total > props.pageSize && (
        <Pagination
          page={props.page}
          pageSize={props.pageSize}
          total={props.total}
          onPaginateBack={props.onPaginateBack}
          onPaginateForward={props.onPaginateForward}
        />
      )}
    </Panel>
  );
}
