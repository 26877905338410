import { userAtom } from "@/stores/Auth";
import {
  commentFamilyAtom,
  postCommentReplyActionAtom,
  toggleCommentResolvedActionAtom,
  updateCommentEditsAtom,
} from "@/stores/Comments";
import { isCommentSelectedAtom, selectTextItemsThatExistActionAtom } from "@/stores/ProjectSelection";
import { deferredUsersAtom, usersByUserIdAtom } from "@/stores/Workspace";
import CommentThread from "@ds/organisms/CommentThread";
import { IUser } from "@shared/types/User";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import React, { Suspense, useCallback } from "react";
import Skeleton from "react-loading-skeleton";

interface IProps {
  commentThreadId: string;
  showGoToText?: boolean;
  className?: string;
}

export function CommentThreadWrapper({ commentThreadId, showGoToText = false, className }: IProps) {
  const toggleResolvedAction = useSetAtom(toggleCommentResolvedActionAtom);
  const postReplyAction = useSetAtom(postCommentReplyActionAtom);
  const commentThread = useAtomValue(commentFamilyAtom(commentThreadId));
  const currentUser = useAtomValue(userAtom, { store: getDefaultStore() });
  const users = useAtomValue(deferredUsersAtom);
  const usersByUserId = useAtomValue(usersByUserIdAtom);
  const selectTextItemsAction = useSetAtom(selectTextItemsThatExistActionAtom);
  const isCommentSelected = useAtomValue(isCommentSelectedAtom(commentThreadId));
  const updateIsEditingAtom = useSetAtom(updateCommentEditsAtom);

  const selectTextItem = () => {
    const textItemIds = commentThread?.comp_id ? [commentThread.comp_id] : [];
    selectTextItemsAction(textItemIds);
  };

  const handlePostReply = async (commentText: string, mentionedUsers: IUser[], resetEditorState: () => void) => {
    await postReplyAction({
      commentThreadId,
      commentText,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
    });

    resetEditorState();
  };

  const handleResolve = async () => {
    toggleResolvedAction(commentThread);
  };

  const handleTypeReply = useCallback(
    (contentToSave: string, _mentionedUsersInfo: IUser[]) => {
      updateIsEditingAtom({ commentThreadId, hasUnsavedChanges: !!contentToSave });
    },
    [commentThreadId, updateIsEditingAtom]
  );

  if (!commentThread || !currentUser) {
    return <></>;
  }

  return (
    <CommentThread
      commentThread={commentThread}
      className={className}
      user={currentUser}
      mentionableUsers={users}
      mentionableUsersByUserId={usersByUserId}
      onResolve={handleResolve}
      onTypeReply={handleTypeReply}
      postReply={handlePostReply}
      goToTextItem={showGoToText ? selectTextItem : undefined}
      isSelected={isCommentSelected || false}
    />
  );
}

export function CommentThreadWrapperWithSuspense(props: IProps) {
  return (
    <Suspense fallback={<Skeleton height={61} baseColor="white" />}>
      <CommentThreadWrapper {...props} />
    </Suspense>
  );
}

export default CommentThreadWrapperWithSuspense;
