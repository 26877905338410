import useAutoScroll from "@/hooks/useAutoScroll";
import { reorderTextItemsActionAtom } from "@/stores/Editing";
import { INavTextItem, textItemFamilyAtom } from "@/stores/Project";
import {
  draggableItemsForTextItemAtom,
  onTextItemClickActionAtomFamily,
  selectedBlockIdAtom,
  selectionTypeAtom,
  textItemIsSelectedAtom,
} from "@/stores/ProjectSelection";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import EditableTextArea from "@ds/atoms/EditableTextArea";
import NavItem from "@ds/molecules/NavigatorRow";
import { ITipTapContentElement, ITipTapRichText } from "@shared/types/TextItem";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { forwardRef, useCallback, useMemo } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { leftSidebarScrollRefAtom } from ".";
import { textItemListScrollRefAtom } from "../../TextItemList";
import style from "./style.module.css";

function TextNavItem(props: { item: INavTextItem }) {
  const textItem = useAtomValue(textItemFamilyAtom(props.item._id));
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.item._id));
  const isSelected = useAtomValue(textItemIsSelectedAtom(props.item._id));

  const itemType = useMemo(() => (textItem.blockId ? "block-child" : "default"), [textItem.blockId]);
  const parentBlockIsSelected = useMemo(
    () => Boolean(textItem.blockId && selectedBlockId === textItem.blockId),
    [textItem.blockId, selectedBlockId]
  );

  const textItemRichTextNewLinesRemoved = useMemo(() => {
    const newRichText: ITipTapRichText = {
      type: textItem.rich_text.type,
      content: [],
    };

    const combinedContent = [] as ITipTapContentElement[];
    // Iterate over all content in the document
    for (const paragraph of textItem.rich_text.content) {
      if (paragraph.type === "paragraph" && paragraph.content) {
        // Append the paragraph's content to the combinedContent array
        combinedContent.push(...paragraph.content);
      }
    }
    // Create the new structure with a single paragraph containing all content
    newRichText.content = [{ type: "paragraph", content: combinedContent }];
    return newRichText;
  }, [textItem.rich_text]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onTextItemClick({ richText: textItem.rich_text, e });
    },
    [onTextItemClick, textItem.rich_text]
  );

  return (
    <NavItem
      key={textItem._id}
      className={style.navItem}
      type={itemType}
      pressed={isSelected}
      parentSelected={parentBlockIsSelected}
      onClick={onClick}
    >
      <EditableTextArea
        className={style.textItemText}
        content={textItemRichTextNewLinesRemoved}
        editable={false}
      ></EditableTextArea>
    </NavItem>
  );
}

const TextNavItemDragWrapper = forwardRef(function TextNavItemDragWrapper(
  props: {
    children: React.ReactNode;
    textItemId: string;
  },
  ref
) {
  const getDraggableItems = useAtomCallback(
    useCallback((get) => get(draggableItemsForTextItemAtom(props.textItemId)), [props.textItemId])
  );
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const textItem = useAtomValue(textItemFamilyAtom(props.textItemId));
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const leftSidebarScrollContainer = useAtomValue(leftSidebarScrollRefAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.textItemId));
  const selectedType = useAtomValue(selectionTypeAtom);

  function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: textItem.blockId,
        before: dragLocation === "above" ? props.textItemId : undefined,
        after: dragLocation === "below" ? props.textItemId : undefined,
      },
    ]);
  }

  const scrollProps = useAutoScroll(textItemScrollContainer, leftSidebarScrollContainer);

  function onDragStart(e: DragStartEvent) {
    if (props.textItemId) {
      onTextItemClick({ richText: textItem.rich_text, e });
    }
  }

  return (
    <DragAndDroppable
      className={style.draggableNavItem}
      ref={ref}
      getDraggableItems={getDraggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      onDrop={handleDrop}
      onDragStart={onDragStart}
      {...scrollProps}
    >
      {(dragAndDropProps) => {
        const isDropTarget = dragAndDropProps.isDropTarget && selectedType === "text";

        const isDroppingAbove = isDropTarget && dragAndDropProps.dragLocation === "above";
        const isDroppingBelow = isDropTarget && dragAndDropProps.dragLocation === "below";

        return (
          <>
            {isDroppingAbove && <div className={classNames(style.dropIndicator)} />}
            {props.children}
            {isDroppingBelow && <div className={classNames(style.dropIndicator, style.textItemDrop)} />}
          </>
        );
      }}
    </DragAndDroppable>
  );
});

const TextNavItemWithDrag = forwardRef(function TextNavItemWithDrag(props: { item: INavTextItem }, ref) {
  return (
    <TextNavItemDragWrapper textItemId={props.item._id} ref={ref}>
      <TextNavItem {...props} />
    </TextNavItemDragWrapper>
  );
});

export default TextNavItemWithDrag;
