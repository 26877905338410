import { flattenedProjectItemsAtom, INavMessageItem } from "@/stores/Project";
import NavItem from "@ds/molecules/NavigatorRow";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import style from "./style.module.css";

export function LoadingNavItem() {
  return <NavItem state="loading" pressed={false} />;
}

export function MessageNavItem(props: { item: INavMessageItem }) {
  return (
    <div className={classNames(style.hiddenResultsMessageNavItem, style.hiddenResultsChildMessageNavItem)}>
      {props.item.message}
    </div>
  );
}

export function HiddenProjectItemsNavItem() {
  const { hiddenProjectItemsMessage } = useAtomValue(flattenedProjectItemsAtom);

  if (!hiddenProjectItemsMessage) {
    return null;
  }

  return <div className={style.hiddenResultsMessageNavItem}>{hiddenProjectItemsMessage}</div>;
}
