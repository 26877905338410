import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

function LeftSidebarContentSkeleton() {
  return (
    <>
      <div className={style.navItemSkeleton}>
        <Skeleton baseColor="var(--bg-minimal)" width={12} height={12} />
        <Skeleton baseColor="var(--bg-minimal)" width={160} height={17} />
      </div>
      <div className={style.navItemSkeleton}>
        <div className={style.icon} style={{ opacity: 0 }} />
        <Skeleton baseColor="var(--bg-minimal)" width={60} height={17} />
      </div>
      <div className={style.navItemSkeleton}>
        <div className={style.icon} style={{ opacity: 0 }} />
        <Skeleton baseColor="var(--bg-minimal)" width={110} height={17} />
      </div>
      <div className={style.navItemSkeleton}>
        <div className={style.icon} style={{ opacity: 0 }} />
        <Skeleton baseColor="var(--bg-minimal)" width={160} height={17} />
      </div>
    </>
  );
}

export default LeftSidebarContentSkeleton;
