import classNames from "classnames";
import React, { forwardRef } from "react";
import { useNativeProps } from "../../useNativeProps";
import Text from "../Text";
import style from "./index.module.css";

interface IProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const DropdownMenuItem = forwardRef<HTMLDivElement, IProps>(function DropdownMenuItem(props: IProps, ref) {
  const nativeProps = useNativeProps<HTMLDivElement, IProps>(props, { disabled: true });

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  }

  return (
    <div
      {...nativeProps}
      style={props.style}
      className={classNames(
        style.DropdownMenuItemWrapper,
        {
          [style.clickable]: !!props.onClick,
          [style.disabled]: props.disabled,
        },
        props.className
      )}
      data-testid="dropdown-menu-item"
      onClick={handleClick}
      ref={ref}
    >
      <Text className={style.itemText}>{props.children}</Text>
    </div>
  );
});

export default DropdownMenuItem;
