import CallSplitIcon from "@mui/icons-material/CallSplit";
import classNames from "classnames";
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  memo,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isDiffRichText } from "../../../../shared/lib/text";
import { RichTextInputProps, RichTextInputRef } from "../../../../shared/types/RichText";
import {
  ActualComponentStatus,
  ITextItemVariable,
  ITextItemVariableRichValue,
  ITextItemVariantUpdate,
  ITipTapRichText,
} from "../../../../shared/types/TextItem";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import DialogueModal from "../../molecules/DialogueModal";
import DropdownMenu from "../../molecules/DropdownMenu";
import LabelWrapper from "../../molecules/LabelWrapper";
import StatusSelect from "../StatusSelect";
import { FlagIcon } from "../TextItemMetadata/icons";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  variantId: string;
  variantStatus?: ActualComponentStatus;
  variantRichText: ITipTapRichText;
  variantText: string;
  variantVariables: ITextItemVariable[];
  variantName: string;

  /**
   * Placeholder text for variant text input. Should be the base item's text.
   */
  placeholder?: string;

  /**
   * Style flag for whether variant metadata is inline (e.g. in edit panel: removing extra padding, top border)
   */
  inline?: boolean;

  /**
   * Callback function for updating the text item's variant.
   * @param update - Update object containing the variantId and the properties to update.
   */
  onSave: (update: ITextItemVariantUpdate) => void;

  /**
   * Callback function for when the TextItemVariant form has changes.
   * @param hasChanges - Whether the form has changes.
   */
  onHasChanges?: (hasChanges: boolean) => void;

  /**
   * Callback function for deleting the variant.
   */
  onDeleteVariant: (variantId: string) => void;

  /**
   * Wrapper for our TipTap RichTextInput component.
   */
  RichTextInput: ForwardRefExoticComponent<RichTextInputProps & RefAttributes<RichTextInputRef>>;
}

export interface ITextItemVariantRef {
  resetFormState: () => void;
}

const TextItemVariant = memo(
  forwardRef<ITextItemVariantRef, IProps>(function TextItemVariant(props: IProps, ref) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [draftStatus, setDraftStatus] = useState<ActualComponentStatus>(props.variantStatus ?? "NONE");
    const [draftRichText, setDraftRichText] = useState<ITipTapRichText>(props.variantRichText);
    const richTextInputRef = useRef<RichTextInputRef | null>(null);

    const { onHasChanges, onDeleteVariant, onSave } = props;

    // Refresh stale state when variant id prop changes
    useLayoutEffect(() => {
      setDraftStatus(props.variantStatus ?? "NONE");
      setDraftRichText(props.variantRichText);
    }, [props.variantId, props.variantStatus, props.variantRichText]);

    // For change detection, compare draft state against props
    const changes: ITextItemVariantUpdate | null = useMemo(() => {
      const hasStatusChange = draftStatus !== props.variantStatus;
      const hasRichTextChange = isDiffRichText(draftRichText, props.variantRichText);

      // If we don't have any changes in form state, return null
      if (!hasStatusChange && !hasRichTextChange) return null;

      return {
        variantId: props.variantId,
        ...(hasStatusChange ? { status: draftStatus } : {}),
        ...(hasRichTextChange ? { richText: draftRichText } : {}),
      };
    }, [draftStatus, draftRichText, props.variantId, props.variantStatus, props.variantRichText]);

    useEffect(() => {
      if (onHasChanges) onHasChanges(changes !== null);
    }, [changes, onHasChanges]);

    const initialVariableRichValue: ITextItemVariableRichValue = useMemo(() => {
      return {
        rich_text: props.variantRichText,
        plurals: [],
        variables: props.variantVariables,
        text: props.variantText,
        characterLimit: null,
      };
    }, [props.variantRichText, props.variantVariables, props.variantText]);

    const variantName = useMemo(() => `${props.variantName} variant`, [props.variantName]);

    const resetFormState = useCallback(
      function _resetFormState() {
        richTextInputRef.current?.reset();
        setDraftStatus(props.variantStatus ?? "NONE");
        setDraftRichText(props.variantRichText);
      },
      [props.variantStatus, props.variantRichText]
    );

    // Expose reset function for the TextItemVariant component to its parents via ref
    useImperativeHandle(
      ref,
      () => ({
        resetFormState,
      }),
      [resetFormState]
    );

    const handleSave = useCallback(
      function _handleSave() {
        if (!changes) return;
        /* TODO: Add support for complex inputs: https://linear.app/dittowords/issue/DIT-8363/support-complex-metadata-on-variants */
        onSave(changes);
      },
      [changes, onSave]
    );

    const handleRichTextChange = useCallback(
      function _handleRichTextChange(newRichText: ITipTapRichText) {
        setDraftRichText(newRichText);
      },
      [setDraftRichText]
    );

    const handleSetCharacterLimit = useCallback(() => {}, []);
    const handleSetPlurals = useCallback(() => {}, []);

    const handleDeleteAttempt = useCallback(() => {
      setShowDeleteModal(true);
    }, []);

    const handleOnDeleteVariant = useCallback(
      function _onDeleteVariant() {
        onDeleteVariant(props.variantId);
      },
      [onDeleteVariant, props.variantId]
    );

    return (
      <>
        <div
          style={props.style}
          className={classNames(
            style.TextItemVariantWrapper,
            {
              [style.inline]: props.inline,
            },
            props.className
          )}
          data-testid="text-item-variant"
        >
          <div className={style.TextItemVariantMetadata}>
            <div className={style.TextItemVariantHeader}>
              <Icon Icon={<CallSplitIcon />} color="secondary" size="xxs" className={style.rotateRight} />
              <Text className={style.variantTitle} size="small" weight="strong">
                {variantName}
              </Text>
              <DropdownMenu
                RDropdownMenuContentProps={{
                  align: "end",
                }}
                items={[
                  {
                    type: "option",
                    text: "Delete...",
                    onClick: handleDeleteAttempt,
                  },
                ]}
              />
            </div>
            <props.RichTextInput
              ref={richTextInputRef}
              setBaseText={handleRichTextChange}
              setPlurals={handleSetPlurals}
              setCharacterLimit={handleSetCharacterLimit}
              initialVariableRichValue={initialVariableRichValue}
              characterLimit={null}
              pluralsDisabled={true}
              characterLimitDisabled={true}
              placeholder={props.placeholder}
              emptyEditorClass="emptyEditor"
            />
          </div>

          {/* Status select */}
          <LabelWrapper
            className={style.labelWrapper}
            labelFlexStart
            labelHeight={32}
            label="Status"
            leadingIcon={<FlagIcon />}
          >
            <StatusSelectWrapper status={draftStatus} setStatus={setDraftStatus} />
          </LabelWrapper>

          {/* CTA buttons */}
          {changes && (
            <div className={style.CTAButtons}>
              <Button size="small" level="secondary" onClick={resetFormState}>
                Cancel
              </Button>
              <Button size="small" level="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          )}
        </div>
        <DialogueModal
          open={showDeleteModal}
          onOpenChange={setShowDeleteModal}
          onAction={handleOnDeleteVariant}
          headline={`Delete ${variantName} variant of this text item?`}
          content="This can’t be undone."
          actionText="Delete"
          type="danger"
        />
      </>
    );
  }),
  (prevProps, nextProps) => {
    // Compare diff in rich text w/custom comparison
    return (
      prevProps.variantId === nextProps.variantId &&
      prevProps.variantStatus === nextProps.variantStatus &&
      !isDiffRichText(prevProps.variantRichText, nextProps.variantRichText)
    );
  }
);

const StatusSelectWrapper = memo(function StatusSelectWrapper(props: {
  status: ActualComponentStatus;
  setStatus: (status: ActualComponentStatus) => void;
}) {
  return (
    <div className={style.inputWidthWrapper}>
      <StatusSelect status={props.status} setStatus={props.setStatus} />
    </div>
  );
});

export default TextItemVariant;
