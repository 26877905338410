import { useAtomRef } from "@/hooks/useAtomRef";
import useSegment from "@/hooks/useSegment";
import {
  addNewBlockActionAtom,
  addNewTextItemActionAtom,
  isEditingNewContentAtom,
  isInlineEditingNewTextAtom,
  newBlockAtom,
  reorderTextItemsActionAtom,
  textItemListRefAtom,
} from "@/stores/Editing";
import {
  deferredProjectBlocksAtom,
  isEmptyProjectAtom,
  nonBlockTextItemsAtom,
  projectBlocksCountAtom,
  projectTextItemsCountAtom,
} from "@/stores/Project";
import { designPreviewToggledAtom, isFilteringAtom, isSearchingAtom } from "@/stores/ProjectFiltering";
import {
  derivedSelectedTextItemsAtom,
  onTextItemKeyDownActionAtom,
  selectedItemsAtom,
  Selection,
} from "@/stores/ProjectSelection";
import NewBlockWrapper from "@/views/NS/ProjectNS/components/NewBlockWrapper";
import Button from "@ds/atoms/Button";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import Text from "@ds/atoms/Text";
import Scrollbar from "@ds/molecules/Scrollbar";
import AddIcon from "@mui/icons-material/Add";
import * as SegmentEvents from "@shared/segment-event-names";
import { IFDittoProjectData, IMoveTextItemsAction } from "@shared/types/http/DittoProject";
import classNames from "classnames";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { last } from "lodash";
import { memo, Suspense, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { z } from "zod";
import NoSearchResults from "../NoSearchResults";
import TextItemBlockWrapper from "../TextItemBlockWrapper";
import style from "./style.module.css";

const clearSelection = (e: React.MouseEvent<HTMLDivElement>, setSelectedItems: (items: Selection) => void) => {
  if (e.target === e.currentTarget) {
    setSelectedItems({ type: "none" });
  }
};

export const textItemListScrollRefAtom = atom<HTMLDivElement | null>(null);

function TextItemList() {
  const designPreviewsActive = useAtomValue(designPreviewToggledAtom);
  const onTextItemKeyDownAction = useSetAtom(onTextItemKeyDownActionAtom);
  const setSelectedItems = useSetAtom(selectedItemsAtom);
  const scrollContentRef = useAtomRef(textItemListScrollRefAtom);
  const containerRef = useAtomRef(textItemListRefAtom);

  useEffect(
    function focusOnMount() {
      // Focus the container when it mount to enable keyboard navigation
      containerRef.current?.focus();
    },
    [containerRef]
  );

  return (
    <div
      className={classNames(style.main, {
        [style.designPreviewsActive]: designPreviewsActive,
      })}
      tabIndex={0}
      onKeyDown={onTextItemKeyDownAction}
      ref={containerRef}
    >
      <Scrollbar
        className={style.scrollWrapper}
        viewPortOnClick={(e) => clearSelection(e, setSelectedItems)}
        {...{ scrollContentRef }}
      >
        <Suspense fallback={<TextItemListLoadingSkeleton />}>
          <TextItemListScrollContent />
          <SelectedTextItemsWatcher />
        </Suspense>
      </Scrollbar>
    </div>
  );
}

// This component is isolated from the rest of the tree to prevent rerenders going to children
const SelectedTextItemsWatcher = () => {
  const derivedSelectedTextItems = useAtomValue(derivedSelectedTextItemsAtom);
  const { track } = useSegment();

  useEffect(
    function segmentTrackMultiSelect() {
      if (derivedSelectedTextItems.length > 1) {
        track({
          event: SegmentEvents.MULTI_TEXT_SELECTED,
          properties: { num_items_selected: derivedSelectedTextItems.length },
        });
      }
    },
    [derivedSelectedTextItems, track]
  );

  return <></>;
};

const TextItemListScrollContent = memo(function TextItemListScrollContent() {
  const projectBlocks = useAtomValue(deferredProjectBlocksAtom);
  const numTextItems = useAtomValue(projectTextItemsCountAtom);
  const numBlocks = useAtomValue(projectBlocksCountAtom);
  const addNewTextItemAction = useSetAtom(addNewTextItemActionAtom);
  const addNewBlockAction = useSetAtom(addNewBlockActionAtom);
  const isEditingNewContent = useAtomValue(isEditingNewContentAtom);
  const isEditingNewText = useAtomValue(isInlineEditingNewTextAtom);
  const newBlock = useAtomValue(newBlockAtom);
  const nonBlockTextItems = useAtomValue(nonBlockTextItemsAtom);
  const isSearching = useAtomValue(isSearchingAtom);
  const isFiltering = useAtomValue(isFilteringAtom);
  const isEmptyProject = useAtomValue(isEmptyProjectAtom);
  const setSelectedItems = useSetAtom(selectedItemsAtom);
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);

  const hasNoVisibleItems = numTextItems === 0 && numBlocks < 2;

  if (hasNoVisibleItems && (isSearching || (!isEmptyProject && isFiltering))) {
    return <NoSearchResults />;
  }

  return (
    <>
      <div className={style.textItemListContainer} onClick={(e) => clearSelection(e, setSelectedItems)}>
        {hasNoVisibleItems && !isEditingNewText && !newBlock && (
          <Button
            level="subtle"
            className={style.emptyProjectButton}
            size="small"
            iconSize="xs"
            leadingIcon={<AddIcon />}
            iconColor="secondary"
            onClick={() => addNewTextItemAction()}
          >
            <Text color="secondary">Add your first text to this project</Text>
          </Button>
        )}

        {(!hasNoVisibleItems || isEditingNewText || newBlock) && (
          <div className={style.blocks}>
            {projectBlocks.map((block, index) => (
              <TextItemBlockWrapperWithRowSeparator
                key={`${index}`}
                textItemBlock={block}
                newBlock={newBlock}
                numBlocks={numBlocks}
                nonBlockTextItems={nonBlockTextItems}
                setSelectedItems={setSelectedItems}
                isEditingNewContent={isEditingNewContent}
                addNewBlockAction={addNewBlockAction}
                addNewTextItemAction={addNewTextItemAction}
                index={index}
              />
            ))}
          </div>
        )}
      </div>
      <TextItemBlockFooter
        setSelectedItems={setSelectedItems}
        isEditingNewContent={isEditingNewContent}
        addNewBlockAction={addNewBlockAction}
        addNewTextItemAction={addNewTextItemAction}
        nonBlockTextItems={nonBlockTextItems}
        numBlocks={numBlocks}
        reorderTextItemsAction={reorderTextItemsAction}
      />
    </>
  );
});

function TextItemBlockFooter({
  setSelectedItems,
  isEditingNewContent,
  addNewBlockAction,
  addNewTextItemAction,
  nonBlockTextItems,
  numBlocks,
  reorderTextItemsAction,
}: {
  setSelectedItems: (items: Selection) => void;
  isEditingNewContent: boolean;
  addNewBlockAction: (update?: { name?: string; atIndex?: number } | undefined) => void;
  addNewTextItemAction: (args?: { blockId?: string | null; location?: "start" | "end" }) => void;
  nonBlockTextItems: { _id: string; sortKey: string }[];
  numBlocks: number;
  reorderTextItemsAction: (actions: IMoveTextItemsAction[]) => Promise<void>;
}) {
  function handleDrop(textItemIds: string[]) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: null,
        after: last(nonBlockTextItems)?._id,
      },
    ]);
  }

  return (
    <DragAndDroppable
      getDraggableItems={() => []}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      isDragDisabled={true}
      onDrop={handleDrop}
    >
      {({ isDropTarget }) => {
        const targetAbove = isDropTarget && nonBlockTextItems.length > 0;
        const targetBelow = isDropTarget && nonBlockTextItems.length === 0;

        return (
          <>
            {targetAbove && <div className={classNames(style.dropIndicator)} />}
            <div className={style.addItems} onClick={(e) => clearSelection(e, setSelectedItems)}>
              <Button
                disabled={isEditingNewContent}
                level="subtle"
                size="micro"
                leadingIcon={<AddIcon />}
                onClick={() => addNewTextItemAction()}
              >
                Add text item
              </Button>

              {nonBlockTextItems.length === 0 && (
                <Button
                  disabled={isEditingNewContent}
                  size="micro"
                  level="subtle"
                  leadingIcon={<AddIcon />}
                  onClick={() => addNewBlockAction({ atIndex: numBlocks - 1 })}
                  className={classNames(style.createNewButton, style.forceShow)}
                >
                  Create block
                </Button>
              )}
            </div>
            {targetBelow && <div className={classNames(style.dropIndicator)} />}
            <div className={style.bottomPadding} />
          </>
        );
      }}
    </DragAndDroppable>
  );
}

function TextItemBlockWrapperWithRowSeparator({
  newBlock,
  numBlocks,
  nonBlockTextItems,
  setSelectedItems,
  isEditingNewContent,
  addNewBlockAction,
  addNewTextItemAction,
  textItemBlock,
  index,
}: {
  key: string;
  newBlock: { name: string; atIndex?: number } | null;
  numBlocks: number;
  nonBlockTextItems: { _id: string; sortKey: string }[];
  setSelectedItems: (args_0: React.SetStateAction<Selection>) => void;
  isEditingNewContent: boolean;
  addNewBlockAction: (update?: { name?: string; atIndex?: number } | undefined) => void;
  addNewTextItemAction: (args?: { blockId?: string | null; location?: "start" | "end" }) => void;
  textItemBlock: IFDittoProjectData["blocks"][number];
  index: number;
}) {
  const isCreatingNewBlockAfter = newBlock?.atIndex !== undefined && newBlock.atIndex === index + 1;
  const isCreatingNewBlockBefore =
    (newBlock && index === newBlock.atIndex) ||
    (newBlock && newBlock?.atIndex === undefined && index === numBlocks - 1) ||
    // this is to handle the case where atIndex is (incorrectly) not a valid block index; we default to
    // rendering the newBlock at the end of the list
    (newBlock && newBlock.atIndex !== undefined && newBlock.atIndex >= numBlocks && index === numBlocks - 1);

  // This is true if:
  // -- the block is the last actual block in the list, and
  // -- there are no non-block text items in the project
  const blockIsLastRow = index === numBlocks - 2 && nonBlockTextItems.length === 0;

  const betweenBlocksRowRef = useRef<HTMLDivElement>(null);

  const isNonBlockBlock = index === numBlocks - 1;

  return (
    <div className={style.blockRow}>
      {/* This will only render a row separator before the root text item list. */}
      {!isCreatingNewBlockBefore && (nonBlockTextItems.length > 0 || isEditingNewContent) && isNonBlockBlock && (
        <div
          className={style.betweenBlocksRow}
          onClick={(e) => clearSelection(e, setSelectedItems)}
          ref={betweenBlocksRowRef}
        >
          <Button
            disabled={isEditingNewContent}
            level="subtle"
            size="micro"
            leadingIcon={<AddIcon />}
            onClick={() => addNewTextItemAction({ location: "start" })}
          >
            Add text item
          </Button>

          <Button
            disabled={isEditingNewContent}
            size="micro"
            level="subtle"
            leadingIcon={<AddIcon />}
            onClick={() => addNewBlockAction({ atIndex: index })}
            className={classNames(style.createNewButton, style.forceShow)}
          >
            Create block
          </Button>
        </div>
      )}

      {isCreatingNewBlockBefore && (
        <>
          <NewBlockWrapper className={style.newBlock} />
          <div className={style.betweenBlocksRow}></div>
        </>
      )}

      <TextItemBlockWrapper
        textItemBlock={textItemBlock}
        index={index}
        maxIndex={numBlocks - 2}
        {...{ betweenBlocksRowRef }}
      />

      {/* We render the row separator after all blocks, except for the last one before non-block text items.*/}
      {(index < numBlocks - 2 || isCreatingNewBlockAfter) && (
        <div
          className={style.betweenBlocksRow}
          onClick={(e) => clearSelection(e, setSelectedItems)}
          ref={betweenBlocksRowRef}
        >
          {!isCreatingNewBlockAfter && !blockIsLastRow && (
            <Button
              disabled={isEditingNewContent}
              size="micro"
              level="subtle"
              leadingIcon={<AddIcon />}
              onClick={() => addNewBlockAction({ atIndex: index + 1 })}
              className={style.createNewButton}
            >
              Create block
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

function TextItemListLoadingSkeleton() {
  return (
    <div className={style.loadingSkeleton}>
      <div className={style.block}>
        <Skeleton width={120} height={17} baseColor="var(--bg-minimal)" highlightColor="white" />
        <div className={style.textItems}>
          <Skeleton width={"100%"} height={36} baseColor="var(--bg-minimal)" highlightColor="white" />
          <Skeleton width={"100%"} height={36} baseColor="var(--bg-minimal)" highlightColor="white" />
        </div>
      </div>

      <div className={style.rootItems}>
        <Skeleton width={"100%"} height={36} baseColor="var(--bg-secondary)" highlightColor="var(--bg-minimal)" />
      </div>
    </div>
  );
}

export default TextItemList;
