import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/react";
import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { Option } from "../MultiCombobox";
import style from "./index.module.css";

export interface ComboboxOption {
  value: string;
  label: string;
  /**
   * Whether the option is a draft item created with the "Create item" combobox functionality.
   */
  isDraftItem?: boolean;
}

interface IBaseComboboxProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The options in the combobox dropdown.
   */
  options: ComboboxOption[];
  /**
   * Options which should not be shown in the dropdown and cannot be created.
   */
  exclusions?: string[];
  /**
   * The selected item from the options list.
   */
  selectedItem: ComboboxOption | null;
  /**
   * Callback function to set the selected item.
   */
  setSelectedItem: (item: ComboboxOption | null) => void;
  /**
   * Placeholder text for the combobox input.
   */
  placeholder?: string;
  /**
   * Text to display when creating a new item.
   */
  createNewText?: string;
  /**
   * Callback function that runs when the user creates a new item in the combobox.
   */
  onCreateNew: (value: string) => void;
  /**
   * Autofocus the combobox input on render.
   */
  autoFocus?: boolean;
}

const NEW_ITEM_VALUE = "__NEW__";

export function BaseCombobox(props: IBaseComboboxProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [comboboxWidth, setComboboxWidth] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  const { selectedItem, setSelectedItem, onCreateNew } = props;

  // Update query when selectedItem changes
  useEffect(() => {
    setQuery(selectedItem?.label || "");
  }, [selectedItem]);

  useEffect(function calculateWrapperDimensions() {
    if (!wrapperRef.current) return;
    const rect = wrapperRef.current.getBoundingClientRect();
    wrapperRef.current.style.setProperty("--input-wrapper-height", `${rect.height}px`);
    wrapperRef.current.style.setProperty("--input-wrapper-width", `${rect.width}px`);
  });

  useEffect(function recalculateOptionsWidth() {
    if (wrapperRef.current) {
      const integerWidth = Math.ceil(wrapperRef.current.offsetWidth);
      setComboboxWidth(integerWidth);
    }
  }, []);

  const selectedItems = useMemo(
    function selectedItems() {
      return props.options.filter((item) => selectedItem?.value === item.value);
    },
    [props.options, selectedItem?.value]
  );
  const unselectedItems = useMemo(
    () =>
      props.options
        .filter(
          (item) =>
            !item.isDraftItem &&
            selectedItem?.value !== item.value &&
            // filter items that don't match the query
            item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    [props.options, query, selectedItem?.value]
  );

  const showCreateNew = useMemo(
    () =>
      query !== "" &&
      !props.options.some((item) => item.label === query) &&
      !props.exclusions?.some((value) => value.toLowerCase() === query.toLowerCase()),
    [props.options, props.exclusions, query]
  );
  const showOptions = useMemo(
    () => isOpen && (unselectedItems.length > 0 || selectedItems.length > 0 || showCreateNew),
    [isOpen, selectedItems.length, showCreateNew, unselectedItems.length]
  );

  const onChangeValue = useCallback(
    function onChangeValue(value: ComboboxOption | null) {
      if (value?.value === NEW_ITEM_VALUE) {
        onCreateNew(query);

        // Set isDraftItem flag to indicate this is an unsaved combobox option
        value = { value: query, label: query, isDraftItem: true };
      }

      setSelectedItem(value);
      setQuery(value?.label || ""); // Immediately set the query to the new value's label
      setIsOpen(false);
    },
    [onCreateNew, query, setSelectedItem]
  );

  /**
   * Callback function that runs when the user creates a new item in the combobox.
   * @param value - The value of the new item (the current text in the combobox input).
   */
  const handleOnCreateNew = useCallback(
    (value: string) => {
      onCreateNew(value);

      // Set isDraftItem flag to indicate this is an unsaved combobox option
      const newValue = { value, label: value, isDraftItem: true };
      onChangeValue(newValue);
    },
    [onCreateNew, onChangeValue]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen(false);
        setQuery(selectedItem?.label || "");
      }
      // if the user presses enter and there are no suggestions, create a new item
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent the default Combobox on enter behavior

        const existingItem = props.options.find((item) => item.value === query);
        if (existingItem) {
          // Prevent creating an option that already exists
          onChangeValue(existingItem);
        } else {
          handleOnCreateNew(query);
        }
      }
    },
    [selectedItem?.label, props.options, query, onChangeValue, handleOnCreateNew]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
      setIsOpen(true);
    },
    [setQuery]
  );

  const handleFocus = useCallback(() => {
    if (!selectedItem) setQuery(""); // Clear the query when focusing
  }, [selectedItem]);

  const handleBlur = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleInputClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <Combobox
      immediate
      by="value"
      value={selectedItem}
      onChange={onChangeValue}
      onClose={() => {
        setQuery(selectedItem?.label || "");
      }}
    >
      <div
        className={classNames(style.combobox, {
          [style.open]: showOptions,
        })}
        style={{ width: comboboxWidth ? `${comboboxWidth}px` : undefined }}
        ref={wrapperRef}
      >
        <div className={style.selectedOptions}>
          <ComboboxInput
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            onKeyDown={handleKeyDown}
            onChange={onInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleInputClick}
            className={style.input}
            value={query}
            ref={inputRef}
          />
          {!isOpen && <Icon Icon={<KeyboardArrowDownIcon />} size="xs" className={style.dropdownIcon} />}
        </div>

        {showOptions && (
          <ComboboxOptions
            // set static to true in order to control combobox open/close behavior
            static={true}
            modal={false}
            anchor={false}
            style={{ width: comboboxWidth }}
            className={style.optionsList}
            ref={optionsListRef}
          >
            <div className={style.filteredOptions}>
              {selectedItems.map((option) => (
                <Option
                  key={`option-$${option.value}`}
                  className={classNames(style.option)}
                  innerClassName={style.optionInner}
                  label={option.label}
                  value={option.value}
                >
                  {({ selected }) => (
                    <>
                      <Icon Icon={<CheckIcon />} size="xxs" className={classNames({ [style.hidden]: !selected })} />
                      <Text>{option.label}</Text>
                    </>
                  )}
                </Option>
              ))}
              {unselectedItems.map((option) => (
                <Option
                  key={`option-$${option.value}`}
                  className={classNames(style.option)}
                  innerClassName={style.optionInner}
                  label={option.label}
                  value={option.value}
                >
                  {({ selected }) => (
                    <>
                      <Icon Icon={<CheckIcon />} size="xxs" className={classNames({ [style.hidden]: !selected })} />
                      <Text>{option.label}</Text>
                    </>
                  )}
                </Option>
              ))}
              {showCreateNew && (
                <Option
                  key={`option-${NEW_ITEM_VALUE}`}
                  className={classNames(style.option, style.createNewOptionWrapper)}
                  innerClassName={style.optionInner}
                  label={query}
                  value={NEW_ITEM_VALUE}
                >
                  {() => (
                    <>
                      <Icon Icon={<Add />} size="xs" className={style.createNewIcon} />
                      <Text>{"Create: " + query}</Text>
                    </>
                  )}
                </Option>
              )}
            </div>
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}

export default BaseCombobox;
