import * as httpDittoProject from "@/http/dittoProject";
import { projectIdAtom, unwrappedProjectAtom } from "@/stores/Project";
import { atom } from "jotai";
import { refreshFramePreviewDataAtom, refreshSyncedTextNodesMapAtom } from "./ProjectDesignPreviews";

export type FigmaSyncFailureReason = "INVALID_TOKEN" | "UNKNOWN";

const FigmaSyncErrorMessages = {
  INVALID_TOKEN: "Invalid Figma token. Please update your Figma token in your account settings.",
  UNKNOWN: "Sync with Ditto failed. Please try again.",
};

const _lastFigmaSyncTimeAtom = atom(undefined as Date | undefined);
export const figmaSyncErrorMessageAtom = atom<FigmaSyncFailureReason | undefined>(undefined);
export const figmaSyncKeyAtom = atom<string | undefined>(undefined);
export const isFigmaSyncingAtom = atom((get) => get(figmaSyncKeyAtom) !== undefined);

export const figmaSyncMessageAtom = atom((get) => {
  const figmaSyncKey = get(figmaSyncKeyAtom);
  const lastSyncTime = get(_lastFigmaSyncTimeAtom);
  const errorMessage = get(figmaSyncErrorMessageAtom);
  if (errorMessage) return FigmaSyncErrorMessages[errorMessage];
  if (figmaSyncKey) return "Sync with Ditto in progress...";
  return lastSyncTime || "Sync with Ditto";
});

export const figmaSyncActionAtom = atom(null, async (get, set) => {
  const projectId = get(projectIdAtom);
  const project = get(unwrappedProjectAtom);
  if (!projectId || !project) throw new Error("projectIdAtom or unwrappedProjectAtom is not set");
  const fileId = project.integrations.figma?.fileId;
  if (!fileId) throw new Error("No Figma file associated with project");

  try {
    set(figmaSyncErrorMessageAtom, undefined);
    const key = crypto.randomUUID();
    set(figmaSyncKeyAtom, key);

    const [createPreviewsJobReq] = httpDittoProject.syncFigma({
      projectId,
      fileId,
      branchId: project.integrations.figma?.branchId ?? null,
    });

    const jobId = (await createPreviewsJobReq).data.jobId;

    set(_lastFigmaSyncTimeAtom, new Date());

    return jobId;
  } catch (e) {
    set(handleFigmaSyncErrorActionAtom, "UNKNOWN");
  }
});

export const handleFigmaSyncErrorActionAtom = atom(null, async (get, set, error: FigmaSyncFailureReason) => {
  set(figmaSyncErrorMessageAtom, error);
  set(figmaSyncKeyAtom, undefined);
  set(_lastFigmaSyncTimeAtom, undefined);
});

export const handleFigmaSyncCompletedActionAtom = atom(null, async (get, set) => {
  set(figmaSyncKeyAtom, undefined);
  set(_lastFigmaSyncTimeAtom, new Date());

  set(refreshFramePreviewDataAtom);
  set(refreshSyncedTextNodesMapAtom);
});
