const ROOT = "/variant";
export default {
  get: {
    listAll: {
      url: (projectId) => {
        // the code for our export modals is a lil goofy: all modals
        // use the same React component, and hardcode the `projectId` prop
        // if the modal isn't rendering on the projects page.
        //
        // `component_library` is the hardcoded value for the export modal
        // in the component library, and that's not a real projectId, so
        // we don't want to pass it in the request
        if (!projectId || projectId === "component_library") return `${ROOT}`;
        return `${ROOT}?project_id=${projectId}`;
      },
    },
    variantId: {
      url: (id) => `${ROOT}/${id}`,
    },
    getVariantsForComp: {
      url: (id) => `${ROOT}/getVariantsForComp/${id}`,
    },
    getVariantsForWorkspace: {
      url: `${ROOT}/getVariantsWithUsageData`,
    },
  },
  post: {
    restore: {
      url: (compId, variantId) => `${ROOT}/restore/${compId}/${variantId}`,
      body: ({
        text_before,
        text_after,
        rich_text_before,
        rich_text_after,
        variables_before,
        variables_after,
        doc_id,
        doc_name,
        figma_node_id,
        from,
      }) => ({
        text_before,
        text_after,
        rich_text_before,
        rich_text_after,
        variables_before,
        variables_after,
        doc_id,
        doc_name,
        figma_node_id,
        from,
      }),
    },
    createVariant: {
      url: `${ROOT}/createVariant`,
      body: ({ projectId, description, frameIds, name, folderId }) => ({
        folderId,
        description,
        projectId,
        frameIds,
        name,
      }),
    },
    addFrameVariants: {
      url: `${ROOT}/addFrameVariants`,
      body: ({ projectId, groupIds, variants, folderId, applyToAllGroups, status }) => ({
        projectId,
        groupIds,
        variants,
        folderId,
        applyToAllGroups,
        status,
      }),
    },
    editVariantTextForComp: {
      url: (compId, variantId) => `${ROOT}/editVariantTextForComp/${compId}/${variantId}`,
      body: ({ updatedVariantInfo, fromFigma }) => ({
        updatedVariantInfo,
        fromFigma,
      }),
    },
    deleteVariants: {
      url: `${ROOT}/deleteVariants`,
      body: ({ variantIds }) => ({ variantIds }),
    },
  },
  put: {
    name: {
      url: `${ROOT}/name`,
      body: ({ doc_ID, doc_name, variant_ID, oldName, newName }) => ({
        doc_ID,
        doc_name,
        variant_ID,
        oldName,
        newName,
      }),
    },
    nameWithId: {
      url: (id) => `${ROOT}/name/${id}`,
      body: ({ newName }) => ({ newName }),
    },
    apiID: {
      url: (id) => `${ROOT}/apiID/${id}`,
      body: ({ newValue }) => ({ newValue }),
    },
  },
  delete: {
    variant: {
      url: (id) => `${ROOT}/${id}`,
    },
    frameVariant: {
      url: `${ROOT}/frameVariant`,
      body: ({ doc_ID, frame_ID, variant_ID }) => ({
        doc_ID,
        frame_ID,
        variant_ID,
      }),
    },
  },
};
