import React from "react";

import RoundedCount from "@/components/rounded-count/rounded-count";
import classNames from "classnames";
import { EditVariant, ISelectedVariant } from "./EditVariant";
import style from "./EditVariantPanel.module.css";
import { VariantInstance } from "./VariantInstance";

interface IProps {
  handleSwitchTab: (tab: "edit" | "instances") => void;
  selectedTab: "edit" | "instances";
  selectedVariant: ISelectedVariant;
  variantActions: {
    onDelete: (variantId: string) => void;
    onMoveToFolder: (variantId: string, folder: { id: string; name: string }) => void;
    onUpdateDescription: (variantId: string, updatedDescription: string) => Promise<void>;
    onUpdateName: (variantId: string, updatedName: string) => void;
    onClickProjectInstance: (projectId: string) => void;
    onClickComponentInstance: (componentId: string) => void;
    onEditApiID: (variantId: string, apiId: string) => Promise<string>;
  };
}

export const EditVariantPanel = (props: IProps) => {
  function onTabClickEdit() {
    props.handleSwitchTab("edit");
  }
  function onTabClickInstances() {
    props.handleSwitchTab("instances");
  }

  return (
    <div className={style.sidebar}>
      <div className={style.titleSection}>
        <div
          onClick={onTabClickEdit}
          className={classNames({
            [style.tabEdit]: true,
            [style.active]: props.selectedTab === "edit",
          })}
        >
          Edit
        </div>
        <div
          onClick={onTabClickInstances}
          className={classNames({
            [style.tabInstances]: true,
            [style.active]: props.selectedTab === "instances",
          })}
        >
          Instances
          {props.selectedVariant.instances.length > 0 && (
            <RoundedCount className={style.count} count={props.selectedVariant.instances.length} />
          )}
        </div>
      </div>
      <div className={style.overflowContainer}>
        {props.selectedTab === "edit" && (
          <EditVariant variant={props.selectedVariant} variantActions={props.variantActions} />
        )}
        {props.selectedTab === "instances" && (
          <div>
            {props.selectedVariant.instances.length === 0 && (
              <div className={style.draft}>Variant currently has no instances.</div>
            )}
            {props.selectedVariant.instances.length > 0 && (
              <div className={style.variantInstanceContainer}>
                {props.selectedVariant.instances.map((instance) => (
                  <VariantInstance
                    key={instance.type === "component" ? instance.componentId : instance.projectId}
                    instance={instance}
                    className={style.variantInstance}
                    onClickComponentInstance={props.variantActions.onClickComponentInstance}
                    onClickProjectInstance={props.variantActions.onClickProjectInstance}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
