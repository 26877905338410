import * as httpComp from "@/http/comp";
import * as httpVariant from "@/http/variantTyped";
import { useWorkspace } from "@/store/workspaceContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NotesIcon from "@mui/icons-material/Notes";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import logger from "@shared/utils/logger";
import classnames from "classnames";
import isEqual from "lodash.isequal";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { scroller } from "react-scroll";
import { isSuperset } from "../../../shared/utils/array";
import spinner from "../../assets/small-spinner.gif";
import { TOAST_TYPES } from "../../defs";
import { useHasProjectFeatureFlag } from "../../hooks/useHasProjectFeatureFlag";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import CharacterLimitRow from "../CharacterLimitRow/index";
import CompactLabel from "../CompactLabel";
import StatusSelect from "../StatusSelect";
import UserSelect from "../UserSelect";
import VariableRichTextArea from "../VariableTextArea/VariableRichTextArea";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import ComponentModal from "../componentmodal/componentmodal";
import { TextItem, TextItems } from "../doc/multiselect";
import TagInput from "../tag-input/tag-input";
import VariablesPanel from "../variable-interpolations/VariablesPanel";
import { useGetVariablesHaveChanged } from "../variable-interpolations/lib";
import style from "./style.module.css";
import { useSectionVisibility } from "./useSectionVisibility";

const EditMultiComp = ({
  doc_ID,
  doc_name,
  handleDocUpdate,
  handleHistoryUpdate,
  multiSelectedIds,
  multiSelectedComps,
  setMultiSelectedComps,
  forceShowToast,
  tagSuggestions,
  getWorkspaceTags,
  unselectAll,
  setShowMultiAttachCompToast,
  frameVariants,
  multiSelectGroupIds,
  groupStateDispatch,
  isLockedProject,
  allCompsInFrameSelected,
  isMultiSelectOnSameFrame,
  onCopySetupClicked,
  refetchMultiComps,
  ...otherProps
}) => {
  const {
    // optional prop to get notified when a component is attached to a single text tem
    onSingleAttachComponent,
    // optional prop to get notified when a component is attached to multiple text items
    onMultiAttachComponent,
  } = otherProps;
  const segment = useSegment();

  const isRichTextFlagOn = useHasProjectFeatureFlag("rich_text");

  // variables to keep track of what's in common for multiselect (XOR)
  const [multiHidden, setMultiHidden] = useState(false);

  // each of the input values (status, tags, text, assignee) is tracked in aggregate;
  // we AND the values, and if they're all the same, we show the value in the input.
  // otherwise, we show "MIXED" (for status and assignee), just display the intersection
  // (tags), or just don't show the input at all (text)
  //
  // for each of these values, we need to track
  // 1. the input value, initialized by the props
  // 2. the "original" value, which is the saved value of selected components
  //     -- this is determined by the props, until we "Save" the edits, at which
  //        point the "original" value is updated to the input value.
  //     -- we save this in a ref so that we can control when it's updated
  //
  // Note from Reed: this sucks, sorry. what *should* happen is that these values
  // should flow down from the multiSelectedComps prop, but that value is manipulated
  // to hell and back in Project/index, and I couldn't untangle it.
  const [statusInput, setStatusInput] = useState(() => computeMultiStatus(multiSelectedComps));
  const origMultiStatus = useRef(computeMultiStatus(multiSelectedComps));
  const statusChanged = statusInput !== origMultiStatus.current;
  // statuses must be recomputed every single time multiSelectedComps changes
  useEffect(() => {
    setStatusInput(computeMultiStatus(multiSelectedComps));
    origMultiStatus.current = computeMultiStatus(multiSelectedComps);
  }, [multiSelectedComps]);

  const [assigneeInput, setAssigneeInput] = useState(computeMultiAssignee(multiSelectedComps));
  const origMultiAssigneeId = useRef(computeMultiAssignee(multiSelectedComps));
  const assigneeChanged = assigneeInput !== origMultiAssigneeId.current;

  const origMultiTags = useRef(computeMultiTags(multiSelectedComps));
  const [tagsInput, setTagsInput] = useState(() => computeMultiTags(multiSelectedComps));
  const addedTags = useMemo(() => {
    const origTags = origMultiTags.current;
    const currTags = tagsInput;
    return currTags.filter((tag) => !origTags.includes(tag));
  }, [tagsInput, origMultiTags.current]);

  const deletedTags = useMemo(() => {
    const origTags = origMultiTags.current;
    const currTags = tagsInput;
    return origTags.filter((tag) => !currTags.includes(tag));
  }, [tagsInput, origMultiTags.current]);
  const tagsChanged = !isEqual(origMultiTags.current, tagsInput);

  const [charLimitInput, setCharLimitInput] = useState(computeMultiCharLimit(multiSelectedComps));
  const origMultiCharLimit = useRef(computeMultiCharLimit(multiSelectedComps));
  const charLimitChanged =
    charLimitInput !== origMultiCharLimit.current && !(isNaN(charLimitInput) && isNaN(origMultiCharLimit.current));

  const [multiText, setMultiText] = useState("");
  const [multiRichText, setMultiRichText] = useState("");

  const [multiSameFrame, setMultiSameFrame] = useState(false);
  const [textChanged, setTextChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [blockSaving, setBlockSaving] = useState(false);
  const [componentModalOpen, setComponentModalOpen] = useState(false);

  const {
    selectedGroupId,
    groupState: [groupState],
  } = useProjectContext();

  const selectedGroup = useMemo(() => {
    if (!selectedGroupId[0]) return null;
    return groupState.groups.find((g) => g._id === selectedGroupId[0]);
  }, [groupState.groups, selectedGroupId]);

  const isDraftGroup = useMemo(() => {
    if (!selectedGroup) return false;
    return !("frame_id" in selectedGroup.integrations.figma);
  }, [selectedGroup]);

  const { users } = useWorkspace();
  const formattedUserOptions = useMemo(() => {
    if (!users) return [];
    return users.map((user) => ({
      id: user._id,
      name: user.name,
    }));
  }, [users]);

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const {
    canSaveEdits: [canSaveEdits, setCanSaveEdits],
    setModalParams,
  } = useContext(UnsavedChangesContext);

  useEffect(() => {
    setCanSaveEdits(statusChanged || tagsChanged || textChanged || assigneeChanged || charLimitChanged);
  }, [statusChanged, tagsChanged, textChanged, setCanSaveEdits, assigneeChanged, charLimitChanged]);

  const allVariablesInSelectedItems = useMemo(() => {
    const variableMap = {};

    multiSelectedComps.forEach(({ plurals, variables }) => {
      if (!variables) {
        return;
      }
      let pluralsExist = false;
      let firstPluralVariableMap = {};
      if (plurals?.length) {
        pluralsExist = true;
        firstPluralVariableMap = plurals[0].variables.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      }
      variables.forEach((variable) => {
        if (!variable) {
          return;
        }
        if (pluralsExist) {
          if (firstPluralVariableMap[variable.variable_id]) variableMap[variable.variable_id] = variable;
        } else {
          variableMap[variable.variable_id] = variable;
        }
      });
    });

    return Object.values(variableMap);
  }, [multiSelectedComps]);

  // { text, variables }
  const valueBeingEdited = useRef();

  const getVariablesHaveChanged = useGetVariablesHaveChanged(allVariablesInSelectedItems);

  const handleAssigneeChange = (userId) => {
    setAssigneeInput(userId);
  };

  const handleStatusChange = (value) => {
    setStatusInput(value);
  };

  const onDeleteTag = (i) => {
    const newTags = [...tagsInput];
    newTags.splice(i, 1);
    setTagsInput(newTags);
  };

  /**
   * @param {id: number, name: string} tag
   */
  const onAddTag = (tag) => {
    if (tagsInput.includes(tag.name)) return;
    const newTags = [...tagsInput, tag.name];
    setTagsInput(newTags);
  };

  const changeHideMultiple = async (is_hidden) => {
    const multiSelectedCompsSet = new Set(multiSelectedComps.map(({ _id }) => _id));

    const updateHiddenStateForSelectedTextItems = (isHidden) => {
      const processTextItems = (textItems) =>
        textItems.map((textItem) => {
          if (multiSelectedCompsSet.has(textItem._id)) {
            textItem.is_hidden = isHidden;
          }
          return textItem;
        });

      const processBlocks = (blocks) => {
        return blocks.map((b) => ({ ...b, comps: processTextItems(b.comps) }));
      };

      const groupId = TextItem.getGroupId(multiSelectedComps[0]);
      groupStateDispatch({
        type: "UPDATE_GROUP_TEXT_ITEMS_CALLBACK",
        groupId,
        compsCallback: processTextItems,
        blocksCallback: processBlocks,
      });

      setMultiSelectedComps(
        multiSelectedComps.map((comp) => {
          if (!comp.ws_comp) comp.is_hidden = isHidden;
          return comp;
        })
      );

      setMultiHidden(isHidden);
    };

    try {
      segment.track({
        event: SegmentEvents.HIDE_SELECTED_TEXT_FROM_VIEW_BUTTON_CLICKED,
      });
      updateHiddenStateForSelectedTextItems(is_hidden);

      // make the network request AFTER updating in state for a smoother experience.
      // we rollback the state changes in the catch block if the network request fails.
      const { url, body } = API.comp.post.multiHide;
      await http.post(
        url,
        body({
          is_hidden,
          comp_ids: multiSelectedIds,
          doc_ID,
          from: "web_app",
        })
      );
    } catch (error) {
      forceShowToast({
        title: "⚠️ Error hiding text",
        body: "Sorry! We had an issue hiding the selected text. ",
        type: TOAST_TYPES.edit_error,
        autoHide: true,
      });
      console.error("Error in hiding multiple: ", error.message);

      // rollback the state changes if an error occurs
      updateHiddenStateForSelectedTextItems(!is_hidden);
    }
  };

  const createBlock = async () => {
    try {
      if (!multiSameFrame) return;

      const [component] = multiSelectedComps;
      const groupId = TextItem.getGroupId(component);

      setBlockSaving(true);
      const { url, body } = API.comp.post.multiBlock;
      await http.post(
        url,
        body({
          comp_ids: multiSelectedIds,
          doc_id: doc_ID,
          groupId,
          fromFigma: false,
        })
      );
      setMultiSelectedComps(
        multiSelectedComps.map((comp) => {
          comp.blockIndex = 0;
          return comp;
        })
      );
      segment.track({ event: "Create Block from Selected Button Clicked" });
      await handleDocUpdate(multiSelectedIds);
      const scrollId = multiSelectedComps[0]._id;

      // wait for the new block to be rendered before attempt scroll
      const checkNewBlockExists = setInterval(() => {
        let el = document.querySelectorAll(`[name="${scrollId}"]`);
        let newBlock = el.length > 0 ? el[0].closest('[data-name="block"]') : null;

        if (newBlock) {
          scroller.scrollTo(scrollId, {
            duration: 500,
            offset: -100,
            smooth: true,
            containerId: "projectContainer",
          });
          clearInterval(checkNewBlockExists);
          setBlockSaving(false);
          newBlock.querySelector(".rename-block-icon")?.click();
        }
      }, 100);
    } catch (error) {
      forceShowToast({
        title: "⚠️ Error creating block",
        body: "Sorry! We had an issue saving your edits. ",
        type: TOAST_TYPES.edit_error,
        autoHide: true,
      });
      console.error("Error in creating multiselect block: ", error.message);
      setBlockSaving(false);
    }
  };

  const showEditErrorToast = () =>
    forceShowToast({
      title: "⚠️ Error saving edits",
      body: "Sorry! We had an issue saving your edits. ",
      type: TOAST_TYPES.edit_error,
      autoHide: true,
    });

  const changeTextMultiple = async () => {
    try {
      const compsToUpdate = multiSelectedComps.filter((comp) => !comp.ws_comp);
      const compIdsToUpdate = compsToUpdate.map((comp) => comp._id);
      const compFigmaIdsToUpdate = compsToUpdate.map(({ figma_node_ID }) => figma_node_ID);
      if (!compsToUpdate || compsToUpdate.length === 0 || !compsToUpdate[0].text) {
        return;
      }

      const { url, body } = API.comp.post.multiText;
      const updatedText = valueBeingEdited.current?.text || "";
      const updatedRichText = valueBeingEdited.current?.richText;
      const updatedVariables = valueBeingEdited.current?.variables || [];

      await http.post(
        url,
        body({
          text_before: compsToUpdate[0].text,
          text_after: updatedText,
          rich_text: updatedRichText,
          variables_before: compsToUpdate[0].variables || [],
          variables_after: updatedVariables,
          comp_ids: compIdsToUpdate,
          doc_id: doc_ID,
          doc_name: doc_name,
          figma_node_ids: compFigmaIdsToUpdate,
        })
      );

      handleDocUpdate(multiSelectedComps.map((comp) => comp._id));
      setMultiSelectedComps(
        multiSelectedComps.map((comp) => {
          if (!comp.ws_comp) {
            comp.text = valueBeingEdited.current?.text || "";
            comp.rich_text = valueBeingEdited.current?.richText;
            if (comp?.plurals?.length > 0) {
              comp.plurals[0].variables = updatedVariables.map((v) => v.variable_id);
            }
            comp.variables = updatedVariables;
          }
          return comp;
        })
      );
      handleHistoryUpdate();
      setTextChanged(false);
    } catch (error) {
      showEditErrorToast();
      console.error("Error in changing multiple texts: ", error.message);
    }
  };

  const resetChanges = () => {
    getMultiProperties();
    setAssigneeInput(origMultiAssigneeId.current);
    setStatusInput(origMultiStatus.current);
    setTagsInput(origMultiTags.current);
    setCharLimitInput(origMultiCharLimit.current);
    setCanSaveEdits(false);
  };

  const onTextChange = (newInputValue, richText) => {
    const updatedValue = {
      ...newInputValue,
      richText,
    };
    const baseRichText = multiSelectedComps[0].rich_text;
    const didRichTextChange = !isEqual(richText, baseRichText);

    setTextChanged(didRichTextChange);
    valueBeingEdited.current = updatedValue;
  };

  const saveEdits = async () => {
    segment.track({
      event: "Multi-edit Saved",
      properties: {
        num: multiSelectedIds.length,
      },
    });
    setIsSaving(true);

    const hasStatusChanges = statusChanged && statusInput !== "MIXED";
    const hasTagChanges = tagsChanged;
    const hasAssigneeChanges = assigneeChanged && assigneeInput !== "MIXED";
    const hasCharacterLimitChanges = charLimitChanged;
    const hasTextChanged = textChanged && selectedCompsTextSame();

    if (multiSelectedComps) {
      try {
        let promises = [];

        if (hasStatusChanges) {
          // Status gets handled separately, because it's the only attribute that can conditionally be applied to the
          // base text item or a variant instance. This endpoint handles both, and also handles either unattached or
          // attached text items.
          const [request] = httpVariant.updateVariantTextInstances({
            data: {
              projectId: doc_ID,
              allTextItemsToUpdate: multiSelectedComps.map((item) => ({
                textItemId: item._id,
                componentId: item.ws_comp?._id ?? item.ws_comp ?? undefined,
                variantId: item.selectedVariant?.id ?? "__base__",
              })),
              updates: { status: statusInput },
            },
          });
          promises.push(request);
        }

        if (hasTagChanges || hasAssigneeChanges || hasCharacterLimitChanges) {
          const compsToUpdate = multiSelectedComps.filter((comp) => !comp.ws_comp);
          const compIdsToUpdate = compsToUpdate.map((comp) => comp._id);
          const attachedCompsToUpdate = multiSelectedComps.filter((comp) => comp.ws_comp);
          if (compIdsToUpdate.length > 0) {
            const [request] = httpComp.update({
              textItemIds: compIdsToUpdate,
              updates: {
                assignee: hasAssigneeChanges ? assigneeInput : undefined,
                tags: hasTagChanges
                  ? {
                      tagsAdded: addedTags,
                      tagsDeleted: deletedTags,
                    }
                  : undefined,
                characterLimit: hasCharacterLimitChanges ? charLimitInput : undefined,
                // TODO: add text changes
              },
              projectId: doc_ID,
            });
            promises.push(request);
          }

          // update workspace components
          if (attachedCompsToUpdate.length > 0) {
            promises.push(
              http.put(
                API.ws_comp.put.updateMultiple.url,
                API.ws_comp.put.updateMultiple.body({
                  docId: doc_ID,
                  status: hasStatusChanges ? statusInput : undefined,
                  tags_added: hasTagChanges ? addedTags : undefined,
                  tags_deleted: hasTagChanges ? deletedTags : undefined,
                  assignee: hasAssigneeChanges ? assigneeInput : undefined,
                  ids: attachedCompsToUpdate.map((comp) => comp.ws_comp._id || comp.ws_comp),
                  characterLimit: hasCharacterLimitChanges ? charLimitInput : undefined,
                })
              )
            );
          }
        }

        await Promise.all(promises);
      } catch (error) {
        logger.error("Error making multi-updates", {}, new Error("Error making multi-updates"));
      }

      // Char limit:
      handleDocUpdate(multiSelectedComps.map((comp) => comp._id));
      if (hasCharacterLimitChanges) {
        origMultiCharLimit.current = charLimitInput;
      }
      if (hasAssigneeChanges) {
        origMultiAssigneeId.current = assigneeInput;
      }
      if (hasStatusChanges) {
        const updatedMultiSelectedComps = multiSelectedComps.map((comp) => {
          comp.status = statusInput;
          return comp;
        });
        origMultiStatus.current = computeMultiStatus(updatedMultiSelectedComps);
      }
      if (hasTagChanges) {
        origMultiTags.current = tagsInput;
        if (
          !isSuperset(
            tagsInput,
            tagSuggestions.map((item) => item.name)
          )
        ) {
          getWorkspaceTags();
        }
      }

      await refetchMultiComps(multiSelectedIds);

      if (hasStatusChanges || hasCharacterLimitChanges || hasAssigneeChanges) {
        handleHistoryUpdate();
      }
    }
    if (hasTextChanged) {
      await changeTextMultiple();
    }

    setIsSaving(false);
    getMultiProperties();
  };

  const getMultiProperties = () => {
    const selectedTextComps = multiSelectedComps;
    setTextChanged(false);

    if (selectedWsCompsCount === 0 && selectedCompsTextSame() && selectedTextComps.length > 0) {
      setMultiText(selectedTextComps[0].text);
      setMultiRichText(selectedTextComps[0].rich_text);
    }

    let hiddens = selectedTextComps.map(({ is_hidden }) => is_hidden);
    let hidden_same = hiddens.every((val, i, arr) => val === arr[0]);
    if (hidden_same && hiddens.length > 0) {
      setMultiHidden(hiddens[0]);
    } else {
      setMultiHidden(true);
    }
  };

  useEffect(() => {
    if (multiSelectedIds.length === multiSelectedComps.length) {
      getMultiProperties();

      const componentsInSameFrame = TextItems.allSameFrame(multiSelectedComps);
      const componentsInBlock = TextItems.includeBlock(multiSelectedComps);
      const componentsHidden = TextItems.includeHidden(multiSelectedComps);

      setMultiSameFrame(componentsInSameFrame && !(componentsInBlock || componentsHidden));
    }
  }, [multiSelectedIds, multiSelectedComps]);

  const selectedCompsTextSame = () => {
    if (multiSelectedComps.length === 0) return false;
    const baseRichText = multiSelectedComps[0].rich_text;

    return multiSelectedComps?.every(({ rich_text }) => isEqual(rich_text, baseRichText));
  };

  const toggleComponentModal = () => {
    setComponentModalOpen(!componentModalOpen);
  };

  const {
    showMultiSelectedEditsApplyToBase,
    showAttachToSelectedComponent,
    showCreateBlockFromSelected,
    showUnhideSelectedTextFromView,
    showHideSelectedTextFromView,
    showVariablesPanel,
  } = useSectionVisibility({ multiSelectedComps });

  const showCopySetupFromAnotherGroup = useMemo(
    () => isMultiSelectOnSameFrame && allCompsInFrameSelected && !showMultiSelectedEditsApplyToBase && !isDraftGroup,
    [isMultiSelectOnSameFrame, allCompsInFrameSelected, showMultiSelectedEditsApplyToBase, isDraftGroup]
  );

  useEffect(() => {
    setModalParams({
      saveCallback: async () => {
        setCanSaveEdits(false);
        await saveEdits();
      },
      discardCallback: async () => {
        setCanSaveEdits(false);
        resetChanges();
      },
    });
  }, [setModalParams, setCanSaveEdits, saveEdits, resetChanges]);

  const selectedWsCompsCount = useMemo(
    () => multiSelectedComps.filter((comp) => comp.ws_comp).length,
    [multiSelectedComps]
  );

  const getWarningText = () => {
    if (tagsChanged || statusChanged || textChanged || assigneeChanged || charLimitChanged) {
      if (selectedWsCompsCount > 0) {
        return `You have unsaved changes! Remember that editing a component also edits all of its instances.`;
      } else return `You have unsaved changes!`;
    }
    if (selectedWsCompsCount > 0) {
      return `You have ${selectedWsCompsCount} component${
        selectedWsCompsCount === 1 ? "" : "s"
      } in your selection. Remember that editing a component also edits all of its instances.`;
    }
  };

  const tagOptions = useMemo(
    () =>
      tagsInput.map((tag) => ({
        name: tag,
      })),
    [tagsInput]
  );

  return (
    <div className={style.container}>
      {isEditEnabled && (
        <>
          {showCopySetupFromAnotherGroup && (
            <div className={classnames(style.hideCompSection, "hide-component")}>
              <div className={style.hideComp} onClick={onCopySetupClicked}>
                <ExitToAppIcon className={style.icon} />
                Copy setup from another frame
              </div>
            </div>
          )}
          {showAttachToSelectedComponent && (
            <div className={classnames(style.hideCompSection, "hide-component")}>
              <div
                data-testid="attach-select-to-component"
                className={style.hideComp}
                onClick={() => toggleComponentModal()}
              >
                <ImportContactsIcon className={style.icon} />
                Attach selected to component
              </div>
            </div>
          )}
          {showCreateBlockFromSelected && (
            <div className={classnames(style.hideCompSection, "hide-component")}>
              <div data-testid="create-block-from-selected" className={style.hideComp} onClick={() => createBlock()}>
                {!blockSaving ? (
                  <ViewStreamIcon className={style.icon} />
                ) : (
                  <img className={style.loading} src={spinner} />
                )}
                Create block from selected
              </div>
            </div>
          )}
          {showHideSelectedTextFromView && (
            <div className={classnames(style.hideCompSection, "hide-component")}>
              <div className={style.hideComp} onClick={() => changeHideMultiple(true)}>
                <VisibilityOffIcon className={style.icon} />
                Hide selected text from view
              </div>
            </div>
          )}
          {showUnhideSelectedTextFromView && (
            <div className={style.hideCompSection}>
              <div className={style.hideComp} onClick={() => changeHideMultiple(false)}>
                <VisibilityIcon className={style.icon} />
                Unhide selected text from view
              </div>
            </div>
          )}
        </>
      )}
      {multiHidden && <div className={style.noStatusTags} />}
      {!multiHidden && (
        <div className={classnames(style.editComp, "edit-component")}>
          <div className={style.form}>
            {showMultiSelectedEditsApplyToBase && (
              <div className={style.warning}>
                Status changes will be applied to the variant text. Other changes will be applied to the base.
              </div>
            )}
            {getWarningText() && <div className={style.warning}>{getWarningText()}</div>}

            <StatusSelect status={statusInput} handleStatusChange={handleStatusChange} disabled={isLockedProject} />

            {selectedWsCompsCount === 0 && selectedCompsTextSame() && (
              <div className={style.textWrapper}>
                <VariableRichTextArea
                  disableRichText={!isRichTextFlagOn}
                  key={multiText}
                  placeholder={"No text."}
                  isBaseText={true}
                  isDisabled={!isEditEnabled || isLockedProject}
                  isVariant={false}
                  isPlural={false}
                  showContentLength={true}
                  value={{
                    text: multiText,
                    variables: allVariablesInSelectedItems,
                    rich_text: multiRichText,
                  }}
                  handleTextChange={onTextChange}
                  textLabelLeft={<CompactLabel text="Text" Icon={NotesIcon} />}
                  hideCharacterLimit={true}
                  inSampleProject={multiSelectedComps.some((comp) => comp.isSample)}
                />
              </div>
            )}

            <div className={style.assignArea}>
              <CompactLabel Icon={AccountCircleIcon} text="Assign" />
              <UserSelect
                placeholder="No teammate assigned"
                setSelectedUserId={handleAssigneeChange}
                selectedUserId={assigneeInput}
                users={formattedUserOptions}
                disabled={!isEditEnabled}
              />
            </div>

            {(isEditEnabled || tagsInput.length > 0) && (
              <div className={style.tagsArea}>
                <CompactLabel Icon={LocalOfferIcon} text="Tags" />
                <TagInput
                  tags={tagOptions}
                  disabled={!isEditEnabled}
                  onDeleteTag={onDeleteTag}
                  onAddTag={onAddTag}
                  inputAttributes={{ disabled: !isEditEnabled }}
                  tagSuggestions={tagSuggestions}
                />
              </div>
            )}

            <CharacterLimitRow charLimitInput={charLimitInput} setCharLimitInput={setCharLimitInput} />

            <br />
            {
              <div className={style.buttons}>
                <ButtonSecondary text="Cancel" onClick={() => resetChanges()} disabled={!canSaveEdits} />
                <ButtonPrimary
                  text={isSaving ? "Saving..." : "Save Edits"}
                  onClick={() => saveEdits()}
                  disabled={!canSaveEdits || isSaving}
                />
              </div>
            }
          </div>
        </div>
      )}
      {showVariablesPanel && (
        <VariablesPanel
          isVariant={false}
          variables={allVariablesInSelectedItems}
          className={classnames({ [style.variablesPanel]: !multiHidden })}
        />
      )}
      {componentModalOpen && (
        <ComponentModal
          onSingleAttach={onSingleAttachComponent}
          onMultiAttach={onMultiAttachComponent}
          onHide={toggleComponentModal}
          handleDocUpdate={handleDocUpdate}
          multiSelectedIds={multiSelectedIds}
          multiSelectedComps={multiSelectedComps}
          getMultiProperties={getMultiProperties}
          unselectAll={unselectAll}
          setShowMultiAttachCompToast={setShowMultiAttachCompToast}
          handleHistoryUpdate={handleHistoryUpdate}
          frameVariants={frameVariants}
          multiSelectGroupIds={multiSelectGroupIds}
          shouldComponentizeDuplicates={false}
          duplicateComps={[]}
        />
      )}
    </div>
  );
};

export default EditMultiComp;

const computeMulti = (field, comps) => {
  let fieldValues = comps.map((c) => {
    if (field === "status" && c.selectedVariant) {
      const variantInstance = c.variants.find((v) => v.variantId === c.selectedVariant.id);
      return variantInstance ? variantInstance[field] : c[field];
    } else return c[field];
  });

  if (fieldValues.length === 0) {
    return null;
  }

  let fieldValuesSame = fieldValues.every((val, i, arr) => val === arr[0]);
  if (fieldValuesSame) {
    return fieldValues[0];
  }
  return "MIXED";
};

const computeMultiStatus = (comps) => computeMulti("status", comps);

const computeMultiAssignee = (comps) => computeMulti("assignee", comps);

const computeMultiCharLimit = (comps) => computeMulti("characterLimit", comps);

/**
 * Takes a list of text items
 * Returns an array of all the tags common to all the text items
 */
const computeMultiTags = (comps) => {
  const alltags = comps.map(({ tags }) => tags).flat();
  if (alltags.length === 0) {
    return [];
  }

  const counts = {};
  for (let i = 0; i < alltags.length; i++) {
    const tag = alltags[i];
    counts[tag] = counts[tag] ? counts[tag] + 1 : 1;
  }

  const tagObjs = [];
  const tags = [];
  for (const tag in counts) {
    if (counts[tag] === comps.length) {
      tagObjs.push({
        name: tag,
      });
      tags.push(tag);
    }
  }

  return tags;
};
