import { useWorkspace } from "@/store/workspaceContext";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LaunchIcon from "@mui/icons-material/Launch";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { ActualComponentInterface } from "@shared/types/TextItem";
import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import { SuggestionData, SuggestionMetadata } from "../../../../components/commentthread/commentthread";
import CompHistory from "../../../../components/comphistory/comphistory";
import EditComp from "../../../../components/editcomp/editcomp";
import { PANELS, routes } from "../../../../defs";
import http, { API } from "../../../../http";
import { UnsavedChangesContext } from "../../../../store/unsavedChangesContext";
import { WebappPermissionProvider as UserPermissionProvider } from "../../../../store/webappPermissionContext";
import { CommentState } from "../../types";
import style from "./style.module.css";

interface Props {
  selectedId: string | null;
  selectedDocId: string | null;
  selectedComp: ActualComponentInterface<string> | null;
  updateResults: (input: string[]) => void;
  panelState: string;
  setPanelState: (input: string) => void;
  commentState: CommentState;
  setCommentState: (input: CommentState) => void;
  fetchWorkspaceTags: () => Promise<void>;
  workspaceTags: string[];
}
const SearchDetail = ({
  selectedId,
  selectedDocId,
  selectedComp,
  updateResults,
  panelState,
  setPanelState,
  commentState,
  setCommentState,
  fetchWorkspaceTags,
  workspaceTags,
}: Props) => {
  const history = useHistory();
  const { users: workspaceUsers } = useWorkspace();
  const [compHistory, setCompHistory] = useState([]);
  const [projName, setProjName] = useState<string>("");
  const [projFolder, setProjFolder] = useState<string | undefined>(undefined);
  const [comp, setComp] = useState<ActualComponentInterface<string> | null>(null);
  const [origComp, setOrigComp] = useState<ActualComponentInterface<string> | null>(null);
  const [canSave, setCanSave] = useState<boolean>(false);
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);

  const [tagSuggestions, setTagSuggestions] = useState<{ id: number; name: string }[]>([]);

  const fetchData = async () => {
    fetchDocData();
    fetchCompData();
    fetchHistory();
  };

  const fetchDocData = async () => {
    try {
      const { url } = API.doc.get.getname;
      const { data: proj } = await http.get(url(selectedDocId));
      setProjName(proj.doc_name);
      setProjFolder(proj.folder_id);
    } catch (e) {
      console.log("in searchdetail.jsx: ", e.message);
    }
  };

  const fetchCompData = async () => {
    try {
      const { url } = API.comp.get.info;
      const { data: comp } = await http.get(url(selectedId));
      setComp(comp);
      setOrigComp(comp);
    } catch (e) {
      console.log("in docdetail.jsx: ", e.message);
    }
  };

  const fetchHistory = async () => {
    try {
      const { url } = API.comp.get.historyAndComments;
      const { data: compHistory } = await http.get(url(selectedId));
      setCompHistory(compHistory || []);
    } catch (e) {
      console.log("in searchdetail.jsx: ", e.message);
    }
  };

  const getWorkspaceTags = async () => {
    try {
      await fetchWorkspaceTags();
      setTagSuggestions(workspaceTags.filter((tag) => tag).map((tag, i) => ({ id: i, name: tag })));
    } catch (err) {
      console.error("error fetching workspace tags in searchdetail.jsx: ", err);
    }
  };

  useEffect(() => {
    if (commentState && commentState.isSelected) {
      checkDetailPanelChanges(() => {
        setPanelState(PANELS.doc.activity);
      });
    } else {
      checkDetailPanelChanges(() => {
        setPanelState(PANELS.doc.edit);
      });
    }
  }, [commentState]);

  useEffect(() => {
    fetchData();
    getWorkspaceTags();
  }, [selectedId]);

  const refetchCompHistory = () => {
    fetchHistory();
  };

  const fetchCompInfo = async (shouldUpdateCompHistory = true) => {
    if (!selectedId) {
      return;
    }
    try {
      const { url } = API.comp.get.info;
      const { data: comp } = await http.get(url(selectedId));
      setComp(comp);
    } catch (e) {
      console.log("in docdetail.jsx: ", e.message);
    }
  };

  const goToDoc = (id: string) => {
    history.push(routes.nonNavRoutes.project.getPath(id));
  };

  const updateHistory = async (updateCompHist = true) => {
    if (updateCompHist) {
      fetchHistory();
      setPanelState(PANELS.doc.activity);
    }
  };

  const onResolveSuggestion = async (
    isAccepted: boolean,
    _threadId: string,
    suggestionData: SuggestionData,
    { compId }: SuggestionMetadata
  ) => {
    if (!isAccepted) {
      return;
    }

    const { url, body } = API.comp.post.update;
    await http.post(
      url(compId),
      body({
        newCompInfo: {
          text: suggestionData.text_after,
          rich_text: suggestionData.rich_text_after,
          variables: suggestionData.variables || [],
        },
        type: undefined,
        originalText: origComp?.text,
        editAll: false,
        changeInfo: {
          doc_name: projName,
          doc_id: selectedDocId,
        },
      })
    );

    updateResults([selectedId || ""]);
    fetchCompData();
  };

  // @ts-ignore
  const numComments: number = (comp?.comment_threads || [])?.reduce(
    // @ts-ignore
    (acc, thread) => acc + thread.comments?.length || 0,
    0
  );
  return (
    <div className={style.sidebar}>
      <div className={style.project} onClick={() => goToDoc(selectedDocId || "")}>
        <span className={style.label}>Project</span>
        <ArrowRightIcon className={style.icon} />
        {projName}
        <LaunchIcon className={style.open} />
      </div>
      <div className={style.sidebarDetail}>
        <div className={style.titleSection}>
          <div
            className={classnames({
              [style.option]: true,
              [style.selected]: panelState == PANELS.doc.edit,
            })}
            onClick={() => {
              checkDetailPanelChanges(() => {
                setPanelState(PANELS.doc.edit);
              });
            }}
          >
            Edit
          </div>
          <div
            className={classnames({
              [style.option]: true,
              [style.selected]: panelState == PANELS.doc.activity,
            })}
            onClick={() => {
              checkDetailPanelChanges(() => {
                setPanelState(PANELS.doc.activity);
              });
            }}
          >
            Activity
            {numComments > 0 && (
              <div className={style.commentCount}>
                <ModeCommentIcon className={style.icon} />
                {numComments}
              </div>
            )}
          </div>
        </div>
        {comp && selectedId && panelState == PANELS.doc.edit && (
          <span>
            <ErrorBoundary componentName="EditComp" type="Selection">
              <UserPermissionProvider resourceId={projFolder} resourceType={"project_folder"}>
                <EditComp
                  inSampleProject={false}
                  key={origComp?._id || "undefined"}
                  origComp={origComp}
                  comp={comp}
                  setOrigComp={setOrigComp}
                  setComp={setComp}
                  selectedId={selectedId}
                  doc_ID={selectedDocId}
                  onSearch={true}
                  doc_name={projName}
                  handleDocUpdate={updateResults}
                  handleHistoryUpdate={refetchCompHistory}
                  tagSuggestions={tagSuggestions}
                  getWorkspaceTags={getWorkspaceTags}
                  isDeveloperModeEnabled={false}
                  commentEditor={undefined}
                  selectedVariant={undefined}
                  duplicateComps={undefined}
                  duplicatesContainsVariants={undefined}
                  fetchCompInfo={undefined}
                  forceShowToast={undefined}
                  hidingAvailable={undefined}
                  unselectAll={undefined}
                  frameVariants={undefined}
                  selectedGroupId={undefined}
                  setShowMultiAttachCompToast={undefined}
                  onSaveSuggestion={undefined}
                  isMergedBranch={undefined}
                  isLockedProject={undefined}
                />
              </UserPermissionProvider>
            </ErrorBoundary>
          </span>
        )}
        {selectedId && panelState == PANELS.doc.activity && (
          <CompHistory
            setCommentState={setCommentState}
            onResolveSuggestion={onResolveSuggestion}
            selectedId={selectedId}
            handleHistoryUpdate={updateHistory}
            handleDocUpdate={updateResults}
            doc_name={projName}
            fetchCompInfo={fetchCompInfo}
            setCompHistory={setCompHistory}
            history={compHistory}
            doc_ID={selectedDocId}
            selectedFigmaId={selectedComp?.figma_node_ID}
            workspaceUsers={workspaceUsers}
            commentStateThreadId={null}
            isWsComp={undefined}
            compHistoryLoading={undefined}
            origComp={undefined}
            fetchAllComps={undefined}
            forceShowToast={undefined}
            refreshLibraryHistory={undefined}
            selectedVariant={undefined}
            setPanelState={undefined}
            updateCompResultComments={undefined}
            showSuggestionComponentWarning={undefined}
            isLockedProject={undefined}
            setSyncSettingsModalVisible={undefined}
          />
        )}
      </div>
    </div>
  );
};

export default SearchDetail;
