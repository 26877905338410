import * as ScrollArea from "@radix-ui/react-scroll-area";
import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  children: React.ReactNode;
  scrollContentRef?: React.Ref<HTMLDivElement>;
  disableScrollX?: boolean;
  disableScrollY?: boolean;
  className?: string;
  style?: React.CSSProperties;
  viewPortOnClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
}

export function Scrollbar(props: IProps) {
  return (
    <ScrollArea.Root
      style={props.style}
      className={classNames(style.ScrollbarWrapper, props.className)}
      data-testid="scrollbar"
    >
      <ScrollArea.Viewport
        ref={props.scrollContentRef}
        className={classNames(style.viewport, {
          [style.disableScrollX]: props.disableScrollX,
          [style.disableScrollY]: props.disableScrollY,
        })}
        id={props.id}
        onClick={props.viewPortOnClick}
      >
        {props.children}
      </ScrollArea.Viewport>

      {!props.disableScrollY && (
        <ScrollArea.Scrollbar orientation="vertical" className={style.scrollbar}>
          <ScrollArea.Thumb className={style.thumb} />
        </ScrollArea.Scrollbar>
      )}

      {!props.disableScrollX && (
        <ScrollArea.Scrollbar orientation="horizontal" className={style.scrollbar}>
          <ScrollArea.Thumb className={style.thumb} />
        </ScrollArea.Scrollbar>
      )}
    </ScrollArea.Root>
  );
}

export default Scrollbar;
