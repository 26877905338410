import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import BootstrapModal from "react-bootstrap/Modal";
import Stripe from "stripe";
import { BillingErrors } from "../../../../shared/types/errors";
import { MAX_NUMBER_OF_FREE_PLAN_USERS } from "../../../../util/constants";
import useSegment from "../../../hooks/useSegment";
import billing from "../../../http/billing";
import { PlanWillCancel } from "../../../store/billingContext";
import { useWorkspace } from "../../../store/workspaceContext";
import ButtonPrimary from "../../button/buttonprimary";
import CardSection from "../../cardsection/cardsection";
import CounterInput from "../../counter-input/counter-input";
import PlanWillCancelComponent from "./PlanWillCancelComponent";
import style from "./style.module.css";

export enum PlanType {
  STARTER = "free",
  TRIAL = "trial",
  TEAM = "team",
  GROWTH = "growth",
  ENTERPRISE = "enterprise",
}

export enum BillingCycle {
  MONTHLY = "Monthly",
  ANNUALLY = "Annually",
}

enum ModalState {
  SEATS,
  PAY,
  SUCCESS,
}

const Pricing = {
  [PlanType.STARTER]: {
    [BillingCycle.MONTHLY]: {
      editor: 0,
      commenter: 0,
      developers: 0,
    },
    [BillingCycle.ANNUALLY]: {
      editor: 0,
      commenter: 0,
      developers: 0,
    },
  },
  [PlanType.TEAM]: {
    [BillingCycle.MONTHLY]: {
      editor: 18,
      commenter: 12,
      developers: 80,
    },
    [BillingCycle.ANNUALLY]: {
      editor: 15,
      commenter: 10,
      developers: 65,
    },
  },
  [PlanType.GROWTH]: {
    [BillingCycle.MONTHLY]: {
      editor: 30,
      commenter: 18,
      developers: 200,
    },
    [BillingCycle.ANNUALLY]: {
      editor: 25,
      commenter: 15,
      developers: 160,
    },
  },
};

export interface UserBilling {
  plan: PlanType;

  yearly: {
    editors: number;
    commenters: number;
    devTools: boolean;
  };

  monthly: {
    editors: number;
    commenters: number;
    devTools: boolean;
  };

  yearlySubscription?: Stripe.Subscription;
  monthlySubscription?: Stripe.Subscription;
}

interface PreviewInvoiceLoading {
  loading: true;
}

interface PreviewInvoiceSuccess {
  loading: false;
  success: true;
  data: Stripe.Invoice;
}

interface PreviewInvoiceFailure {
  loading: false;
  success: false;
  error: string;
}

type PreviewInvoiceState = PreviewInvoiceLoading | PreviewInvoiceSuccess | PreviewInvoiceFailure;

const usePreviewYearlyInvoice = ({
  hasYearlyBilling,
  currentPlan,
  currentYearlyEditors,
  currentYearlyCommenters,
  currentYearlyDevTools,
  yearlySubscription,
}: {
  hasYearlyBilling: boolean;
  currentPlan: PlanType;
  currentYearlyEditors: number;
  currentYearlyCommenters: number;
  currentYearlyDevTools: boolean;
  yearlySubscription?: Stripe.Subscription;
}): [PreviewInvoiceState, () => Promise<void>] => {
  const [invoice, setInvoice] = useState<PreviewInvoiceState>({
    loading: true,
  });

  const previewInvoice = async () => {
    if (!hasYearlyBilling || currentPlan === PlanType.GROWTH || !yearlySubscription) {
      return;
    }

    let items: Stripe.InvoiceRetrieveUpcomingParams.SubscriptionItem[] = [];
    if (currentYearlyEditors) {
      items.push({
        price: process.env.REACT_APP_STRIPE_GROWTH_EDITOR_YEARLY!,
        quantity: currentYearlyEditors,
      });
      items.push({
        id: yearlySubscription.items.data.find((item) =>
          [process.env.REACT_APP_STRIPE_EDITOR_YEARLY_NEW, process.env.REACT_APP_STRIPE_EDITOR_YEARLY_OLD].includes(
            item.price.id
          )
        )?.id,
        deleted: true,
      });
    }
    if (currentYearlyCommenters) {
      items.push({
        price: process.env.REACT_APP_STRIPE_GROWTH_COMMENTER_YEARLY!,
        quantity: currentYearlyCommenters,
      });
      items.push({
        id: yearlySubscription.items.data.find((item) =>
          [process.env.REACT_APP_STRIPE_COMMENTER_YEARLY_NEW].includes(item.price.id)
        )?.id,
        deleted: true,
      });
    }

    if (currentYearlyDevTools) {
      items.push({
        price: process.env.REACT_APP_STRIPE_GROWTH_DEV_INTEGRATIONS_YEARLY!,
        quantity: 1,
      });
      items.push({
        id: yearlySubscription.items.data.find((item) =>
          [process.env.REACT_APP_STRIPE_TEAM_DEV_INTEGRATIONS_YEARLY].includes(item.price.id)
        )?.id,
        deleted: true,
      });
    }

    const [request, cancel] = billing.previewUpcomingInvoice({
      subscription: yearlySubscription?.id,
      subscription_items: [...items],
      subscription_proration_behavior: "always_invoice",
      subscription_billing_cycle_anchor: "unchanged",
    });

    try {
      const { data } = await request;
      setInvoice({ loading: false, success: true, data });
    } catch (error) {
      console.error(error);
      setInvoice({
        loading: false,
        success: false,
        error: "Unable to fetch invoice.",
      });
    }
  };

  useEffect(() => {
    previewInvoice();
  }, [setInvoice]);

  console.log(invoice);

  return [invoice, previewInvoice];
};

export interface BillingModalProps {
  initialUserBilling: UserBilling;
  componentsInWorkspace: number;
  name: string;
  email: string;
  devToolsEnabled: boolean;
  onDevToolsTrial: boolean;
  numEditorUsers: number;
  numCommenterUsers: number;
  onHide: () => void;
  planWillCancel: PlanWillCancel;
}

const BillingModal = (props: BillingModalProps) => {
  const segment = useSegment();
  const workspaceDetails = useWorkspace();

  const {
    initialUserBilling: {
      plan: currentPlan,
      yearly: { editors: currentYearlyEditors, commenters: currentYearlyCommenters, devTools: currentYearlyDevTools },
      monthly: {
        editors: currentMonthlyEditors,
        commenters: currentMonthlyCommenters,
        devTools: currentMonthlyDevTools,
      },
      yearlySubscription,
      monthlySubscription,
    },
    componentsInWorkspace,
    name,
    email,
    onHide,
    planWillCancel,
    devToolsEnabled,
    onDevToolsTrial,
    numEditorUsers,
    numCommenterUsers,
  } = props;

  const [modalState, setModalState] = useState(ModalState.SEATS);

  // Main modal state
  const [selectedPlan, setSelectedPlan] = useState<PlanType>(currentPlan || PlanType.STARTER);
  // don't add if billed yearly -- we don't want to also add it to the monthly cart
  // automatically add to cart if we're a free user and we're currently using dev tools
  const [devToolsAdded, setDevToolsAdded] = useState(
    currentMonthlyDevTools || (currentPlan === "free" && devToolsEnabled) || onDevToolsTrial
  );
  const [billingCycle, setBillingCycle] = useState(
    currentPlan !== PlanType.STARTER ? BillingCycle.MONTHLY : BillingCycle.ANNUALLY
  );

  // A workspace must have at least enough seats per role to cover the users
  // in the workspace, so the counts should initialize at the minimum number required.
  // If we're already billing monthly users, initialize at that number instead.
  const minEditors = Math.max(0, numEditorUsers - currentYearlyEditors);
  const minCommenters = Math.max(0, numCommenterUsers - currentYearlyCommenters);
  const [editorsCount, setEditorsCount] = useState(currentMonthlyEditors || minEditors);
  const [commentersCount, setCommentersCount] = useState(currentMonthlyCommenters || minCommenters);

  // Downgrade modal state
  const [downgradePlan, setDowngradePlan] = useState(PlanType.STARTER);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [continueDowngrade, setContinueDowngrade] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");

  // Payment modal state
  const stripe = useStripe();
  const elements = useElements();
  const [paymentErrors, setPaymentErrors] = useState<JSX.Element[]>([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [nameInput, setNameInput] = useState(name);
  const [emailInput, setEmailInput] = useState(email);

  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const { user } = useAuthenticatedAuth();

  // Basic boolean questions about the state of our user's workspace
  const pastStarterLimit =
    numEditorUsers + numCommenterUsers > MAX_NUMBER_OF_FREE_PLAN_USERS || componentsInWorkspace > 20;
  const pastTeamLimit = componentsInWorkspace > 200;
  const isAnnual = billingCycle === BillingCycle.ANNUALLY;
  const isCurrentlyPaying = currentPlan !== PlanType.STARTER;
  const changedMonthlyBilling =
    editorsCount !== currentMonthlyEditors ||
    commentersCount !== currentMonthlyCommenters ||
    devToolsAdded !== currentMonthlyDevTools ||
    selectedPlan !== currentPlan;
  const canCheckout = editorsCount > 0 || commentersCount > 0 || devToolsAdded;
  const hasMonthlyBilling = currentMonthlyEditors > 0 || currentMonthlyCommenters > 0 || currentMonthlyDevTools;
  const hasYearlyBilling = currentYearlyEditors > 0; // can't have commenters or dev tools without editors
  const upgradingAnnualSeats = hasYearlyBilling && currentPlan === PlanType.TEAM && selectedPlan === PlanType.GROWTH;

  const [yearlyPreviewInvoice, previewInvoice] = usePreviewYearlyInvoice({
    currentYearlyEditors,
    currentYearlyCommenters,
    currentYearlyDevTools,
    yearlySubscription,
    currentPlan,
    hasYearlyBilling,
  });

  // If user is on free plan, they can have dev tools enabled without currently billing them
  const isRemovingDevTools =
    (currentMonthlyDevTools || (currentPlan === PlanType.STARTER && devToolsEnabled)) && !devToolsAdded;

  // Show line items if:
  //   1. new customer has selected a plan
  //   2. existing customer has anything currently billed monthly
  //   3. existing customer wants to add something billed monthly
  //   4. customer wants to change plans
  const shouldShowLineItems =
    (!isCurrentlyPaying && selectedPlan !== PlanType.STARTER) ||
    (isCurrentlyPaying && changedMonthlyBilling) ||
    (isCurrentlyPaying && (currentMonthlyEditors || currentMonthlyCommenters || currentMonthlyDevTools)) ||
    currentPlan !== selectedPlan;

  // Lots of basic math to calculate pricing!
  const planPrices = Pricing[selectedPlan || PlanType.STARTER];

  const editorsPrice =
    editorsCount * planPrices[billingCycle].editor * (billingCycle === BillingCycle.ANNUALLY ? 12 : 1);

  const commentersPrice =
    commentersCount * planPrices[billingCycle].commenter * (billingCycle === BillingCycle.ANNUALLY ? 12 : 1);

  const devPrice = planPrices[billingCycle].developers * (billingCycle === BillingCycle.ANNUALLY ? 12 : 1);

  const totalPrice = editorsPrice + commentersPrice + (devToolsAdded ? devPrice : 0);

  // for calculating annual savings
  const monthlyAnnualPrice =
    (editorsCount * planPrices[BillingCycle.MONTHLY].editor +
      commentersCount * planPrices[BillingCycle.MONTHLY].commenter +
      (devToolsAdded ? planPrices[BillingCycle.MONTHLY].developers : 0)) *
    12;

  // Message for users who are already billing some seats annually
  //
  // "Surely," you think, "this could have been done with JSX?"
  // Well, you've never tried to implement an Oxford comma with JSX.
  // And for that, I envy you.
  let currentBillingMessage: string;
  if (currentYearlyEditors > 0 && currentYearlyCommenters > 0 && currentYearlyDevTools) {
    currentBillingMessage = `${currentYearlyEditors} editors, ${currentYearlyCommenters} commenters, and developer integrations`;
  } else if (currentYearlyEditors > 0 && currentYearlyDevTools) {
    currentBillingMessage = `${currentYearlyEditors} editors and developer integrations`;
  } else if (currentYearlyEditors > 0 && currentYearlyCommenters > 0) {
    currentBillingMessage = `${currentYearlyEditors} editors and ${currentYearlyCommenters} commenters`;
  } else if (currentYearlyEditors > 0) {
    currentBillingMessage = `${currentYearlyEditors} editors`;
  } else currentBillingMessage = "";

  const [userBilling, setUserBilling] = useState({} as UserBilling);

  useEffect(() => {
    const newUserBilling = { ...props.initialUserBilling };

    if (isAnnual) {
      if (!isCurrentlyPaying) {
        newUserBilling.yearly = {
          editors: editorsCount,
          commenters: commentersCount,
          devTools: devToolsAdded,
        };
      }
    } else {
      newUserBilling.monthly = {
        editors: editorsCount,
        commenters: commentersCount,
        devTools: devToolsAdded,
      };
    }

    newUserBilling.plan = selectedPlan;

    setUserBilling(newUserBilling);
  }, [
    isCurrentlyPaying,
    isAnnual,
    currentYearlyEditors,
    editorsCount,
    currentYearlyCommenters,
    commentersCount,
    currentYearlyDevTools,
    devToolsAdded,
    selectedPlan,
  ]);

  const closeDowngradeModal = () => {
    setShowDowngradeModal(false);
    setContinueDowngrade(false);
    setFeedbackText("");
  };

  const onDowngradeSelect = (downgradePlan: PlanType) => {
    setShowDowngradeModal(true);
    setDowngradePlan(downgradePlan);
  };

  const handlePaymentError = (code: BillingErrors, error?: any) => {
    let message = "Error upgrading workspace.";
    switch (code) {
      case BillingErrors.INVALID_SEATS:
        message = "You don't have enough seats selected to cover your workspace.";
        break;
      case BillingErrors.UNABLE_TO_CREATE_STRIPE_CUSTOMER:
        message = "Unable to create new Stripe customer.";
        break;
      case BillingErrors.INVALID_PLAN_CHANGE:
        message = "Invalid plan change.";
        break;
      case BillingErrors.MISSING_NEW_CUSTOMER_PARAMS:
        message = "Missing information required to create a new customer.";
        break;
      case BillingErrors.ATTEMPTED_TO_MODIFY_YEARLY_SUBSCRIPTION:
        message = "Cannot modify annual subscriptions; please contact support.";
        break;
      case BillingErrors.STRIPE_CARD_ERROR:
        message = error?.response?.data?.message ?? "Error upgrading workspace.";
        break;
      default:
        message = error?.message;
        console.error(error);
    }
    console.error(`Failed to process payment with error code ${code}:`, message);

    setPaymentErrors([
      <React.Fragment key="error1">
        We hit an error upgrading your workspace! If this issue persists, please{" "}
        <a href="mailto:support@dittowords.com">contact support</a>.
      </React.Fragment>,
      <React.Fragment key="error2">
        Failed to process payment with error code {code}: {message}
      </React.Fragment>,
    ]);
    setPaymentLoading(false);
  };

  const onUpdateBilling = async () => {
    setPaymentLoading(true);

    const [request, cancelRequest] = billing.updateSubscriptions({
      ...userBilling,
      yearly: isAnnual ? userBilling.yearly : undefined,
      monthly: isAnnual ? undefined : userBilling.monthly,
    });
    try {
      await request;
      setPaymentLoading(false);
      document.location.reload();
    } catch (error) {
      handlePaymentError(error.response?.data?.code, error);
    }
  };

  const createPaymentMethod = async () => {
    if (!stripe || !elements) return;
    const card = elements.getElement(CardElement);
    if (!card) return;

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: card,
      billing_details: {
        email: emailInput,
      },
    });

    return result.paymentMethod;
  };

  const handleSubmitPayment = async () => {
    // Stripe hasn't loaded; disable form submission until then
    if (!stripe || !elements) return;
    setPaymentErrors([]);

    setPaymentLoading(true);

    try {
      const paymentMethod = await createPaymentMethod();

      if (!paymentMethod) {
        throw new Error("Unable to authenticate payment method.");
      }

      const [request, _cancelRequest] = billing.updateSubscriptions({
        ...userBilling,
        yearly: isAnnual ? userBilling.yearly : undefined,
        monthly: isAnnual ? undefined : userBilling.monthly,
        newCustomerParams: { name, email, paymentMethodId: paymentMethod.id },
      });

      await request;

      setPaymentLoading(false);
      setPaymentSuccess(true);
      setModalState(ModalState.SUCCESS);
    } catch (error) {
      handlePaymentError(error.response?.data?.code, error);
    }
  };

  // alert the user if they try to close the modal without checking out
  const handleOnHide = () => {
    // user has "made changes" if they:
    //    - are a non-free user who has changed their monthly billing or plan
    //    - are a free user who should see their line items & can check out
    if (
      (changedMonthlyBilling && currentPlan !== "free") ||
      (!isCurrentlyPaying && shouldShowLineItems && canCheckout)
    ) {
      setShowAreYouSureModal(true);
    } else onHide();
  };

  const mappedYearlyInvoicePreview = useMemo(() => {
    if (!yearlyPreviewInvoice || yearlyPreviewInvoice.loading || !yearlyPreviewInvoice.success) return null;
    let currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: yearlyPreviewInvoice.data.currency,
    });
    return {
      proratedCharge: currencyFormatter.format(yearlyPreviewInvoice.data.amount_due * 0.01),
      totalCharge: currencyFormatter.format(
        Pricing[PlanType.GROWTH][BillingCycle.ANNUALLY].editor * currentYearlyEditors * 12 +
          Pricing[PlanType.GROWTH][BillingCycle.ANNUALLY].commenter * currentYearlyCommenters * 12 +
          (currentYearlyDevTools ? Pricing[PlanType.GROWTH][BillingCycle.ANNUALLY].developers * 12 : 0)
      ),
      editor: {
        proratedRefund: currencyFormatter.format(
          (yearlyPreviewInvoice.data.lines.data.find((item) =>
            [process.env.REACT_APP_STRIPE_EDITOR_YEARLY_OLD, process.env.REACT_APP_STRIPE_EDITOR_YEARLY_NEW].includes(
              item.price?.id
            )
          )?.amount ?? 0) * 0.01
        ),
        proratedCharge: currencyFormatter.format(
          (yearlyPreviewInvoice.data.lines.data.find(
            (item) => item.price?.id === process.env.REACT_APP_STRIPE_GROWTH_EDITOR_YEARLY
          )?.amount ?? 0) * 0.01
        ),
      },
      commenter: {
        proratedRefund: currencyFormatter.format(
          (yearlyPreviewInvoice.data.lines.data.find(
            (item) => process.env.REACT_APP_STRIPE_COMMENTER_YEARLY_NEW === item.price?.id
          )?.amount ?? 0) * 0.01
        ),
        proratedCharge: currencyFormatter.format(
          (yearlyPreviewInvoice.data.lines.data.find(
            (item) => item.price?.id === process.env.REACT_APP_STRIPE_GROWTH_COMMENTER_YEARLY
          )?.amount ?? 0) * 0.01
        ),
      },
      devTools: {
        proratedRefund: currencyFormatter.format(
          (yearlyPreviewInvoice.data.lines.data.find(
            (item) => process.env.REACT_APP_STRIPE_TEAM_DEV_INTEGRATIONS_YEARLY === item.price?.id
          )?.amount ?? 0) * 0.01
        ),
        proratedCharge: currencyFormatter.format(
          (yearlyPreviewInvoice.data.lines.data.find(
            (item) => item.price?.id === process.env.REACT_APP_STRIPE_GROWTH_DEV_INTEGRATIONS_YEARLY
          )?.amount ?? 0) * 0.01
        ),
      },
    };
  }, [yearlyPreviewInvoice]);

  return (
    <div>
      <BootstrapModal
        show={true}
        className={classnames({
          [style.paymentModal]: modalState === ModalState.PAY || modalState === ModalState.SUCCESS,
        })}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={handleOnHide}
        centered
      >
        {modalState === ModalState.SEATS && (
          <div>
            <BootstrapModal.Header className={style.header}>
              <BootstrapModal.Title className={style.title}>Upgrading your plan</BootstrapModal.Title>
              <CloseIcon className={style.close} onClick={handleOnHide} />
            </BootstrapModal.Header>
            <BootstrapModal.Body className={style.body}>
              <div className={style.pricingColumns}>
                {planWillCancel && (
                  <div className={style.billingModalWarning}>
                    <PlanWillCancelComponent plan={currentPlan} planWillCancel={planWillCancel} />
                  </div>
                )}
                <p className={style.thankYou}>
                  Thank you for choosing to upgrade! Upgrading helps us continue to work on Ditto and build out
                  features. 💗
                </p>

                <div className={classnames([style.row])}>
                  <div className={style.col}></div>
                  <div className={classnames([style.col, style.planNameWrapper])}>
                    <div className={style.planName}>Starter</div>
                  </div>
                  <div className={classnames([style.col, style.planNameWrapper])}>
                    <div className={style.planName}>Team</div>
                    {isAnnual && <div className={classnames([style.badge, style.green])}>-17%</div>}
                  </div>
                  <div className={classnames([style.col, style.planNameWrapper])}>
                    <div className={style.planName}>Growth</div>
                    {isAnnual && <div className={classnames([style.badge, style.green])}>-17%</div>}
                  </div>
                </div>

                <div
                  className={classnames([style.row, style.corePricing], {
                    [style.willCancel]: planWillCancel,
                  })}
                >
                  <div className={style.col}>
                    <div className={style.rowTitle}>Core Pricing</div>
                  </div>
                  <SelectPlan
                    plan={PlanType.STARTER}
                    billingCycle={billingCycle}
                    selectedPlan={selectedPlan}
                    currentPlan={currentPlan}
                    onDowngradeSelect={onDowngradeSelect}
                    setSelectedPlan={setSelectedPlan}
                    disabled={!!planWillCancel}
                  />
                  <SelectPlan
                    plan={PlanType.TEAM}
                    billingCycle={billingCycle}
                    selectedPlan={selectedPlan}
                    currentPlan={currentPlan}
                    onDowngradeSelect={onDowngradeSelect}
                    setSelectedPlan={setSelectedPlan}
                    disabled={!!planWillCancel}
                  />
                  <SelectPlan
                    plan={PlanType.GROWTH}
                    billingCycle={billingCycle}
                    selectedPlan={selectedPlan}
                    currentPlan={currentPlan}
                    setSelectedPlan={setSelectedPlan}
                    onDowngradeSelect={onDowngradeSelect}
                    disabled={!!planWillCancel}
                  />
                </div>

                <div className={classnames([style.row, style.tools])}>
                  <div className={style.col}>
                    <div className={style.rowTitle}>
                      Developer Integrations <br />
                      Add-On
                    </div>
                    <p>Sync edits to development with access to our API/CLI, GitHub Actions, and SDKs</p>
                  </div>
                  <div className={style.col}>
                    <DeveloperPrice
                      plan={PlanType.STARTER}
                      devToolsAdded={devToolsAdded}
                      selectedPlan={selectedPlan}
                      billingCycle={billingCycle}
                    />
                  </div>
                  <div className={style.col}>
                    <DeveloperPrice
                      plan={PlanType.TEAM}
                      devToolsAdded={devToolsAdded}
                      selectedPlan={selectedPlan}
                      billingCycle={billingCycle}
                    />
                  </div>
                  <div className={style.col}>
                    <DeveloperPrice
                      plan={PlanType.GROWTH}
                      devToolsAdded={devToolsAdded}
                      selectedPlan={selectedPlan}
                      billingCycle={billingCycle}
                    />
                  </div>
                </div>

                <div className={style.separatorWrapper}>
                  <div className={style.separator}></div>
                </div>

                <div className={style.rowTitle}>Usage</div>
                <div className={classnames([style.row, style.usage])}>
                  <div className={style.col}>
                    <div className={style.featureCell}>Projects</div>
                    <div className={style.featureCell}>Users</div>
                    <div className={style.featureCell}>Components</div>
                  </div>
                  <div className={style.col}>
                    <div className={style.featureCell}>Unlimited</div>
                    <div className={style.featureCell}>4</div>
                    <div className={style.featureCell}>20</div>
                  </div>
                  <div className={style.col}>
                    <div className={style.featureCell}>Unlimited</div>
                    <div className={style.featureCell}>Unlimited</div>
                    <div className={style.featureCell}>200</div>
                  </div>
                  <div className={style.col}>
                    <div className={style.featureCell}>Unlimited</div>
                    <div className={style.featureCell}>Unlimited</div>
                    <div className={style.featureCell}>750</div>
                  </div>
                </div>

                <div className={style.rowTitle}>Features</div>
                <div className={classnames([style.row, style.usage])}>
                  <div className={style.col}>
                    <div className={style.featureCell}>Sync with Figma files</div>
                    <div className={style.featureCell}>Export Text</div>
                    <div className={style.featureCell}>Commenter Permissions</div>
                    <div className={style.featureCell}>Folder Permissions</div>
                    <div className={style.featureCell}>Component Folders</div>
                    <div className={style.featureCell}>Variant Folders</div>
                  </div>
                  <div className={style.col}>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}></div>
                    <div className={style.featureCell}></div>
                    <div className={style.featureCell}></div>
                    <div className={style.featureCell}></div>
                    <div className={style.featureCell}></div>
                  </div>
                  <div className={style.col}>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}></div>
                    <div className={style.featureCell}></div>
                    <div className={style.featureCell}></div>
                  </div>
                  <div className={style.col}>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                    <div className={style.featureCell}>
                      <CheckIcon className={style.checkIcon} />
                    </div>
                  </div>
                </div>

                <div className={classnames([style.row, style.features])}>
                  <a className={style.link} href="https://www.dittowords.com/pricing" target="_blank">
                    See all features
                    <OpenInNew className={style.openInNewIcon} />
                  </a>
                </div>
              </div>
              <div className={style.checkoutArea}>
                <div className={style.billingCard}>
                  <div className={style.configureBilling}>
                    <div className={style.rowTitle}>Configure Billing</div>
                    <div className={style.billingCycleArea}>
                      {isCurrentlyPaying && hasYearlyBilling && (
                        <div className={style.currentlyPaying}>
                          <div className={style.currentSeats}>
                            You also have {currentBillingMessage} billed annually.
                          </div>
                          <div className={style.billingMonthly}>
                            Billing <span>Monthly</span>:
                          </div>
                        </div>
                      )}
                      {!isCurrentlyPaying && (
                        <div className={style.toggleArea}>
                          Billing Cycle
                          <BillingCycleToggle billingCycle={billingCycle} setBillingCycle={setBillingCycle} />
                        </div>
                      )}
                    </div>
                    <div className={style.seatCounts}>
                      <div className={style.seatCount}>
                        Editors
                        <CounterInput
                          min={minEditors}
                          count={editorsCount}
                          onCountChange={(count) => setEditorsCount(count)}
                          className={style.counter}
                        />
                      </div>
                      <div className={style.seatCount}>
                        Commenters
                        <CounterInput
                          min={minCommenters}
                          count={commentersCount}
                          onCountChange={(count) => setCommentersCount(count)}
                          className={style.counter}
                        />
                      </div>
                    </div>
                    {!currentYearlyDevTools && (
                      <div className={style.devTools}>
                        <span>Developer Integrations</span>
                        {devToolsAdded ? (
                          <button className={style.removeButton} onClick={() => setDevToolsAdded(false)}>
                            Remove
                          </button>
                        ) : (
                          <Button className={style.addButton} variant="primary" onClick={() => setDevToolsAdded(true)}>
                            Add
                          </Button>
                        )}
                      </div>
                    )}
                  </div>

                  <div className={style.separatorWrapper}>
                    <div className={style.separator}></div>
                  </div>

                  <div className={style.verticalSeparator}></div>

                  <div className={style.billSummary}>
                    <div className={style.summaryText}>
                      <div className={style.rowTitle}>Bill Summary</div>
                      {!shouldShowLineItems &&
                        (isCurrentlyPaying
                          ? "You haven't yet selected any seats or add-ons to be billed monthly."
                          : "Please select a plan to view the total.")}
                      {shouldShowLineItems && isCurrentlyPaying && changedMonthlyBilling && upgradingAnnualSeats && (
                        <div className={classnames([style.badge, style.blue])}>
                          This is your <b>prorated</b> total for upgrading your annual seats in the middle of your
                          billing cycle.
                        </div>
                      )}
                      {shouldShowLineItems && isRemovingDevTools && (
                        <div className={classnames([style.badge, style.red])}>
                          Heads up! Removing developer integrations for your workspace means your team will no longer be
                          able to sync edits to development.
                        </div>
                      )}
                      {!yearlyPreviewInvoice.loading && yearlyPreviewInvoice.success && upgradingAnnualSeats && (
                        <>
                          <div className={style.lineItems}>
                            <div className={style.subtotal}>
                              {currentYearlyCommenters > 0 && (
                                <>
                                  <div className={style.lineItem}>
                                    <span>{`Unused time on ${currentYearlyEditors} Team Editor${
                                      currentYearlyEditors === 1 ? "" : "s"
                                    } (Yearly)`}</span>
                                    <span>{`${mappedYearlyInvoicePreview?.editor.proratedRefund}`}</span>
                                  </div>
                                  <div className={style.lineItem}>
                                    <span>{`Remaining time for ${currentYearlyEditors} Growth Editor${
                                      currentYearlyEditors === 1 ? "" : "s"
                                    } (Yearly)`}</span>
                                    <span>{`${mappedYearlyInvoicePreview?.editor.proratedCharge}`}</span>
                                  </div>
                                </>
                              )}
                              {currentYearlyCommenters > 0 && (
                                <>
                                  <div className={style.lineItem}>
                                    <span>{`Unused time on ${currentYearlyCommenters} Team Commenter${
                                      currentYearlyCommenters === 1 ? "" : "s"
                                    } (Yearly)`}</span>
                                    <span>{`${mappedYearlyInvoicePreview?.commenter.proratedRefund}`}</span>
                                  </div>
                                  <div className={style.lineItem}>
                                    <span>{`Remaining time for ${currentYearlyCommenters} Growth Commenter${
                                      currentYearlyCommenters === 1 ? "" : "s"
                                    } (Yearly)`}</span>
                                    <span>{`${mappedYearlyInvoicePreview?.commenter.proratedCharge}`}</span>
                                  </div>
                                </>
                              )}
                              {currentYearlyDevTools && (
                                <>
                                  <div className={style.lineItem}>
                                    <span>{`Unused time on Team Developer Integrations (Yearly)`}</span>
                                    <span>{`${mappedYearlyInvoicePreview?.devTools.proratedRefund}`}</span>
                                  </div>
                                  <div className={style.lineItem}>
                                    <span>{`Remaining time for Growth Developer Integrations (Yearly)`}</span>
                                    <span>{`${mappedYearlyInvoicePreview?.devTools.proratedCharge}`}</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className={style.total}>
                            <div className={style.lineItem}>
                              <span>Due Now</span>
                              <span>{`${mappedYearlyInvoicePreview?.proratedCharge}`}</span>
                            </div>
                            <div className={style.lineItem}>
                              <span className={style.secondaryLineItemText}>Next billing cycle</span>
                              <span
                                className={style.secondaryLineItemText}
                              >{`${mappedYearlyInvoicePreview?.totalCharge}`}</span>
                            </div>
                          </div>
                        </>
                      )}

                      {upgradingAnnualSeats && shouldShowLineItems && hasMonthlyBilling && (
                        <div className={classnames([style.badge, style.blue, style.mixedUpgradeBadge])}>
                          This is your updated monthly invoice.
                        </div>
                      )}
                      {shouldShowLineItems && (hasMonthlyBilling || changedMonthlyBilling) && (
                        <div className={style.lineItems}>
                          <div className={style.subtotal}>
                            <div className={style.lineItem}>
                              <span>{`${editorsCount} ${selectedPlan === PlanType.GROWTH ? "Growth" : "Team"} Editor${
                                editorsCount === 1 ? "" : "s"
                              }`}</span>
                              <span>{`$${editorsPrice}`}</span>
                            </div>
                            {commentersCount > 0 && (
                              <div className={style.lineItem}>
                                <span>{`${commentersCount} ${
                                  selectedPlan === PlanType.GROWTH ? "Growth" : "Team"
                                } Commenter${commentersCount === 1 ? "" : "s"}`}</span>
                                <span>{`$${commentersPrice}`}</span>
                              </div>
                            )}
                            {devToolsAdded && (
                              <div className={style.lineItem}>
                                <span>Developer Integrations</span>
                                <span>{`$${devPrice}`}</span>
                              </div>
                            )}
                          </div>
                          <div className={style.total}>
                            <div className={style.lineItem}>
                              <span>
                                Total per {(isCurrentlyPaying || !isAnnual) && "month"}
                                {!isCurrentlyPaying && isAnnual && "year"}
                              </span>
                              <span>{`$${totalPrice}`}</span>
                            </div>
                            {isAnnual && (
                              <div className={style.lineItem}>
                                <span>Annual savings with yearly plan</span>
                                <span>{`$${monthlyAnnualPrice - totalPrice}`}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {isCurrentlyPaying && (
                      <Button
                        className={style.checkoutBtn}
                        variant={changedMonthlyBilling && !paymentLoading ? "primary" : "secondary"}
                        disabled={!changedMonthlyBilling || paymentLoading}
                        onClick={onUpdateBilling}
                      >
                        <span>{paymentLoading ? "Processing..." : "Update Billing"}</span>
                      </Button>
                    )}
                    {!isCurrentlyPaying && (
                      <Button
                        className={style.checkoutBtn}
                        variant={canCheckout && shouldShowLineItems ? "primary" : "secondary"}
                        disabled={!(canCheckout && shouldShowLineItems)}
                        onClick={() => setModalState(ModalState.PAY)}
                      >
                        <span>Continue to Checkout </span>
                        <ArrowForwardIcon className={style.arrowIcon} />
                      </Button>
                    )}
                  </div>
                </div>
                <div className={style.enterpriseContact}>
                  <span>Looking for SAML/SSO, custom training, advanced security, or dedicated support? </span>
                  <a className={style.link} href="mailto:enterprise@dittowords.com" target="_blank">
                    Contact us about Enterprise
                  </a>
                </div>
              </div>
            </BootstrapModal.Body>
          </div>
        )}

        {modalState === ModalState.PAY && (
          <div>
            <BootstrapModal.Body className={style.body}>
              <div className={style.totalRow}>
                <div className={style.left}>
                  Total
                  <div className={style.small}>
                    {editorsCount} Editor{editorsCount !== 1 ? "s" : ""}, {commentersCount} Commenter
                    {commentersCount !== 1 ? "s" : ""}
                    {devToolsAdded && ", Developer Integrations"}
                  </div>
                </div>
                <div className={style.right}>
                  ${totalPrice}
                  <div className={style.small}>per {isAnnual ? "year" : "month"}</div>
                </div>
              </div>
              <div className={style.paymentForm}>
                <h3>Payment</h3>
                <p>
                  Payment information is handled securely by{" "}
                  <a className={style.link} href="https://stripe.com/" target="_blank">
                    Stripe
                  </a>
                  . Ditto does not store any of your payment information.
                </p>
                <h4>Name</h4>
                <input
                  type="text"
                  placeholder="Full Name"
                  aria-label="Full Name"
                  value={nameInput}
                  onChange={(e) => setNameInput(e.target.value)}
                />
                <h4>Billing Email</h4>
                <input
                  type="text"
                  placeholder="Billing Email"
                  aria-label="Billing Email"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                />
                <CardSection />
                {paymentErrors.map((err, i) => {
                  return (
                    <div key={i} className={classnames([style.badge, style.red, style.paymentErrorMessage])}>
                      {err}
                    </div>
                  );
                })}
                <p>
                  By upgrading, you agree to our{" "}
                  <a href="https://www.dittowords.com/legal/terms-of-service" target="_blank">
                    Terms of Service
                  </a>
                  . Your subscription will renew on {isAnnual ? "an annual" : "a monthly"} basis, and you may cancel at
                  any time.
                </p>
              </div>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className={style.footer}>
              <div className={style.backButton} onClick={() => setModalState(ModalState.SEATS)}>
                <KeyboardArrowLeftIcon className={style.icon} />
                Back
              </div>
              <ButtonPrimary
                onClick={() => handleSubmitPayment()}
                text={paymentLoading ? "Processing..." : "Upgrade Plan"}
                disabled={paymentLoading}
              />
            </BootstrapModal.Footer>{" "}
          </div>
        )}
        {modalState === ModalState.SUCCESS && (
          <div>
            <BootstrapModal.Header className={style.header}>
              <BootstrapModal.Title className={style.title}>Successfully upgraded! 🎉</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body className={style.body}>
              <div className={style.description}>
                <p>
                  Your workspace has been upgraded to the {selectedPlan === PlanType.TEAM ? "Team" : "Growth"} Plan!
                  You'll receive a receipt via email shortly.
                </p>
                <div className={style.subheader}>📖 Guidance & Resources</div>
                <p className={style.no_margin}>
                  We want to help get your team up and running in any way possible. Some resources we have include:
                </p>
                <ul>
                  <li>
                    <a href="https://www.dittowords.com/docs/introduction" target="_blank" className={style.li_link}>
                      Ditto Help Guides
                    </a>{" "}
                    — includes demos, getting started guides, feature overviews, and troubleshooting help
                  </li>
                  <li>
                    <a href="mailto:support@dittowords.com" target="_blank" className={style.li_link}>
                      Ditto Office Hours
                    </a>{" "}
                    — book 1:1 time for hands-on guidance over a call or email us at{" "}
                    <a href="mailto:support@dittowords.com" target="_blank">
                      support@dittowords.com
                    </a>
                  </li>
                  {devToolsAdded && (
                    <li>
                      <a href="https://developer.dittowords.com/" target="_blank" className={style.li_link}>
                        Ditto Developer Docs
                      </a>{" "}
                      — includes documentation on our API, CLI, and SDKs, example projects, and developer changelog
                    </li>
                  )}
                </ul>
              </div>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className={style.footer}>
              <div className={style.footerText}></div>
              <ButtonPrimary onClick={() => document.location.reload()} text="Finish" />
            </BootstrapModal.Footer>
          </div>
        )}
      </BootstrapModal>
      {showDowngradeModal && (
        <BootstrapModal
          show={showDowngradeModal}
          className={style.secondaryModal}
          dialogClassName={style.dialog}
          backdropClassName={style.downgradeBackdrop}
          onHide={closeDowngradeModal}
          centered
        >
          <BootstrapModal.Header className={style.header}>
            <BootstrapModal.Title className={style.title}>Downgrading your plan</BootstrapModal.Title>
            <CloseIcon className={style.close} onClick={closeDowngradeModal} />
          </BootstrapModal.Header>
          <BootstrapModal.Body className={style.body}>
            {!continueDowngrade && (
              <>
                {downgradePlan === PlanType.STARTER && (
                  <div className={style.helpText}>
                    Are you sure you want to downgrade to the Starter Plan? On the Starter Plan, your workspace will
                    have a limit of 4 editors and 20 components.
                  </div>
                )}
                {downgradePlan === PlanType.TEAM && (
                  <div className={style.helpText}>
                    Are you sure you want to downgrade to the Team Plan? On the Team Plan, your workspace will have a
                    limit of 200 components.
                  </div>
                )}
                <div className={style.helpText}>
                  We'd love to help if we can! If you'd prefer to meet us to discuss your team's needs and workflow
                  before downgrading, please reach out to us at{" "}
                  <a href="mailto:support@dittowords.com" target="_blank" className={style.link}>
                    support@dittowords.com
                  </a>{" "}
                  or book a time for a quick chat{" "}
                  <a href="https://calendly.com/morris-dittowords/1-1-ditto-support">here</a>.
                </div>
                <div className={style.buttons}>
                  <Button className={style.button} variant="outline-dark" onClick={closeDowngradeModal}>
                    No, go back
                  </Button>
                  <Button
                    onClick={() => {
                      setShowDowngradeModal(false);
                      setSelectedPlan(downgradePlan);
                      window.open(
                        `https://dittowords.fillout.com/feedback?email=${email}&workspaceName=${workspaceDetails.workspaceInfo.name}&workspaceID=${user.workspaceId}&fromPlan=${currentPlan}&toPlan=${downgradePlan}`,
                        "_blank"
                      );
                    }}
                    className={style.button}
                    variant="primary"
                  >
                    Yes, downgrade
                  </Button>
                </div>
              </>
            )}
          </BootstrapModal.Body>
        </BootstrapModal>
      )}
      {showAreYouSureModal && (
        <BootstrapModal
          show
          className={style.secondaryModal}
          dialogClassName={style.dialog}
          backdropClassName={style.downgradeBackdrop}
          onHide={() => setShowAreYouSureModal(false)}
          centered
        >
          <BootstrapModal.Header className={style.header}>
            <BootstrapModal.Title className={style.title}>Unsaved changes!</BootstrapModal.Title>
            <CloseIcon className={style.close} onClick={() => setShowAreYouSureModal(false)} />
          </BootstrapModal.Header>
          <BootstrapModal.Body className={style.body}>
            <div className={style.helpText}>
              You have unsaved changes to your billing! Are you sure you want to exit?
            </div>
            <div className={style.buttons}>
              <Button onClick={() => onHide()} className={style.button} variant="primary">
                Exit
              </Button>
              <Button
                onClick={() => setShowAreYouSureModal(false)}
                className={style.button}
                variant="outline-secondary"
              >
                Cancel
              </Button>
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      )}
    </div>
  );
};

interface SelectPlanProps {
  plan: PlanType;
  billingCycle: BillingCycle;
  currentPlan: PlanType;
  selectedPlan: PlanType | undefined;
  setSelectedPlan: React.Dispatch<React.SetStateAction<PlanType | undefined>>;
  onDowngradeSelect: (downgradePlan: PlanType) => void;
  disabled: boolean;
}

const SelectPlan = (props: SelectPlanProps) => {
  const { billingCycle, plan, currentPlan, selectedPlan, onDowngradeSelect, setSelectedPlan, disabled } = props;
  const isDowngrade =
    (plan === PlanType.STARTER && (currentPlan === PlanType.TEAM || currentPlan === PlanType.GROWTH)) ||
    (plan === PlanType.TEAM && currentPlan === PlanType.GROWTH);

  return (
    <div className={classnames(style.col, style.selectPlan)}>
      <div className={style.pricePerText}>
        <div className={style.dollarAmount}>
          <span className={style.dollarSign}>$</span>
          <span className={style.price}>{Pricing[plan][billingCycle].editor}</span>
        </div>
        <div className={style.perText}>
          <span>per editor</span>
          <span>per month</span>
        </div>
      </div>
      <div className={style.commenterPrice}>
        {plan === PlanType.STARTER ? "" : `$${Pricing[plan][billingCycle].commenter} per commenter/month`}
      </div>
      <SelectPlanButton
        plan={plan}
        currentPlan={currentPlan}
        isDowngrade={isDowngrade}
        isSelectedPlan={plan === selectedPlan}
        setSelectedPlan={setSelectedPlan}
        onDowngradeSelect={onDowngradeSelect}
        disabled={disabled}
      />
    </div>
  );
};

const SelectPlanButton = ({
  plan,
  currentPlan,
  isDowngrade,
  isSelectedPlan,
  setSelectedPlan,
  onDowngradeSelect,
  disabled,
}) => {
  if (plan === currentPlan) {
    return (
      <Button className={classnames([style.selectPlanBtn, style.currentPlanBtn])} variant="outline-secondary" disabled>
        Current Plan
      </Button>
    );
  }

  if (isSelectedPlan) {
    return (
      <Button
        className={classnames([style.selectPlanBtn, style.selected])}
        variant="primary"
        onClick={() => setSelectedPlan(currentPlan)}
        disabled={disabled}
      >
        <span>
          <CheckIcon fontSize="inherit" className={style.checkIcon} /> Selected
        </span>
      </Button>
    );
  }

  if (isDowngrade) {
    return (
      <Button
        className={classnames([style.selectPlanBtn, style.downgrade])}
        onClick={() => onDowngradeSelect(plan)}
        variant="secondary"
        disabled={disabled}
      >
        Downgrade Plan
      </Button>
    );
  }

  return (
    <Button
      className={style.selectPlanBtn}
      variant={"primary"}
      onClick={() => setSelectedPlan(plan)}
      disabled={disabled}
    >
      Select Plan
    </Button>
  );
};

const DeveloperPrice = ({ plan, selectedPlan, devToolsAdded, billingCycle }) => {
  return (
    <div className={style.addedBadgeWrapper}>
      <div className={style.pricePerText}>
        <div className={style.dollarAmount}>
          <span className={style.dollarSign}>$</span>
          <span className={style.price}>{Pricing[plan][billingCycle].developers}</span>
        </div>
        <div className={style.perText}>
          <span>additional</span>
          <span>per month</span>
        </div>
      </div>
      {devToolsAdded && selectedPlan === plan && <div className={classnames([style.badge, style.blue])}>Added</div>}
    </div>
  );
};

const BillingCycleToggle = ({ billingCycle, setBillingCycle }) => {
  return (
    <div className={style.billingCycleToggle}>
      <div
        className={classnames([style.selectedOverlay], {
          [style.right]: billingCycle === BillingCycle.MONTHLY,
        })}
      ></div>
      <div
        className={classnames([style.clickArea], {
          [style.selected]: billingCycle === BillingCycle.ANNUALLY,
        })}
        onClick={() => setBillingCycle(BillingCycle.ANNUALLY)}
      >
        Yearly
      </div>
      <div
        className={classnames([style.clickArea], {
          [style.selected]: billingCycle === BillingCycle.MONTHLY,
        })}
        onClick={() => setBillingCycle(BillingCycle.MONTHLY)}
      >
        Monthly
      </div>
    </div>
  );
};

export default BillingModal;
