import React, { forwardRef } from "react";
import Button from "../Button";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  /**
   * Icon to be displayed in the trigger
   * Defaults to three horizontal dots
   */
  icon?: React.ReactNode;
}

const DropdownTrigger = forwardRef<HTMLButtonElement, IProps>(function DropdownTrigger(props: IProps, ref) {
  return (
    <Button ref={ref} {...props} type="icon" level="secondary">
      {props.icon || <KebabIcon />}
    </Button>
  );
});

function KebabIcon() {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 10C5.63333 10 5.29848 9.91061 4.99545 9.73182C4.69242 9.55 4.45 9.30758 4.26818 9.00455C4.08939 8.70151 4 8.36667 4 8C4 7.6303 4.08939 7.29545 4.26818 6.99545C4.45 6.69242 4.69242 6.45152 4.99545 6.27273C5.29848 6.09091 5.63333 6 6 6C6.3697 6 6.70455 6.09091 7.00455 6.27273C7.30758 6.45152 7.54849 6.69242 7.72727 6.99545C7.90909 7.29545 8 7.6303 8 8C8 8.36667 7.90909 8.70151 7.72727 9.00455C7.54849 9.30758 7.30758 9.55 7.00455 9.73182C6.70455 9.91061 6.3697 10 6 10Z"
        fill="#B7B7B8"
      />
      <path
        d="M12 10C11.6333 10 11.2985 9.91061 10.9955 9.73182C10.6924 9.55 10.45 9.30758 10.2682 9.00455C10.0894 8.70151 10 8.36667 10 8C10 7.6303 10.0894 7.29545 10.2682 6.99545C10.45 6.69242 10.6924 6.45152 10.9955 6.27273C11.2985 6.09091 11.6333 6 12 6C12.3697 6 12.7045 6.09091 13.0045 6.27273C13.3076 6.45152 13.5485 6.69242 13.7273 6.99545C13.9091 7.29545 14 7.6303 14 8C14 8.36667 13.9091 8.70151 13.7273 9.00455C13.5485 9.30758 13.3076 9.55 13.0045 9.73182C12.7045 9.91061 12.3697 10 12 10Z"
        fill="#B7B7B8"
      />
      <path
        d="M18 10C17.6333 10 17.2985 9.91061 16.9955 9.73182C16.6924 9.55 16.45 9.30758 16.2682 9.00455C16.0894 8.70151 16 8.36667 16 8C16 7.6303 16.0894 7.29545 16.2682 6.99545C16.45 6.69242 16.6924 6.45152 16.9955 6.27273C17.2985 6.09091 17.6333 6 18 6C18.3697 6 18.7045 6.09091 19.0045 6.27273C19.3076 6.45152 19.5485 6.69242 19.7273 6.99545C19.9091 7.29545 20 7.6303 20 8C20 8.36667 19.9091 8.70151 19.7273 9.00455C19.5485 9.30758 19.3076 9.55 19.0045 9.73182C18.7045 9.91061 18.3697 10 18 10Z"
        fill="#B7B7B8"
      />
    </svg>
  );
}

export default DropdownTrigger;
